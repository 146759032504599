
import {useState, useEffect, useContext} from 'react';
import {useHistory} from "react-router-dom";

import React from 'react'
import "../style/Homepage.css"
import Modal from 'react-modal';
import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import {LogLevel,
    PublicClientApplication
} from "@azure/msal-browser";
import baseUrl from '../utilities/globalVariables.js'
import logoGEM from "../imgs/risorsa2.png"
import azureEnvironment from '../utilities/azureActiveDirectoryAppClient.js'

export const MSAL_CONFIG = {
  // change development to production before pushing to git
    auth: {
        clientId: azureEnvironment.appId,
        authority: "https://login.microsoftonline.com/" + azureEnvironment.tenant,
        redirectUri: azureEnvironment.redirect,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
            return;
            }
            switch (level) {
            case LogLevel.Error:
                //console.error(message);
                return;
            case LogLevel.Info:
                //console.info(message);
                return;
            case LogLevel.Verbose:
                console.debug(message);
                return;
            case LogLevel.Warning:
                //console.warn(message);
                return;
            }
        },
        },
    },
};

let myMSALObj = new PublicClientApplication(MSAL_CONFIG);


function Homepage(){

    const userObject = useContext(UserContext);
    const {username, setUsername, userId, setUserId, ruolo, setRuolo, setMail, accessToken, setAccessToken, setCapoSquadra} = userObject

    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

    const [password, setPassword]= useState()
    const [esterno, setEsterno]= useState(true)
    const [avrStaff, setAvrStaff]= useState(false)
    const [avviso, setAvviso]= useState('')
    const [avviso2, setAvviso2]= useState('')

    //const baseUrl = ""; // change to http://localhost:3000 for development

    const urlAPI = baseUrl + "/api/v1/auth/signin"
    const urlAPISAML = baseUrl + "/api/v1/auth"

    let history = useHistory();

    function callApi(){
        if(!password || !username){
            setAvviso("Compilare tutti i campi!")
            return
        }
        setAvviso('')
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        
        var urlencoded = new URLSearchParams();
        urlencoded.append("username",username);
        urlencoded.append("password", password);
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(urlAPI, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.accessToken==null){
                setAvviso(result.error)
            }else{ 
                settingParametriUtente(result)
                history.push("/dashboard")
            }
        })
        .catch(error => console.log('error', error));
    }
    
    function settingParametriUtente(res){
        //console.log(res)
        setUserId(res.id);
        setUsername(res.username);
        setMail(res.mail);
        setAccessToken(res.accessToken);
        setRuolo({...ruolo, id: res.role.id, name: res.role.name})
        setCapoSquadra(res.capoSquadra)


        window.localStorage.setItem("userId", res.id); //JSON.stringify(res.id) JSON.stringify(res.role.id)
        window.localStorage.setItem("username",JSON.stringify(res.username));
        window.localStorage.setItem("mail",JSON.stringify(res.mail));
        window.localStorage.setItem("ruoloId", res.role.id ); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
        window.localStorage.setItem("ruoloName", JSON.stringify(res.role.name)); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
        window.localStorage.setItem("accessToken",JSON.stringify(res.accessToken));
        window.localStorage.setItem("caposquadra",res.capoSquadra);
    }

    //per quando premo enter  a fine inserimento pwd ['x-access-token']
    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            callApi();
        }
    };

    function callSAMLAPI(SAMLemail ) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("SAMLemail", SAMLemail);
        //urlencoded.append("SAMLAggiuntivo", SAMLAggiuntivo);
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        
        fetch(urlAPISAML, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("POSTLOGIN", result)
            if(result.accessToken==null){
                setAvviso2(result.error)
            }else{ 
                settingParametriUtente(result)
                history.push("/dashboard")
            }
        })
        .catch(error => console.log('error', error));
    }

    const goToDashboard = () => {
        myMSALObj
        .loginPopup({
            scopes: [],
            prompt: "select_account",
            })
            .then((resp) => {
            //console.log('MSAL', resp);
            if(resp.idToken && resp.account && resp.account.name){
                let SAMLemail = resp.account.username.toLowerCase()
                //let SAMLAggiuntivo = resp.account.idTokenClaims.roles[0]
                //callSAMLAPI(SAMLemail, SAMLAggiuntivo);
                callSAMLAPI(SAMLemail);
                }
            })
            .catch((err) => {
            console.error(err);
        });
    };

    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
            <Modal isOpen={true} style={{ overlay: {zIndex:5}}} className="modaleIniziale" ariaHideApp={false}>
                <div className="modalContainer">
                    <div className="payoffModal">
                        <img src={logoGEM}></img> 
                        <p>GESTIONE EMERGENZE MILANO</p>
                    </div>

                    <div className="titoliModal">
                        <span className={`titoloModalSx ${esterno?'active':''}`} onClick={()=>{setEsterno(true);setAvrStaff(false)}}> Esterno </span>
                        <span className={`titoloModalDx ${avrStaff?'active':''}`} onClick={()=>{setEsterno(false);setAvrStaff(true)}}> Avr Staff </span>
                    </div>

                    <div>
                        {esterno&& 
                            <div className="contenutoModal">
                                <input type="text" placeholder="Inserire Username.." onChange={(e)=>setUsername(e.target.value)}></input>
                                <input type="password" placeholder="Inserire Password.." onChange={(e)=>setPassword(e.target.value)} onKeyPress={handleKeypress}></input> 
                                <div className="bottoneEntra" onClick={()=>callApi()}>ENTRA</div>
                                <span style={{maxWidth: '22em'}}> {avviso}</span>
                            </div>
                        }

                        {avrStaff&& 
                            <div className="contenutoModal" >
                                <div className="bottoneEntra" onClick={()=>goToDashboard()}>ENTRA</div>
                                <span style={{maxWidth: '22em'}}>{avviso2}</span>
                            </div>
                        }
                    </div>
                </div>

            </Modal>
        </div>
    )
}
export default Homepage;
