function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function sanitize(string) {
    if(string){
      return string.replace(/[*+^${}()|\\]/g, '\\$&'); // $& means the whole matched string
    } else {return}
}

function calcoloOreFromEpoch(epoch){
    const dt = new Date(epoch*1000);
    const hr = dt.getHours();
    const m = "0" + dt.getMinutes();
    
    return hr + ':' + m.substr(-2)
}

function fromEpochToLocaleString(epoch){
    const dataLocale = new Date(+epoch).toLocaleString()
    return dataLocale;
}

export default  capitalizeFirstLetter