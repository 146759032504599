

import  React, { useState , useEffect, useContext } from 'react'
import { UserContext } from "../../Context/UserContext.js";
import baseUrl from '../../utilities/globalVariables.js'
import capitalizeFirstLetter from '../../utilities/utilities.js'


function MeteoGiornataCritica(props){

    const userObject = useContext(UserContext);
    const { userId, accessToken, } = userObject;
    const [iconMeteo, setIconMeteo] = useState('https://openweathermap.org/img/wn/10d@2x.png')

    const {giornataCritica} = props //in forma: 27-01-2022 
    var apiUrl = baseUrl + "/api/v1/meteo/storico";
    const [meteo, setMeteo]= useState(null)
    const avviso = 'Informazioni meteo non disponibili.'
//debugger
    useEffect(()=>{

        if(!giornataCritica || !giornataCritica.giorno || accessToken==null ){
            //console.log(giornataCritica)
            return
        }
        console.log(giornataCritica)
        var day = +giornataCritica.giorno.split('-')[0] 
        var month =  +giornataCritica.giorno.split('-')[1] -1
        var year = +giornataCritica.giorno.split('-')[2]
        var inizioDataCritica = new Date(year, month, day).getTime()
        var fineDataCritica = +inizioDataCritica + 86400000 //+24ore

        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        myHeaders.append('x-inizio-giornata-critica', inizioDataCritica);
        myHeaders.append('x-fine-giornata-critica', fineDataCritica);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message&&result.message.length>0){
                console.log('risposta get meteo annuale',JSON.parse(result.message[0].info))
                setMeteo(JSON.parse(result.message[0].info))
                setIconMeteo("https://openweathermap.org/img/wn/"+JSON.parse(result.message[0].info).weather[0].icon+"@2x.png")
            }else if(!result.message||result.message.length==0){
                setMeteo(null)
            }
        })
        .catch(error => console.log('error', error));
    },[giornataCritica, accessToken])


return(
    <>
        {meteo&&<div className="meteoBody">
            Temperatura (Tmin - Tmax): {Math.floor(meteo.temp.min - 273.15)} °C -  {Math.floor(meteo.temp.max - 273.15)} °C <br/>
            Vento (Velocità Media): {meteo.wind_speed} Km/h <br/>
            Umidità: {meteo.humidity}% <br/>
            {meteo.rain&&<p>Pioggia (mm): 0.1 mm </p>}
            {meteo.snow&&<p>Neve (mm): -- </p>}
            Descrizione:  {capitalizeFirstLetter(meteo.weather[0].description)} <br/>
            <div className="imgContainer">
                <img src={iconMeteo} className="iconaMeteo"></img>
            </div>
        </div>}
        {!meteo&&<div>{avviso}</div>}

    </>
    )

}

export default MeteoGiornataCritica

