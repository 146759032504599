/* questo component conterrà solo la label e la select della lista danno , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";
import baseUrl from '../../utilities/globalVariables.js'


function Danno(props){
    const [listaDanni, setListaDanni] = useState([]);
    const [danno, setDanno] = [props.danno,  props.setDanno];
    const userObject = useContext(UserContext);
    const { userId, accessToken} = userObject


    //onload faccio get a lista Danni per polare la select
    useEffect(()=>{
        if(accessToken==null){return}
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/richieste/danno ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista danni", result.message)
            if(result.message != undefined){
                setListaDanni(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    return(
        <div className="campoInserimentoRichiesta">
            <label>Danni</label>
            <select onChange={(e)=>setDanno(e.target.value)} value={danno} >
            <option value="">---</option>
            {listaDanni.map((danno)=>{
                    return <option key={danno.id} value={danno.id}>{danno.name}</option>
                })}
            </select>

        </div>
    )
}
export default Danno;