import  React, { useState , useEffect, useContext  } from 'react'

import { Chart } from "react-google-charts";


function ChartTemporale(props){
    const {listaRichieste, dataInizio, dataFine, setGiornataCritica} = props
    //dataInizio, dataFine sono date vere-non epoch

    var dati = [
        [
        "Day",`Numero Richieste`
        ]
    ]
    Date.prototype.addDays = function(days) {
        var dat = new Date(this.valueOf())
        dat.setDate(dat.getDate() + days);
        return dat;
    }

    function getDates(startDate, stopDate) {
        var dateAnnualiArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateAnnualiArray.push(currentDate)
            currentDate = currentDate.addDays(1);
        }
        return dateAnnualiArray;
    }

    //console.log(dataInizio, dataFine) //date vere-non epoch

    //creo un array di date dalla prima alla seconda data + utilizza la funzione getDates
    var dateAnnualiArray = getDates(dataInizio, dataFine);
    
    //creo un array di giorni/mese, manipolando l'array di date, dal primo giorno fino ad oggi
    var giorniArray = [];
    dateAnnualiArray.forEach((item)=>{
        let dd = item.getDate()
        let mm = item.getMonth()+1
        giorniArray.push( dd  +'/'+ mm )
    })
    //console.log("ggarray", giorniArray)
    //creo un array con la stessa lunghezza dell'array dei giorni
    var counter = new Array(giorniArray.length).fill(0);

    //ora che counter e giorniArray hanno la stessa lunghezza => i loro indici corrispondono
    //faccio la funzione di conteggio
    listaRichieste.forEach(req=>{
        let reqEpoch= req.epoch; //prendo le req.epoch 
        let dataReq = new Date( +reqEpoch) //le trasformo in data dataReq
        let dd = dataReq.getDate()
        let mm = dataReq.getMonth()+1
        let giornoEMese = dd +'/'+ mm
        let index = giorniArray.indexOf(giornoEMese) //prendo l'indice del giorno per aumentare il counter di quel giorno li
        counter[index] += 1;
    })

    //console.log("counter", counter)
    giorniArray.forEach((giorno,i)=>{
        dati.push([giorno, counter[i]])
    })
    
    useEffect(()=>{
        //console.log(dati)
        let thisYear = new Date().getFullYear(); 

        var giornoMax=['',0];
        dati.forEach((gg)=>{
                if(gg[1]>giornoMax[1]){
                giornoMax=gg
            }
        })
        console.log(giornoMax)
        if(giornoMax[0] !==''){setGiornataCritica({giorno:giornoMax[0].replace('/', '-')+'-'+thisYear, numero:giornoMax[1]})}

    },[listaRichieste])

    
    return(
        <Chart
            width={'400px'}
            height={'250px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data = {dati}
            options = {{
                backgroundColor: { fill:'transparent' },
                curveType: 'function',
                legend: { position: "top" }
            }}
            />
    );
}

export default ChartTemporale;


