
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";

import MappaVisualizzaSingolaComp from "./InserimentoRichiestaComponents/MappaVisualizzaSingola.js"
import ModificaRichiesta from "./ModificaRichiesta.js"
import ImmaginiComponent from "./ImmaginiComponent.js"
import EliminaRichiesta from  "./EliminaRichiesta.js"

import "../style/SingolaRichiesta.css"
import baseUrl from '../utilities/globalVariables.js'
import Footer from "./Footer.js"
import {useHistory} from "react-router-dom"; 


function SingolaRichiesta(props){
    //console.log("PROPS", props)
    const apiUrl= baseUrl + "/api/v1/richieste/singolarichiesta";
    const idRichiesta = props.history.location.state.idRichiesta;
    const emergenzaPage = props.history.location.state.emergenzaPage || false; //questa var se arriva da emergenzaPage è true se arriva da ricercaRichiesta è false (serve x non far modificare le richieste archiviate delle emergenze passate!!!)
    const [avviso, setAvviso]= useState({text:'', className:''});
    
    //console.log(emergenzaPage)
    const [richiesta, setRichiesta] = useState()
    const [immaginiComponent, setImmaginiComponent] = useState(false)
    
    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;
    const userObject = useContext(UserContext);
    const { setUsername, userId, setUserId, ruolo, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject


    let history = useHistory();

    if(accessToken == null){
        console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    //per render condizionato ai ruoli
    var superadmin= ruolo.id==5?true:false;
    var user= ruolo.id==1? true:false;

    //stati per la modifica: quelli con la parole "assegnato" sono i precedenti: vengono usati come placeholder
    const [geomAssegnato, setGeomAssegnato]= useState('');
    const [idLocalitaAssegnata, setIdLocalitaAssegnata]= useState('');
    const [nomeLocalitaAssegnata, setNomeLocalitaAssegnata]= useState('');
    const [municipioAssegnato, setMunicipioAssegnato]= useState('');
    const [indirizzoAssegnato, setIndirizzoAssegnato]= useState('');
    const [civicoAssegnato, setCivicoAssegnato]= useState(null); //RESTA NULL
    const [tipoRichiedenteAssegnato, setTipoRichiedenteAssegnato]= useState();//questo serve solo per visualizzare la scelta precedente

    const [oggettoRichiestaAssegnato, setOggettoRichiestaAssegnato]= useState();//questo serve solo per visualizzare la scelta precedente
    const [noteOggettoAssegnato, setNoteOggettoAssegnato]= useState('');
    const [idAlberoAssegnato, setIdAlberoAssegnato]= useState(null);

    const [interferenzaAssegnata, setInterferenzaAssegnata]= useState('');
    const [noteInterferenzaAssegnataInput, setNoteInterferenzaAssegnataInput]= useState(false);
    const [testoNoteInterferenzaAssegnata, setTestoNoteInterferenzaAssegnata]= useState('');

    const [priorityAssegnata, setPriorityAssegnata]= useState();//questo serve solo per visualizzare la scelta precedente
    const [dannoAssegnato, setDannoAssegnato]= useState();//questo serve solo per visualizzare la scelta precedente
    
    const [tipoInterventoAssegnato, setTipoInterventoAssegnato]= useState();//questo serve solo per visualizzare la scelta precedente
    const [noteInterventoAssegnato, setNoteInterventoAssegnato]= useState('');

    const [mezzoAssegnato, setMezzoAssegnato]= useState([]);//questo serve solo per visualizzare la scelta precedente
    
    const [statoRichiestaAssegnata, setStatoRichiestaAssegnata]= useState();//questo serve solo per visualizzare la scelta precedente
    const [mostraCausaAnnullamentoAssegnata, setMostraCausaAnnullamentoAssegnata]= useState(false)
    const [causaAnnullamentoAssegnata, setCausaAnnullamentoAssegnata]= useState();//questo serve solo per visualizzare la scelta precedente
    const [mostraIdDuplicataAssegnata, setMostraIdDuplicataAssegnata]= useState(false)
    const [idDuplicataAssegnata, setIdDuplicataAssegnata]= useState(null);//questo serve solo per visualizzare la scelta precedente
    const [mostraDataRisoluzioneAssegnata, setMostraDataRisoluzioneAssegnata]= useState(false)
    const [dataRisoluzioneAssegnata, setDataRisoluzioneAssegnata]= useState(null);
    
    //stati per render condiziato alle scelte
    const [noteOggettoAssegnatoInput, setNoteOggettoAssegnatoInput]= useState(false);
    const [noteInterventoAssegnatoInput, setNoteInterventoAssegnatoInput]= useState(false);

    //PARTE PER MOSTRARE IL COMPONENT DELLA MODIFICA
    const [mostraParteModifica, setMostraParteModifica] = useState(false);

    //FECTH PER PRENDERE LA SINGOLA RICHIESTA con tutte le info => setto lo stato richiesta. la sua modifica fa partire lo useEffect successivo
    useEffect(()=>{ 

        var myHeaders = new Headers();
        myHeaders.append("x-idrichiesta", idRichiesta);
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log("GET RESULT:Singola Richiesta Cliccata", result.message)
                if(result.message){
                    setRichiesta(result.message);
                }
            })
            .catch(error => console.log('error', error));
    },[accessToken, idRichiesta, mostraParteModifica])

    //questo piena lo stato richiesta con tutti i dati presi dalla fetch
    useEffect(()=>{
        //console.log("stato richiesta che fa partire useEffect",richiesta)
        if(!richiesta){return}
        if(richiesta.geom){ setGeomAssegnato(richiesta.geom)}
        if(richiesta.idLocalità){ setIdLocalitaAssegnata(richiesta.idLocalità)}
        if(richiesta.nomeLocalità){ setNomeLocalitaAssegnata(richiesta.nomeLocalità)}
        if(richiesta.municipio){ setMunicipioAssegnato(richiesta.municipio)}
        if(richiesta.indirizzo){ setIndirizzoAssegnato(richiesta.indirizzo)}
        if(richiesta.civico){ setCivicoAssegnato(richiesta.civico)}
        if(richiesta.tipirichiedenteId){ setTipoRichiedenteAssegnato(richiesta.tipirichiedente.name)}
        if(!richiesta.idAlbero){ //se è null non cambia l'input in vuoto altrimenti
            setIdAlberoAssegnato('')
        }else{
            setIdAlberoAssegnato(richiesta.idAlbero)
        }
        if(richiesta.interferenza==true){
            setInterferenzaAssegnata('Si')
            setTestoNoteInterferenzaAssegnata(richiesta.noteInterferenza)
            setNoteInterferenzaAssegnataInput(true)
        }else if(richiesta.interferenza==false){
            setInterferenzaAssegnata('No')
            setTestoNoteInterferenzaAssegnata(richiesta.noteInterferenza)
            setNoteInterferenzaAssegnataInput(false)
        }

        if(richiesta.oggettirichiestaId){ setOggettoRichiestaAssegnato(richiesta.oggettirichiestum.name)}
        if(richiesta.oggettirichiestaId==3){
            setNoteOggettoAssegnatoInput(true); 
            setNoteOggettoAssegnato(richiesta.noteOggetto)
        }else{
            setNoteOggettoAssegnatoInput(false); 
            setNoteOggettoAssegnato('')
        }
        if(richiesta.prioritiesId){setPriorityAssegnata(richiesta.priority.name)}
        if(richiesta.danniId){setDannoAssegnato(richiesta.danni.name)}
        if(richiesta.tipiinterventoId){ setTipoInterventoAssegnato(richiesta.tipiintervento.name)}

        if(richiesta.noteIntervento!=""){
            setNoteInterventoAssegnatoInput(true); 
            setNoteInterventoAssegnato(richiesta.noteIntervento);
        }else{
            setNoteInterventoAssegnatoInput(false); 
            setNoteInterventoAssegnato('');
        }
        if(richiesta.mezzis.length!=0){
            let value = Array.from(richiesta.mezzis, option => option);
            setMezzoAssegnato(value);   
        }
        if(richiesta.statirichiestaId){
            setStatoRichiestaAssegnata(richiesta.statirichiestum.name)
            if(richiesta.statirichiestaId == 7){ //annullata
                setMostraCausaAnnullamentoAssegnata(true)
                setMostraDataRisoluzioneAssegnata(false)
            }else if (richiesta.statirichiestaId == 5){ //risolta
                setMostraCausaAnnullamentoAssegnata(false)
                setMostraDataRisoluzioneAssegnata(true)
            }else{
                setMostraCausaAnnullamentoAssegnata(false)
                setMostraDataRisoluzioneAssegnata(false)
            }
        }
        if(richiesta.causeannullamentoId){ 
            setCausaAnnullamentoAssegnata(richiesta.causeannullamento.name); 
            if(richiesta.statirichiestaId == 7&&richiesta.causeannullamentoId == 3){ //duplicata
                setMostraIdDuplicataAssegnata(true)
            }else{
                setMostraIdDuplicataAssegnata(false)
            }
        }
        if(!richiesta.idRichiestaDuplicata){ //se è null non cambia l'input in vuoto altrimenti
            setIdDuplicataAssegnata('')
        }else{
            setIdDuplicataAssegnata(richiesta.idRichiestaDuplicata)
        }
        if(richiesta.dataRisoluzione){ setDataRisoluzioneAssegnata(richiesta.dataRisoluzione.split("T")[0]); }else{ setDataRisoluzioneAssegnata('')}
    },[richiesta])

    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
            <div className="contenutoMain">
                {!mostraParteModifica&&
                <div><MappaVisualizzaSingolaComp richiesta={richiesta}>  </MappaVisualizzaSingolaComp>
                <div className="formInserimentoRichiesta">
                    <p className="titoloformInserimentoRichiesta">VISUALIZZA RICHIESTA {idRichiesta}</p>
                    
                    <div className="formInserimentoRichiestaLeft">
                        <p className="titoloDatiContainer">Dati Geografici</p>
                        <div className="datiGeograficiContainer">

                            <div className="campoSingolaRichiesta">
                                <label>Latitudine, Longitudine</label>
                                <input  type="text" value={geomAssegnato} disabled="disabled" ></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Id Località</label>
                                <input  type="text" value={idLocalitaAssegnata} disabled="disabled"></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Nome Località</label>
                                <input  type="text" value={nomeLocalitaAssegnata} disabled="disabled"></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Municipio</label>
                                <input  type="text" value={municipioAssegnato} disabled="disabled"></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Indirizzo</label>
                                <input  type="text" value={indirizzoAssegnato} disabled="disabled"></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Civico</label>
                                <input  type="varchar(6)" value={civicoAssegnato} disabled="disabled"></input>
                            </div>
                        </div>
                    </div>
                    <div className="formInserimentoRichiestaRight">
                        <p className="titoloDatiContainer">Dati Generali</p>
                        <div className="datiGeneraliContainer">
                            
                            <div className="campoSingolaRichiesta">
                                <label>Tipo Richiedente</label>
                                <input value={tipoRichiedenteAssegnato} disabled="disabled"></input>
                            </div>

                            <div className="campoSingolaRichiesta">
                                <label>Oggetto Richiesta</label>
                                <input value={oggettoRichiestaAssegnato} disabled="disabled"></input>
                            </div>

                            {noteOggettoAssegnatoInput&&<div className="campoSingolaRichiesta">
                                <label>Note Oggetto</label>
                                <textarea maxLength="150" value={noteOggettoAssegnato}  disabled="disabled"></textarea>
                            </div>}
                            <div className="campoSingolaRichiesta">
                                <label>Id Albero</label>
                                <input  type="number" value={idAlberoAssegnato} disabled="disabled"></input>
                            </div>
                            <div className="campoSingolaRichiesta">
                                <label>Interferenza</label>
                                <input  type="text" value={interferenzaAssegnata} disabled="disabled"></input>
                            </div>
                            {noteInterferenzaAssegnataInput&&<div className="campoSingolaRichiesta">
                                <label>Note Interferenza</label>
                                <input  type="text" value={testoNoteInterferenzaAssegnata} disabled="disabled"></input>
                            </div>}

                            <div className="campoSingolaRichiesta">
                                <label>Priorità</label>
                                <input value={priorityAssegnata} disabled="disabled"></input>
                            </div>
                            
                            <div className="campoSingolaRichiesta">
                                <label>Danni</label>
                                <input value={dannoAssegnato} disabled="disabled"></input>
                            </div>

                            <div className="campoSingolaRichiesta">
                                <label>Tipo Intervento</label>
                                <input value={tipoInterventoAssegnato} disabled="disabled"></input>
                            </div>

                            {noteInterventoAssegnatoInput&&<div className="campoSingolaRichiesta">
                                <label>Note Intervento</label>
                                <textarea maxLength="150" value={noteInterventoAssegnato}  disabled="disabled"></textarea>
                            </div>}
                            

                            {!user&&<div className="campoSingolaRichiesta">
                                <span>Mezzo/i Assegnato/i</span>
                                <div >
                                {mezzoAssegnato.map((item)=>{
                                            return <div key={item.id} value={item.id}>{item.targaMezzo} - {item.user?.username} {item.deleted?'(Utente eliminato!)':''}</div>
                                        })}
                                </div>
                            </div>}
                            
                            <div className="campoSingolaRichiesta">
                                <label>Stato Richiesta</label>
                                <input value={statoRichiestaAssegnata} disabled="disabled"></input>
                            </div>
                            
                            {mostraCausaAnnullamentoAssegnata &&<div className="campoSingolaRichiesta">
                                <label>Causa Annullamento</label>
                                <input value={causaAnnullamentoAssegnata} disabled="disabled"></input>
                            </div>}

                            {mostraIdDuplicataAssegnata&&<div className="campoSingolaRichiesta">
                                <label>Id duplicata</label>
                                <input value={idDuplicataAssegnata} disabled="disabled"></input>
                            </div>}

                            {mostraDataRisoluzioneAssegnata&&<div className="campoSingolaRichiesta">
                                <label>Data Risoluzione</label>
                                <input  type="date"  value={dataRisoluzioneAssegnata} disabled="disabled" ></input>
                            </div>}
                        </div> 
                    </div> 
                    <div className="containerBottoniConferma">
                        <div className={`bottoneConferma ${immaginiComponent?"attivo":""}`} onClick={()=>setImmaginiComponent(!immaginiComponent)}>IMMAGINI</div>  
                        {(!user&&!mostraParteModifica&&!emergenzaPage)&&
                            <div className="bottoneConferma" onClick={()=>setMostraParteModifica(true)}>MODIFICA</div>}
                            {(superadmin&&!emergenzaPage)&&<EliminaRichiesta idRichiesta={idRichiesta}></EliminaRichiesta>}

                    </div>
                    {immaginiComponent&&<ImmaginiComponent idRichiesta= {idRichiesta} modifica={false} ></ImmaginiComponent>}
                </div>
            </div>}

            {mostraParteModifica&&
                <ModificaRichiesta 
                    richiestaDaModificare={richiesta} 
                    setMostraParteModifica={setMostraParteModifica} 
                    setAvviso={setAvviso}>
                </ModificaRichiesta>}

            <p className={`avviso ${avviso.className}`}>{avviso.text}</p>
            </div>
            <Footer></Footer>
        </div>
    
    )
}
export default SingolaRichiesta;
