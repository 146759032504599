import * as React from "react";
import { Chart } from "react-google-charts";


function ChartRichiedente(props){
    const listaRichieste = props.listaRichieste;
    var [tipo1, tipo2, tipo3, tipo4, tipo5, tipo6, tipo7, tipo8]=[0,0,0,0,0,0,0,0,]
    listaRichieste.forEach(item=>{
        if(item.tipirichiedenteId==1){ tipo1+=1}
        if(item.tipirichiedenteId==2){ tipo2+=1}
        if(item.tipirichiedenteId==3){ tipo3+=1}
        if(item.tipirichiedenteId==4){ tipo4+=1}
        if(item.tipirichiedenteId==5){ tipo5+=1}
        if(item.tipirichiedenteId==6){ tipo6+=1}
        if(item.tipirichiedenteId==7){ tipo7+=1}
        if(item.tipirichiedenteId==8){ tipo8+=1}
    })


    return(
        <Chart
            width={'450px'}
            height={'300px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
                ["Tipo", "Numero"],
                [ "Cittadino", tipo1 ],
                [  "Polizia Locale", tipo2 ],
                [ "Vigili del Fuoco", tipo3 ],
                [ "Protezione Civile",  tipo4 ],
                [  "Comune di Milano",  tipo5 ],
                [  "Personale RTI", tipo6 ],
                [ "Scuola",  tipo7 ],
                [ "Altro", tipo8 ],
            ]}
            options = {{
                backgroundColor: { fill:'transparent' },
            }}
            />
    );
}

export default ChartRichiedente;


