/* questo component conterrà solo la label e la select della lista danno , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useEffect} from 'react';
import L from 'leaflet';
import {useHistory} from "react-router-dom";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import icon from  '../../imgs/avr.svg';
import greenIcon from '../../imgs/greenM.svg';
import redIcon from '../../imgs/redM.svg';
import orangeIcon from '../../imgs/orangeM.svg';
import greyIcon from '../../imgs/greyM.svg';

import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize : [50,50],
    /* popupAnchor: [12, 10], */

});

L.Marker.prototype.options.icon = DefaultIcon;

const LeafIcon = L.Icon.extend({
    options: {}
});

function Mappa(props){
    //MAPPA CHE FA VISUALIZZARE PIU DI UN MARKER => PIU DI UNA RICHIESTA
    let history = useHistory();
    const [markers, setMarkers] = useState([]);
    const setMappaVisualizza = props.setMappaVisualizza;
    //per le etichette della mappa
    const [hiddenTooltip, setHiddenTooltip] = useState(false)

    var richiesteInseriteDaVisualizzare = [];
    props.rows.forEach((row) => {
        richiesteInseriteDaVisualizzare.push(row.original)
    })
    const [richiesteInseriteDaVisualizzareState, setReqs] = useState(richiesteInseriteDaVisualizzare)
    //console.log("RichiesteInseriteDaVisualizzare", richiesteInseriteDaVisualizzareState)

    var arrayMarkers=[];

    useEffect(()=>{ //per ogni richiesta vado a costruire un marker con lat e longitudine
        if(richiesteInseriteDaVisualizzareState){
            richiesteInseriteDaVisualizzareState.forEach(item=>{

                var lat = +item.geom.split("(")[1].replace(/[)]/g, '').split(",")[0]
                var lng = +item.geom.split("(")[1].replace(/[)]/g, '').split(",")[1]
                var id= item.id;

                var statoRichiesta= item.statirichiestaId; //mi serve per discriminare il colore del marker
                const newMarker = { lat: lat, lng: lng, id:id, statoRichiesta:statoRichiesta}
                arrayMarkers.push(newMarker)

            })
            const array2= arrayMarkers //appoggio array
            setMarkers(array2); 
        }
    },[richiesteInseriteDaVisualizzareState])

    //centro la mappa su avr
    const initialCoordinates = [45.44956343385593, 9.254375202676734] //milano

    const greenIconM = new LeafIcon({
        iconUrl: greenIcon,
        iconAnchor: [24, 46], 
        iconSize: [40,40],
        popupAnchor: [0, -50],

    })
    const orangeIconM = new LeafIcon({
        iconUrl: orangeIcon,
        iconAnchor: [24, 46], 
        iconSize: [40,40],
        popupAnchor: [0, -50],

        })
    const redIconM = new LeafIcon({
        iconUrl: redIcon,
        iconAnchor: [24, 46], 
        iconSize: [40,40],
        popupAnchor: [0, -50],

        })
    const greyIconM = new LeafIcon({
        iconUrl: greyIcon,
        iconAnchor: [24, 46], 
        iconSize: [40,40],
        popupAnchor: [0, -50],
        
        })

    //con questa funzione passo l'id della cliccata come prop alla pagina dopo : SE LA CLICCO APRE LA SINGOLA RICHIESTA
    function clickedPopUp(e){
        //console.log(e.target.id)
        history.push({
            pathname: '/singolarichiesta',
            state: { idRichiesta: e.target.id}
        });
    }

    return(

        <div> 
        <div className="mappaComponentVisualizza"> 
            <p className="headMappa" onClick={()=>setMappaVisualizza(false)}> MAPPA RICHIESTE<span > (Clicca su una richiesta per aprirla!)</span></p>
            {!hiddenTooltip&&
                <div className="containerIconaEtichette">
                    <FontAwesomeIcon icon={faEye} onClick={()=>setHiddenTooltip(true)}></FontAwesomeIcon>
                    <span className="containerIconaEtichetteSpan">Nascondi Etichette </span>
                </div>
                }
            {hiddenTooltip&&
                <div className="containerIconaEtichette">
                    <FontAwesomeIcon icon={faEyeSlash} onClick={()=>setHiddenTooltip(false)}></FontAwesomeIcon>
                    <span className="containerIconaEtichetteSpan">Mostra Etichette </span>
                </div>
            }

            <div className="mappaContainerVisualizza" >
                <MapContainer center={{lat:45.464159, lon:9.191647}} zoom={13} scrollWheelZoom={true}/*  zoomControl={false} */maxZoom={19} doubleClickZoom={false}>
                    <TileLayer maxZoom={19}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={initialCoordinates}>
                        <Popup className="popUpAvr">
                            AVR S.p.A
                        </Popup>
                    </Marker>
                    {markers.map((marker,i) => {
                        //console.log("marker", marker)
                        if(marker.statoRichiesta == 6 || marker.statoRichiesta == 5 || marker.statoRichiesta == 4){
                            return <Marker key={i} position={marker} icon={greenIconM}>
                                        <Popup> <p className="markerPopupVisualizza" id={marker.id} onClick={(e)=>clickedPopUp(e)}>RICHIESTA {marker.id}</p></Popup>
                                        {!hiddenTooltip&&<Tooltip direction="right" offset={[10, -30]} opacity={0.85} permanent>{marker.id}</Tooltip>}

                                    </Marker>
                        }
                        if(marker.statoRichiesta== 1){
                            return <Marker key={i} position={marker} icon={redIconM}>
                                        <Popup><p className="markerPopupVisualizza" id={marker.id} onClick={(e)=>clickedPopUp(e)}>RICHIESTA {marker.id}</p></Popup>
                                        {!hiddenTooltip&&<Tooltip direction="right" offset={[10, -30]} opacity={0.85} permanent>{marker.id}</Tooltip>}

                                    </Marker>
                        }
                        if(marker.statoRichiesta == 2  || marker.statoRichiesta == 3){
                            return <Marker key={i} position={marker} icon={orangeIconM}>
                                        <Popup><p className="markerPopupVisualizza" id={marker.id} onClick={(e)=>clickedPopUp(e)}>RICHIESTA {marker.id}</p></Popup>
                                        {!hiddenTooltip&&<Tooltip direction="right" offset={[10, -30]} opacity={0.85} permanent>{marker.id}</Tooltip>}

                                    </Marker>
                        }
                        if(marker.statoRichiesta== 7 ){
                            return <Marker key={i} position={marker} icon={greyIconM}>
                                        <Popup ><p className="markerPopupVisualizza" id={marker.id} onClick={(e)=>clickedPopUp(e)}>RICHIESTA {marker.id}</p></Popup>
                                        {!hiddenTooltip&&<Tooltip direction="right" offset={[10, -30]} opacity={0.85} permanent>{marker.id}</Tooltip>}

                                    </Marker>
                        }
                        })}
                       {/*  <StatoFlottaMarkers></StatoFlottaMarkers> */}
                </MapContainer>
            </div>
            <div className="legendaVisualizzaMappa"> In <span style={{color:'red', fontWeight:'bold'}}>ROSSO</span>  le richieste: Inserite. <br/> In <span style={{color:'orange', fontWeight:'bold'}}>ARANCIONE</span> le richieste: Prese in carico, Messe in Sicurezza.<br/> In  <span style={{color:'green', fontWeight:'bold'}}>VERDE</span> le richieste: Risolte, Risolte con Risulta, Chiuse.<br/> In <span style={{color:'grey', fontWeight:'bold'}}>GRIGIO</span> le richieste: Annullate.  </div>
            <FontAwesomeIcon icon={faTimes} className="faTimes" onClick={()=>setMappaVisualizza(false)}></FontAwesomeIcon> 
        </div>
        </div>
    )
}
export default Mappa;

// REVERSE GEOCODING https://nominatim.openstreetmap.org/reverse?lat=45.452982&lon=9.246903&format=json
// GEOCODING https://nominatim.openstreetmap.org/search?q=empoli%20via%20roma&format=json
// Nominatim https://nominatim.org/release-docs/latest/api/Reverse/
