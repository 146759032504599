import gemEnvironment from './environment'


const productionOSKey = '3024758a-7da0-4389-a422-296cab98d111'; //appId - OneSignal App ID
const developmentOSKey = 'c12236ed-9df5-4dfd-bc9c-0bafd60e8ca6'; //appId -localStor

const baseAuth = 'MmE3NjcyMmQtNWNkNS00YzgzLWIyOTktZGIxOWFhNGFjYzkx' //basicAPI - Rest API Key
const baseAuthDev = 'MDBlMmExYzAtMzFhNy00ZTZhLThjMDktMWZkNDI3ZWM1MDk5' //Basic

//CHANGE ONESIGNALkEYS FROM DEV TO PROD
//const oneSignalKeys = {appId:productionOSKey, basicAPI:baseAuth}
const oneSignalKeys = {appId:gemEnvironment=='development'?developmentOSKey:productionOSKey, basicAPI:gemEnvironment=='development'?baseAuthDev:baseAuth}

export default oneSignalKeys;