/* questo component conterrà solo la label e la select della lista oggetto , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";

import baseUrl from '../../utilities/globalVariables.js'



function CausaAnnullamento(props){
    const [listaCauseAnnullamento, setListaCauseAnnullamento] = useState([]);
    const [causaAnnullamento, setCausaAnnullamento]= [props.causaAnnullamento, props.setCausaAnnullamento];
    const userObject = useContext(UserContext);
    const { userId, accessToken} = userObject

    //onload faccio get a lista oggetti per polare la select
    useEffect(()=>{
        if(accessToken==null){return}
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl +"/api/v1/richieste/causaannullamento ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista causaannullamento", result.message)
            
            if(result.message != undefined){
                setListaCauseAnnullamento(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    return(
        <div className="campoInserimentoRichiesta">
            <label>Causa Annullamento Richiesta</label>
            <select onChange={(e)=>setCausaAnnullamento(e.target.value)} value={causaAnnullamento}>
            <option value="">---</option>
            {listaCauseAnnullamento.map((oggetto)=>{
                    return <option key={oggetto.id} value={oggetto.id}>{oggetto.name}</option>
                })}
            </select>
            <span>**Se non selezionato, verrà assegnato di default il valore: "Non Annullata"</span>

        </div>
    )
}
export default CausaAnnullamento;