import React, {useState, createContext} from 'react';

export const SideMenuContext = createContext();

export const SideMenuProvider = props =>{

    const [openSidemenu, setOpenSidemenu] = useState(false)
    var sideMenuContext={
        openSidemenu: openSidemenu,
        setOpenSidemenu: setOpenSidemenu
    };

    return(
        <SideMenuContext.Provider value ={ sideMenuContext }>
            {props.children}
        </SideMenuContext.Provider>
    );
}