
import { imageOverlay } from 'leaflet';
import {useState, useEffect, useContext} from 'react';
import {useHistory} from "react-router-dom";

import "../style/GridTable.css"

function GridTable(props) {
    const listaRichieste= props.listaRichieste
    const [listaUltime10, setListaUltime10]= useState([])
    let history = useHistory();
    

    useEffect(()=>{
        if(listaRichieste.length>11){
            let value=listaRichieste.slice(0, 10 )
            setListaUltime10(value)
        }
        else{
            setListaUltime10(listaRichieste)
        }
    },[listaRichieste])

    //con questa funzione passo l'id della cliccata come prop alla pagina dopo
    function clickedRow(e){
        //console.log(e.target.parentNode.id)
        history.push({
            pathname: '/singolarichiesta',
            state: { idRichiesta: e.target.parentNode.id}
        });
    }

    return(
        <div className="tabellaHomeMade">
            <div  className="tabellaHomeMadeTitle" >ULTIME RICHIESTE INSERITE</div>
            <div  >
                {listaUltime10.map((item,i)=>{
                    //console.log(listaUltime10)

                    return(
                            <div  className="rowTabella" key={i} id={item.id} onClick={(e)=>clickedRow(e)}>
                                <div className="rowTabellaId">{item.id}</div>
                                <div className="rowTabellaMunicipio">{item.municipio}</div>
                                <div className="rowTabellaIndirizzo">{item.nomeLocalità?item.nomeLocalità:item.indirizzo}</div>
                                <div className="rowTabellaPriority">{item.priority.name}</div>
                                <div className="rowTabellaTipoR">{item.statirichiestum.name}</div>
                            </div>
                            )

                })}
            </div>
        </div>
    )
}

export default GridTable;