import {useState, useEffect, useContext} from 'react';
import {useHistory} from "react-router-dom";

import React from 'react'

import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import MappaVisualizzaComp from "./InserimentoRichiestaComponents/MappaVisualizzaLeMie.js"
import "../style/LeMieRichieste.css"
import baseUrl from '../utilities/globalVariables.js'
import Footer from "./Footer.js"

function LeMieRichieste(){
    
    const apiUrl = baseUrl + "/api/v1/richieste/lemie";
    const userObject = useContext(UserContext);
    const { username, setUsername, userId, setUserId, ruolo, setRuolo,  accessToken, setAccessToken,  setCapoSquadra} = userObject


    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

    //import {useHistory} from "react-router-dom"; 
    let history = useHistory();

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }
    
    window.onbeforeunload = function() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('ruoloName');
        localStorage.removeItem('username');
        localStorage.removeItem('ruoloId');
        localStorage.removeItem('userId');
        localStorage.removeItem('caposquadra');
        return '';
    };
    const [listaRichieste, setListaRichieste] = useState([])
    const [listaRichiesteFiltered, setListaRichiesteFiltered] = useState([])
    const [listaMezzi, setListaMezzi] = useState([])
    const [avviso, setAvviso] = useState('')
    const [mappaVisualizza, setMappaVisualizza] = useState(false);
    const [visualizzaMezzi, setVisualizzaMezzi] = useState(false);
    const [soloAttive, setSoloAttive] = useState(false);
    const [orderByPriority, setOrderByPriority] = useState(false);

    var editor = ruolo.id==2?true:false; 
    
    /**get di tutti mezzi che sono associati a me- caposquadra con INCLUSE TUTTE le richieste associate ad ogni mezzo */
    useEffect(()=>{
        if(accessToken == null){return}
        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){ /**ho dei mezzi a me associati */
                //console.log(result.message)
                setListaMezzi(result.message);
                handleMezziToGetRichieste(result.message)
                setAvviso('')
            }else{
                setAvviso(result.error)
            }
        })
        .catch(error => console.log('error', error));

    },[userId, accessToken])

    function handleMezziToGetRichieste(mezzi){
        var richiesteManipolate=[];
        //console.log("MEZZI", mezzi);
        mezzi.forEach(item=>{
            if(item.richiestes.length!=1){
                item.richiestes.forEach(req =>richiesteManipolate.push(req))
            }else{
                richiesteManipolate.push(item.richiestes[0])
            }
        })
        
        /**tolgo le richieste doppione perchè magari associate a due o +  mezzi MIEI*/
        const richiesteManipolateSenzaDoppioni= richiesteManipolate.reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        //ordinaperID e quindi DATACREAZIONE
        richiesteManipolateSenzaDoppioni.sort( (a,b)=>{
            return b.id - a.id
        })
        setListaRichieste(richiesteManipolateSenzaDoppioni)
        setListaRichiesteFiltered(richiesteManipolateSenzaDoppioni)
    }


    //con questa funzione passo l'id della cliccata come prop alla pagina dopo
    function clickedRow(e){
        //console.log(e.target.id)
        //console.log(e.target.parentNode.id || e.target.id)
        history.push({
            pathname: '/singolarichiesta',
            state: { idRichiesta: e.target.parentNode.id || e.target.id}
        });
    }

    //questa funzione filtra le richeiste e mostra solo quelle che NON sono già state risolte o chiuse 
    useEffect(()=>{
        if(soloAttive){
            const listaSoloAttive = listaRichieste.filter((item)=>{
                if(item.statirichiestaId != 4 && item.statirichiestaId != 5 && item.statirichiestaId != 6  ){
                    return item;
                }
            })
            setListaRichiesteFiltered(listaSoloAttive)
        } else{
            setListaRichiesteFiltered(listaRichieste) //TUTTE LE FETCHATE
        }
    },[soloAttive])

    //questa funzione filtra le richeiste e le ordina per priorità
    useEffect(()=>{
        if(orderByPriority){ //ordinaper Priority
            listaRichiesteFiltered.sort( (a,b)=>{
                return a.prioritiesId - b.prioritiesId
            })
            console.log("SONO STATE ORDINATE X PRIORITà")
        }else{ //ordinaperID e quindi DATACREAZIONE
            listaRichiesteFiltered.sort( (a,b)=>{
                return b.id- a.id})
            console.log("SONO STATE ORDINATE X DATA")
        }
    },[orderByPriority, listaRichiesteFiltered])

    function orderListaOnclick(){
        if(!orderByPriority){ //ordinaper Priority
            listaRichiesteFiltered.sort( (a,b)=>{
                return a.prioritiesId - b.prioritiesId
            })
            console.log("SONO STATE ORDINATE X PRIORITà")
        }else{ //ordinaperID e quindi DATACREAZIONE
            listaRichiesteFiltered.sort( (a,b)=>{
                return b.id- a.id})
            console.log("SONO STATE ORDINATE X DATA")
        }
        setOrderByPriority(!orderByPriority)
    }

    //console.log("RichiesteFFF", listaRichiesteFiltered)


    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}

            {editor&&<div className="contenutoMain">
                <div className="titoloMieRichieste"> Buongiorno, {username}. Qui puoi vedere le <b>{listaRichieste.length}</b> richieste che sono state assegnate ai tuoi <b>{listaMezzi.length}</b> mezzi. 
                <br/>Clicca su una richiesta per aprirla. </div>
                <div className="containerBottoni">
                    <div className="leftBottoni" onClick={()=>setMappaVisualizza(!mappaVisualizza)}>Visualizza su Mappa</div>
                    <div className="rightBottoni" onClick={()=>orderListaOnclick()}>Ordina per <br/>{!orderByPriority&& "PRIORITÀ"}{orderByPriority&& "DATA CREAZIONE"}</div>
                    <div className={` leftBottoni ${soloAttive?'attivo':''}` }  onClick={()=>setSoloAttive(!soloAttive)}>Mostra solo Attive <span className="smallFont">(NON Risolte o NON Chiuse)</span></div>
                    <div className={`rightBottoni ${visualizzaMezzi?"attivo":""}`} onClick={()=>setVisualizzaMezzi(!visualizzaMezzi)}>I miei Mezzi</div>
                </div>
                <p className="avvisoNoRichieste"> {avviso}</p>

                {mappaVisualizza&&<MappaVisualizzaComp data={listaRichiesteFiltered} setMappaVisualizza={setMappaVisualizza}></MappaVisualizzaComp>}

                {visualizzaMezzi&&<div className="contenitoreMezzi">
                    {listaMezzi.map((item,i)=>{
                        return <div key={i}  className="singoliMezzi"> {item.targaMezzo} - {item.tipoMezzo} </div>
                    })}
                </div>}

                <div className="contenitoreRichieste">
                    <div className="titoloContainerMieRichieste">
                        <span className="id">ID</span>
                        <span className="indirizzoCivico">INDIRIZZO</span>
                        <span className="priority">PRIORITÀ</span>
                        <span className="tipoIntervento">TIPO INTERVENTO</span>
                        <span className="immaginiNumero">IMMAGINI</span></div>
                    {listaRichiesteFiltered.map((item,i)=>{
                        return <div key={i} id={item.id} className="singoleRichieste" onClick={(e)=>clickedRow(e)}> 
                                    <span className="id">{item.id}</span>
                                    <span  className="indirizzoCivico">{item.indirizzo?item.indirizzo+', ':item.nomeLocalità+', '} {item.civico?item.civico+' - ':''} {item.municipio}</span> 
                                    <span  className={` priority ${item.priority.name}`}>{item.priority.name}</span>
                                    {item.tipiinterventoId==5 && <span className="tipoIntervento">{item.tipiintervento.name}{item.noteIntervento?': '+ item.noteIntervento:''}</span>}
                                    {item.tipiinterventoId!=5 && <span className="tipoIntervento">{item.tipiintervento.name}</span>}
                                    <span className="immaginiNumero">{item.immaginis.length}</span>
                                </div>
                    })}
                </div>
            </div>}
            {!editor&&<div className="contenutoMain">
                <p className="titoloMieRichieste">Non sei un Editor!</p>
                </div>}


            <Footer></Footer>
        </div>
    )
}
export default LeMieRichieste