

import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";

import baseUrl from '../../utilities/globalVariables.js'

function InserisciMezzo(props){

    //interni al component
    const [avviso, setAvviso]= useState({text:'', className:''});

    const [targaMezzo, setTargaMezzo]= useState('');
    const [idCapoSquadra, setIdCapoSquadra]= useState('');
    const [tipoMezzo, setTipoMezzo]= useState('');
    const [listaCapiSquadra, setListaCapiSquadra] = useState([]);
    const mezziURL= baseUrl + "/api/v1/mezzi";
    const utentiURL= baseUrl + "/api/v1/users/caposquadra";

    const userObject = useContext(UserContext);
    const { userId, accessToken} = userObject


    const setRenderAutomatico = props.setRenderAutomatico;
    const renderAutomatico =  props.renderAutomatico;
    function controlliPreCreazione(){
        /* Per i campi obbligatori: se passa tutti i controlli chiami la f(x) */
        if( tipoMezzo==''||targaMezzo==''||idCapoSquadra==''){
            setAvviso({text:'Tutti i campi devono essere compilati!', className:'red'});
            return;
        }
        creaNuovaRichiesta()
    }

    useEffect(()=>{
        //prendo tutti i capi squadra!!!!
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
            
            fetch(utentiURL, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result.message)
                if(result.message){
                    setListaCapiSquadra(result.message) 
                    /* let arrayModificato=[];
                    result.message.forEach((item)=>{
                    if(item.roleId==2 && item.capoSquadraFlag==true && item.deleted == false){
                        arrayModificato.push(item)
                        }
                    })
                    //console.log("array filtrato capisquadre", arrayModificato)
                    setListaCapiSquadra(arrayModificato) */
                }
                if(result.error){ setListaCapiSquadra([])}
            })
            .catch(error => console.log('error', error));
    },[accessToken])

    function creaNuovaRichiesta(){        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("targaMezzo", targaMezzo);
        urlencoded.append("capoSquadraId", idCapoSquadra);
        urlencoded.append("tipoMezzo", tipoMezzo);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(mezziURL, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                setAvviso({text:'Mezzo inserito correttamente', className:'green'})
                setTargaMezzo('')
                setTipoMezzo('')
                setIdCapoSquadra('')
                setRenderAutomatico(!renderAutomatico)
            }else if (result.error){
                setAvviso({text:result.error, className:'red'})
            }
        
        
        })
        .catch(error => console.log('error', error));
    }


    return(
        <div className="inserimentoMezzoContainer"> 
            <p>Per <b>inserire</b> un nuovo mezzo compila i seguenti campi:</p>
            
            <div className="campoInserimentoMezzo">
                <label>Targa del Mezzo</label>
                <input  type="text" onChange={(e)=>setTargaMezzo(e.target.value.toUpperCase().replace(/\s+/g, ''))}></input>
            </div>
            <div className="campoInserimentoMezzo">
                <label> Capo Squadra:</label> 

                <select onChange={(e)=>setIdCapoSquadra(e.target.value)}>
                    <option >---</option>
                    {listaCapiSquadra.map((user)=>{
                            return <option key={user.id} value={user.id}>{user.username}</option>
                        })}
                </select>
            </div>
            <div className="campoInserimentoMezzo">
                <label>Tipo di Mezzo e altre caratteristiche</label>
                <input  type="text" onChange={(e)=>setTipoMezzo(e.target.value)}></input>
            </div>

            <div className="bottoneConferma" onClick={()=>controlliPreCreazione()}>INSERISCI</div>
            <span className={` avviso ${avviso.className}`}>{avviso.text}</span>

        </div>
    )
}

export default InserisciMezzo;
