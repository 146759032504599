import {useState,useContext, useEffect} from 'react';
import { UserContext } from "../Context/UserContext.js";

import "../style/ImmaginiComponent.css"
import baseUrl from '../utilities/globalVariables.js'


function ImmaginiComponent(props){
    const userObject = useContext(UserContext);
    const {userId, ruolo, accessToken} = userObject

    //per render condizionato ai ruoli
    var editor = ruolo.id==2?true:false;
    var adminOrMore = ruolo.id==5||ruolo.id==4?true:false;

    const [avviso, setAvviso]= useState({text:'', className:''});

    const [listaImmagini, setListaImmagini]= useState([]);
    const [forceRerender, setForceRerender]= useState(true)
    const [listaTipiImmagini, setListaTipiImmagini]= useState([]);

    const [openImage, setOpenImage]= useState(false)
    const [urlCliccata, setUrlCliccata]= useState('')
    const [foto, setFoto] = [props.foto, props.setFoto];
    const tipoImmagine = props.tipoImmagine;   
    const setTipoImmagine = props.setTipoImmagine;
    const modifica = props.modifica; //se renderizzo questo dopo aver cliccato modifica appaiono dei campi in piu
    const idRichiesta= props.idRichiesta;
    const [modal, setModal] = useState(false)
    const [imgToDelete, setImgToDelete] = useState(0)

    //onload faccio get a lista tipi img per popolare la select
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/immagini/tipi", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista tipiimmagini", result.message)
            if(result.message != undefined){
                setListaTipiImmagini(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])


    //GET ALL IMMAGINI
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        myHeaders.append("x-richiestaid", idRichiesta);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/immagini", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            if(result.message.length > 0) {
                setListaImmagini(result.message)
            }else if(result.message.length == 0){
                setListaImmagini([])
                setAvviso({text:"Non ci sono immagini da mostrare", className:'red'})
            }else if(result.error){
                setAvviso({text:result.error, className:'red'})
            }
        })
        .catch(error => console.log('error', error));

    },[forceRerender])

    function handleEliminaImg(){
        if(imgToDelete == 0){
            setModal(false);
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("idImmagine", imgToDelete);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/immagini", requestOptions)
        .then(response => response.text())
        .then(result => {
            console.log(result)
            setModal(false)
            setForceRerender(!forceRerender)

        })
        .catch(error => console.log('error', error));

    }


    //per aggiungere immagini
    function handleInputImages(e){
        //console.log(e.target.files)
        setFoto(e.target.files)
        //console.log(foto)
    }


    function apriImmagine(e){
        //console.log(e.target.src)
        setUrlCliccata(e.target.src)
        setOpenImage(true)
    }
    
    function callZIProute(){
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        myHeaders.append("x-richiestaid", idRichiesta);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/immagini/richiesta", requestOptions)
        .then(response => {
            return response.blob()
        })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filename.zip'); 
            document.body.appendChild(link);
            link.click(); 
        })
        .catch(error => console.log('error', error));
    }
    return(
        <div>
           {/*  IMMAGINI ALLEGATE ALLA RICHIESTA {idRichiesta} */}
            {modifica&&
            <div className="divInserimentoImmagini">
                <label className="labelAggiungi">Aggiungi Immagini: </label>
                <input type="file" id="imagesInput" accept="image/*" name="images" aria-label="File browser" multiple onChange={(e)=>handleInputImages(e)}></input>

                <div>
                    <label className="labelSelezionaTipo">Seleziona a quale momento dell'intervento si riferiscono le foto che vuoi inserire:</label>
                    <div className={`contenitoreBottoniTipoImmagine`}>
                    {listaTipiImmagini.map((tipo)=>{
                        //console.log(tipo)
                        if(editor&&tipo.id==1){
                            return
                        }else{ 
                            return <div className={`bottoniTipoImmagine ${tipoImmagine==tipo.id?"selectedType":""}`} onClick={(e)=>setTipoImmagine(e.target.id)} key={tipo.id} id={tipo.id}>{tipo.name}</div>
                        }
                    })}
                    </div>
                </div>
                
            </div>
            }
            <p className={`avviso ${avviso.className}`}>{avviso.text}</p>
            
            {listaImmagini.length!=0 &&
            <div>
                <div className="containerImmaginiDiv">
                    <div>
                        <span> Richiesta </span>
                        <div className="containerImmaginiRichiesta" > 
                            {listaImmagini.map((immagine)=>{
                                let nuovoPath= baseUrl + '/' + immagine.path
                                if(immagine.tipiimmagineId==1){
                                    return <div className="singolaImmagine" key={immagine.id}> {(modifica &&!editor)&&<span className="spanElimina" onClick={()=>{setImgToDelete(immagine.id); setModal(true)}}>Elimina  </span>}
                                                <img onClick={(e)=>apriImmagine(e)} id={immagine.id} src={nuovoPath} ></img> 
                                            </div>
                                }
                            })}
                        </div>
                    </div>
                    <div>
                        <span> Prima dell'Intervento</span>
                        <div className="containerImmaginiPrima"> 
                        {listaImmagini.map((immagine)=>{
                                let nuovoPath= baseUrl + '/' + immagine.path
                                if(immagine.tipiimmagineId==2){
                                    return <div className="singolaImmagine"  key={immagine.id}> 
                                                {modifica &&<span className="spanElimina" onClick={()=>{setImgToDelete(immagine.id); setModal(true)}}>Elimina  </span>}
                                                <img onClick={(e)=>apriImmagine(e)} id={immagine.id} src={nuovoPath} ></img> 
                                            </div>
                                }
                            })
                            }
                        </div>
                    </div>
                    <div>
                        <span> Dopo l'Intervento</span>
                        <div className="containerImmaginiDopo" > 
                            {listaImmagini.map((immagine)=>{
                                    let nuovoPath= baseUrl + '/' + immagine.path
                                    if(immagine.tipiimmagineId==3){
                                        return <div className="singolaImmagine"  key={immagine.id}> {modifica &&<span className="spanElimina" onClick={()=>{setImgToDelete(immagine.id); setModal(true)}}>Elimina  </span>}
                                        <img onClick={(e)=>apriImmagine(e)} id={immagine.id} src={nuovoPath}></img> </div>
                                    }
                                })
                                }
                        </div>
                    </div>
                </div>
                <div className={`immagineApertaDiv ${openImage?"":"chiusa"}`} onClick={()=>setOpenImage(false)} >
                    <img src={`${urlCliccata}`}></img>
                </div>

                {adminOrMore&&<div className="bottoneZip" onClick={()=>callZIProute()}>
                    Scarica ZIP foto
                </div>}
                {modal&&
                    <div className="containerModal" onClick={(e)=>{if(e.target == e.currentTarget){setModal(false)}}}>
                        <div className="eliminaModal" >
                            <p> Sei sicuro di voler eliminare quest'immagine? <br/> L'operazione non sarà reversibile!</p>
                            <div className="eliminaButtonModal" onClick={()=>handleEliminaImg()}>SI, Elimina</div>
                            <div className="eliminaButtonModal" onClick={()=>setModal(false)}>NO, Annulla</div>
                        </div>
                    </div>
                }
            </div>
            }
        </div>
    )
}
export default ImmaginiComponent;