import React, { useState, useEffect } from "react";


function ChartTipiIntervento(props){
    const listaRichieste = props.listaRichieste;
    const listaTipiIntervento = props.listaTipiIntervento;
    const [counterTipo, setCounterTipo] = useState()
    const [clickedType, setClickedType] = useState(1)

    useEffect(()=>{
        let value=[];
        listaRichieste.forEach(item=>{
            if(item.tipiinterventoId == 1 ){
                value.push(item)
            }
            return
        })
        setCounterTipo(value.length); 
    },[listaRichieste ])

    function handleCounter(e){
        //console.log(e.target.id)
        let value=[];
        listaRichieste.forEach(item=>{
            if(item.tipiinterventoId == e.target.id ){
                value.push(item)
            }
            return
        })
        setCounterTipo(value.length); 
        setClickedType(e.target.id)

    }

    return(
        <div className="containerTipiIntervento">
            <div className="">
                {listaTipiIntervento.map((tipo)=>{
                    return <p className={`${clickedType==tipo.id?'tipoCliccato':''}`} id={tipo.id} key={tipo.id} onClick={(e)=>handleCounter(e)}>{tipo.name}</p> 
                })}
            </div>
            <div className="counterTipoTotale"><div>{counterTipo}/{listaRichieste.length}</div></div>
        </div>
    )
}

export default ChartTipiIntervento