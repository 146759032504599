/* questo component conterrà solo la label e la select della lista danno , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useEffect} from 'react';
import L from 'leaflet';

import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, useMapEvents, TileLayer, WMSTileLayer, Marker, Popup } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import icon from  '../../imgs/avr.svg';
import greenIcon from '../../imgs/greenM.svg';
import blueIcon from '../../imgs/blueM.svg';

import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize : [50,50],
    
});

L.Marker.prototype.options.icon = DefaultIcon;

const LeafIcon = L.Icon.extend({
    options: {}
});


function Mappa(props){
    const [map, setMap] = useState(null);
    const [openMap, setOpenMap] = useState(true)
    const [avviso, setAvviso]= useState('')

    const [layers, setLayers] = useState(true)
    const [layerAppaltatori, setLayerAppaltatori] = useState(false)
    const [layerOperatorePrivato, setLayerOperatorePrivato] = useState(false)
    const [layerCantieri, setLayerCantieri] = useState(false)
    const [layerSponsor, setLayerSponsor] = useState(false)
    const [layerAlberi, setLayerAlberi] = useState(false)
    const [layerInterferenze, setLayerInterferenze] = useState(false)
    const [hover, setHover] = useState(false)


    const {setGeom, setIdLocalita, setNomeLocalita, setMunicipio, setIndirizzo, setCivico, setIdAlbero} = props
    const [markerIniziale, setMarkerIniziale] = useState({lat:45.44956343385593, lng:9.254375202676734});
    const [markers, setMarkers] = useState();

    const richiestaDaModificare = props.richiesta;
    //console.log("props da modificare", props);
    const [coordinateRichiesta, setCoordinateRichiesta]= useState([])


    useEffect(()=>{
        if(richiestaDaModificare&&richiestaDaModificare.geom){
            var lat = +richiestaDaModificare.geom.split("(")[1].replace(/[)]/g, '').split(",")[0]
            var lng = +richiestaDaModificare.geom.split("(")[1].replace(/[)]/g, '').split(",")[1]
            var marker = { lat: lat, lng: lng}
            setMarkerIniziale(marker);
            //console.log("markerIniziale", marker)
            setCoordinateRichiesta([lat, lng])

        }
    },[richiestaDaModificare])

    //centro la mappa su avr
    const initialCoordinates = [45.44956343385593, 9.254375202676734] //milano

    const greenIconM = new LeafIcon({
        iconUrl: greenIcon,
        iconAnchor: [20, 45], 
        iconSize: [40,40],
        popupAnchor: [0, -50],
        })

    const blueIconM = new LeafIcon({
        iconUrl: blueIcon,
        iconAnchor: [20, 40], 
        iconSize: [40,40],
        popupAnchor: [0, -50],
        })

    //stati che uso per mostrare il nome completo della posizione nel Pop up del Marker E nel result della ricerca
    const [displayNameSearch , setDisplayNameSearch] = useState();
    const [displayNameClick , setDisplayNameClick] = useState();

/*     useEffect(()=>{
        if(!layers){
            setLayerAppaltatori(false);
            setLayerSponsor(false);
            setLayerOperatorePrivato(false);
            setLayerCantieri(false)
            setLayerAlberi(false)
            setLayerInterferenze(false)
        }
    },[layers]) */
    
    function GetFeatureInfoWMS (e, map){
        var 
            map = map,
            loc = e.latlng,
            xy = e.containerPoint, // xy = map.latLngToContainerPoint(loc,map.getZoom())
            size = map.getSize(),
            bounds = map.getBounds(),
            url = 'https://geospaces.avrgroup.it/geoserver/AVRTech/wms',
            crs = map.options.crs, // me._crs
            //srs = crs.code,
            sw = crs.project(map.getBounds().getSouthWest()),
            ne = crs.project(map.getBounds().getNorthEast()),
    
        obj = {
            service: "WMS", // WMS (default)
            version: '1.1',
            request: "GetFeatureInfo",
            layers: 'AVRTech:P103108_alberi, AVRTech:S325502_appaltatore, AVRTech:S325509_operatore_privato,AVRTech:S326550_cantieri,AVRTech:S326_551_563_sponsor_collaborazioni, AVRTech:S441561_interferenza_32632',
            query_layers: ' AVRTech:P103108_alberi, AVRTech:S325502_appaltatore, AVRTech:S325509_operatore_privato,AVRTech:S326550_cantieri,AVRTech:S326_551_563_sponsor_collaborazioni, AVRTech:S441561_interferenza_32632',
            bbox: sw.x + "," + sw.y + "," + ne.x + "," + ne.y, 
            width: size.x,
            height: size.y,
            x: Math.round(xy.x),
            y: Math.round(xy.y),
            info_format: "application/json" // text/plain (default), application/json for JSON (CORS enabled servers), text/javascript for JSONP (JSONP enabled servers) // 1 (default)
            // exceptions: 'application/vnd.ogc.se_xml', // application/vnd.ogc.se_xml (default)
            // format_options: 'callback: parseResponse' // callback: parseResponse (default), use only with JSONP enabled servers, when you want to change the callback name
        };
        fetch(url + L.Util.getParamString(obj, url, true), {
            mode: 'cors'
        })
        .then((response)=> {
            return response.json()
        })
        .then((res)=>{
            if(res.features.length>0){
                console.log("RISPOSTA LAYERS", res.features[0].properties)

                setIdLocalita(res.features[0].properties["z.area"])
                setNomeLocalita(res.features[0].properties["nome_loc"])
                setMunicipio("Municipio "+res.features[0].properties["zona"])

                //setNomeLocalitaLayer(res.features[0].properties["nome_loc"])
                //setIdAlbero(res.features[0].properties["albero"])
                //setInterferenza('true')
            }else{ 
                setIdLocalita('')
                setNomeLocalita('')
                setIdAlbero('')
                setMunicipio('')
                //setInterferenza('false')
            }
        })
        .catch((err)=> {console.log(err)})
    }
    function AddMarkerToClick() {
        const map = useMapEvents({
            click(e) {
                //setNomeLocalitaLayer();
                //setNomeLocalitaNominatim();

                //inserisco il marker
                const newMarker = e.latlng
                setMarkers(newMarker); //questo mi fa partire lo UseEffect di Nominatim
                setGeom(newMarker) 
                
                //interrogo i layers
                GetFeatureInfoWMS(e, map)
            },
        })

        return (
            <>
                {markers &&<Marker position={markers} icon={blueIconM}>
                    {displayNameSearch&& <Popup>La richiesta verrà spostata qui: <br/> {displayNameSearch}</Popup>}
                    {displayNameClick&& <Popup>La richiesta verrà spostata qui: <br/> {displayNameClick}</Popup>}
                </Marker>}
            </>
        )
    }
    //REVERSE GEOCODING se clicco su mappa CAMBIO LO STATO MARKERS E FACCIO PARTI L'EFFECT PER: ottenere longlat ed  indirizzo come result fetch
    useEffect(()=>{
        if(markers){
        fetch(`https://nominatim.openstreetmap.org/reverse?lat=${markers.lat}&lon=${markers.lng}&format=json`)
        .then((response)=> {
            //console.log(response)
            return response.json()
        })
        .then((res)=>{
            console.log("nominatim", res)
            //setMunicipio(res.address.suburb);
            setIndirizzo(res.address.road);
            setDisplayNameClick(res.display_name)

            if(res.address.house_number){
                setCivico(res.address.house_number)
            }else{ setCivico('')}
            /* else{ setCivico('')}
            setNomeLocalitaNominatim(res.address); //è qui che do il via al prox useEffect */
        })
        .catch((err)=> {console.log(err)})
    }
    },[markers])

/*     useEffect(()=>{ //lui dipende solo da nominatim, perchè nominatim risponde sempre.
        if(nomeLocalitaLayer){
            setNomeLocalita(nomeLocalitaLayer)
            console.log("localita presa dai layers:", nomeLocalitaLayer)
        }
        else if (nomeLocalitaNominatim){ 
            console.log("localita presa da Nominatim:",nomeLocalitaNominatim)

            if(nomeLocalitaNominatim.hamlet){
                setNomeLocalita(nomeLocalitaNominatim.hamlet)
            }else if(nomeLocalitaNominatim.village){
                setNomeLocalita(nomeLocalitaNominatim.village)
            }else if(nomeLocalitaNominatim.quarter){
                setNomeLocalita(nomeLocalitaNominatim.quarter +' , ' + nomeLocalitaNominatim.city);
            }else if(nomeLocalitaNominatim.neighbourhood){
                setNomeLocalita(nomeLocalitaNominatim.neighbourhood +' , ' + nomeLocalitaNominatim.city);
            }
        }
        else{
            setNomeLocalita('')
        }
    },[nomeLocalitaNominatim]) */


    //GEOCODING se cerco indirizzo escono latLong
    const [addressSearch, setAddressSearch] =useState('')

    /* SEZIONE RELATIVA ALLA RICERCA PER INDIRIZZO */

    //per quando premo enter a fine inserimento 
    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            callAPIaddress();
        }
    }; 

    //clicco vai  =>ricevo ottengo latlong
    function callAPIaddress(){
        fetch(`https://nominatim.openstreetmap.org/search?q=${addressSearch}&format=json`)
        .then((response)=> {
            //console.log(response)
            return response.json()
        })
        .then((res)=>{
            console.log("Nominatim",res)
            if(res.length==0){
                setAvviso('Nessun risultato')
            }else{setAvviso('')}
            let objCoord={lat:+res[0].lat, lng:+res[0].lon }
            setDisplayNameSearch(res[0].display_name)
            setMarkers(objCoord);
            map.flyTo([objCoord.lat, objCoord.lng],19)
        })
        .catch((err)=> {console.log(err)})
    }


    return(

        <div> 
        <div className="mappaComponent"> 
            <p className="headMappa" onClick={()=>setOpenMap(!openMap)}> MAPPA </p>
            <div className={`mappaContainer ${openMap?"open":"closed" }` } >
                
            {coordinateRichiesta.length>0&&
                <MapContainer whenCreated={setMap} center={markerIniziale} zoom={16} scrollWheelZoom={true}/*  zoomControl={false} */ maxZoom={19} doubleClickZoom={false}>
                    <TileLayer maxZoom={19}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {layerAlberi&&          <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:P103108_alberi'}                      url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerAppaltatori&&     <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S325502_appaltatore'}                 url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerOperatorePrivato&&<WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S325509_operatore_privato'}           url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerCantieri&&        <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S326550_cantieri'}                    url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerSponsor&&         <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S326_551_563_sponsor_collaborazioni'} url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerInterferenze&&    <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S441561_interferenza_32632'}          url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    
                    <Marker position={initialCoordinates}>
                        <Popup className="popUpAvr">
                        AVR S.p.A
                        </Popup>
                    </Marker>
                    <Marker position={markerIniziale} icon={greenIconM}>
                        <Popup>La richiesta {richiestaDaModificare.id} era inserita qui.</Popup>
                    </Marker>
                    <AddMarkerToClick />
                </MapContainer>
            }
            </div>
            <div className={openMap?"containerSearchMapOpen":"containerSearchMapClosed"}>
                <label>Cerca Indirizzo su mappa:</label>
                <input onChange={(e)=>setAddressSearch(e.target.value)} value={addressSearch}   onKeyPress={handleKeypress}></input>
                <div className="bottoneVai" onClick={()=>callAPIaddress()}>VAI</div>
                <div className="avvisoMappa">{avviso}</div>

            </div>

            {openMap&&<div className="containerBottoniLayers"> 
                <div className="iconaLayers" onClick={()=>setLayers(!layers)}>
                    <FontAwesomeIcon style={hover?{color:'#DB2219'}:''} icon={faLayerGroup} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}></FontAwesomeIcon>
                    </div>
                {layers&&<div className={`bottoniLayersDiv`}>
                    <div className={`bottoneLayer ${layerAppaltatori?'pressed1':''}`} onClick={()=>setLayerAppaltatori(!layerAppaltatori)} >Appaltatore</div>
                    <div className={`bottoneLayer ${layerOperatorePrivato?'pressed2':''}`}  onClick={()=>setLayerOperatorePrivato(!layerOperatorePrivato)}>Operatore privato</div>
                    <div className={`bottoneLayer ${layerSponsor?'pressed3':''}`}  onClick={()=>setLayerSponsor(!layerSponsor)}>Sponsor</div>
                    <div className={`bottoneLayer ${layerCantieri?'pressed4':''}`}  onClick={()=>setLayerCantieri(!layerCantieri)}>Cantiere</div>
                    <div className={`bottoneLayer ${layerAlberi?'pressed5':''}`}  onClick={()=>setLayerAlberi(!layerAlberi)}>Alberi</div>
                    <div className={`bottoneLayer ${layerInterferenze?'pressed6':''}`}  onClick={()=>setLayerInterferenze(!layerInterferenze)}>Interferenze</div>
                    

                </div>}
            </div>}
            <FontAwesomeIcon icon={faChevronUp} className={openMap?"angleUp":"angleDown"} onClick={()=>setOpenMap(!openMap)}></FontAwesomeIcon> 
        </div>
        </div>
    )
}
export default Mappa;

// REVERSE GEOCODING https://nominatim.openstreetmap.org/reverse?lat=45.452982&lon=9.246903&format=json
// GEOCODING https://nominatim.openstreetmap.org/search?q=empoli%20via%20roma&format=json
// Nominatim https://nominatim.org/release-docs/latest/api/Reverse/
