
import {useState, useEffect, useContext} from 'react';
import ImmaginiComponent from "./ImmaginiComponent.js"

import MappaModificaComp from "./InserimentoRichiestaComponents/MappaModifica.js"
import TipoRichiedenteComp from "./InserimentoRichiestaComponents/TipoRichiedente.js"
import TipoInterventoComp from "./InserimentoRichiestaComponents/TipoIntervento.js"
import OggettoRichiestaComp from "./InserimentoRichiestaComponents/OggettoRichiesta.js"
import StatoRichiestaComp from "./InserimentoRichiestaComponents/StatoRichiesta.js"
import DannoComp from "./InserimentoRichiestaComponents/Danno.js"
import PriorityComp from "./InserimentoRichiestaComponents/Priority.js"
import MezziComponent from "./InserimentoRichiestaComponents/MezziComponent.js"
import CausaAnnullamentoComp from "./InserimentoRichiestaComponents/CausaAnnullamento.js"
import EliminaRichiesta from  "./EliminaRichiesta.js"

import baseUrl from '../utilities/globalVariables.js'

import "../style/InserimentoRichiesta.css"
import { UserContext } from "../Context/UserContext.js";



function ModificaRichiesta(props){
    const apiUrl= baseUrl + "/api/v1/richieste"
    const immaginiUrl= baseUrl + "/api/v1/immagini"

    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId, ruolo, setRuolo,  accessToken, setAccessToken, setCapoSquadra} = userObject

    var superadmin= ruolo.id==5?true:false;

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
        setUsername(JSON.parse(window.localStorage.username));
        setAccessToken(JSON.parse(window.localStorage.accessToken));
        setUserId(window.localStorage.userId); //sono già stringhe
        setCapoSquadra(window.localStorage.caposquadra)
    }

    const richiestaDaModificare = props.richiestaDaModificare;
    const setMostraParteModifica = props.setMostraParteModifica;
    const setAvviso= props.setAvviso;


    const [immaginiComponent, setImmaginiComponent] = useState()
    const [tipoImmagine, setTipoImmagine]= useState('2');


    //console.log("richiestaDaModificare",richiestaDaModificare)
    //console.log('GIO', localita)

    //stati testuali del form
    const [geom, setGeom] = useState('');
    const [idLocalita, setIdLocalita] = useState('');
    const [nomeLocalita, setNomeLocalita] = useState('')
    const [municipio, setMunicipio] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [civico, setCivico] = useState(''); 
    //stati che passerò ai components (campi select che faranno una get)e che saranno settati durante la compilazione del form
    const [tipoRichiedente, setTipoRichiedente] = useState('');
    const [tipoIntervento, setTipoIntervento] = useState('');
    const [oggettoRichiesta, setOggettoRichiesta] = useState('');
    const [statoRichiesta, setStatoRichiesta] = useState('');
    const [danno, setDanno] = useState('');
    const [priority, setPriority] = useState('');
    const [idAlbero, setIdAlbero]= useState('');
    const [interferenza, setInterferenza]= useState('');

    const [causaAnnullamento, setCausaAnnullamento] = useState('');
    const [idDuplicata, setIdDuplicata]= useState('');
    const [noteOggetto, setNoteOggetto]= useState('');
    const [noteIntervento, setNoteIntervento]= useState('');
    const [dataRisoluzione, setDataRisoluzione]= useState('');
    const [noteInterferenza, setNoteInterferenza]= useState('');

    useEffect(() => {
        setGeom(richiestaDaModificare.geom)
        setIdLocalita(richiestaDaModificare['idLocalità'])
        setNomeLocalita(richiestaDaModificare['nomeLocalità'])
        setMunicipio(richiestaDaModificare.municipio)
        setIndirizzo(richiestaDaModificare.indirizzo)
        setCivico(richiestaDaModificare.civico)
        setTipoRichiedente(richiestaDaModificare.tipirichiedenteId);
        setTipoIntervento(richiestaDaModificare.tipiinterventoId);
        setOggettoRichiesta(richiestaDaModificare.oggettirichiestaId);
        setIdAlbero(richiestaDaModificare.idAlbero)
        richiestaDaModificare.interferenza == true?setInterferenza('true'):setInterferenza('false')
        setNoteInterferenza(richiestaDaModificare.noteInterferenza );
        setStatoRichiesta(richiestaDaModificare.statirichiestaId);
        setDanno(richiestaDaModificare.danniId);
        setPriority(richiestaDaModificare.prioritiesId);
        setCausaAnnullamento(richiestaDaModificare.causeannullamentoId);
        setIdDuplicata(richiestaDaModificare.idRichiestaDuplicata);
        setNoteOggetto(richiestaDaModificare.noteOggetto );
        setNoteIntervento(richiestaDaModificare.noteIntervento);
        setDataRisoluzione(richiestaDaModificare.dataRisoluzione ? richiestaDaModificare.dataRisoluzione.split("T")[0] : null);
    }, [richiestaDaModificare])

    const [foto, setFoto] = useState(null);

    //questa variabile mi serve anche BE (la mando tra gli headers) per eliminare dalla richiesta le eventuali mezzi inserite in precedenza e aggiunte dopo
    var mezziPrecedenti = [];
    const objMezziPrima = richiestaDaModificare.mezzis;
    objMezziPrima.forEach(item => mezziPrecedenti.push(item.id)); //ARRAY DI NUMERI
    const [objMezziDopo, setObjMezziDopo] = useState(mezziPrecedenti);

    //stati per render condiziato alle scelte
    const [idDuplicataInput, setIdDuplicataInput]= useState(false);
    const [causaAnnullamentoInput, setCausaAnnullamentoInput]= useState(false);
    const [noteOggettoInput, setNoteOggettoInput]= useState(false);
    /* const [noteInterventoInput, setNoteInterventoInput]= useState(false); */
    const [dataRisoluzioneInput, setDataRisoluzioneInput]= useState(false);
    const [noteInterferenzaInput, setNoteInterferenzaInput]= useState(false);

    useEffect(()=>{
        setAvviso({text: '', className:''})
    },[])

    //questi 4 useEffect mi servono per aprire dei campi condizionati alle scelte dell'utente (es Se l'oggetto è altro allora si apre il div note oggetto!)
    useEffect(()=>{
        if(statoRichiesta==7){ //annullata
            setCausaAnnullamentoInput(true)
            if(causaAnnullamento==3){ //annullata + duplicata
                setIdDuplicataInput(true)
            }else{ //annullata non duplicata 
                setIdDuplicataInput(false)
                setIdDuplicata(null)
            }
        }else{
            setCausaAnnullamentoInput(false)
            setCausaAnnullamento(null)
            setIdDuplicataInput(false)
            setIdDuplicata(null)
        }
    },[statoRichiesta])

    useEffect(()=>{ //SE CAUSE = DUPLICATA APRE INSERISCI ID DUPLICATA
        if(causaAnnullamento==3){
            setIdDuplicataInput(true)
        }else{
            setIdDuplicataInput(false)
            setIdDuplicata(null)
        }
    },[causaAnnullamento])

    useEffect(()=>{ //SE RICHIESTA RISOLTA APRE DATA RISOLUZIONE
        if(statoRichiesta==5){
            setDataRisoluzioneInput(true)
        }else{
            setDataRisoluzioneInput(false)
            setDataRisoluzione(null)
        }
    },[statoRichiesta])

    useEffect(()=>{ //SE OGGETTO è ALTRO APRE NOTE OGG
        if(oggettoRichiesta==3){
            setNoteOggettoInput(true)
        }else{
            setNoteOggettoInput(false)
            setNoteOggetto('')
        }
    },[oggettoRichiesta])

/*     useEffect(()=>{
        if(tipoIntervento==5){
            setNoteInterventoInput(true)
        }else{
            setNoteInterventoInput(false)
            setNoteIntervento('')
        }
    },[tipoIntervento]) */

    useEffect(()=>{
        if(interferenza=='true'){
            setNoteInterferenzaInput(true)
        }else if (interferenza=='false'){
            setNoteInterferenzaInput(false)
            setNoteInterferenza('')
        }
    },[interferenza])


    function controlliPreCreazione(){
        /* Per i campi obbligatori: se passa tutti i controlli chiami la f(x) */
        if(geom==null || geom==''){
            setAvviso({text:'è obbligatorio scegliere un campo GEOM', className:'red'})
            return
        }
        if (foto){
            var arrayFoto = Array.from(foto)
            if (arrayFoto.some(pic => pic.type != "image/png" && pic.type != "image/jpg" && pic.type != "image/jpeg")){
                setAvviso({text:"Il file selezionato non è un'immagine!!", className:'red'})
                return
            }
        }
        modificaRichiesta()
    }

    function modificaRichiesta(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-idrichiesta", richiestaDaModificare.id);
        myHeaders.append("x-mezzipresenti", mezziPrecedenti);
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();

        urlencoded.append("geom", geom); //obbligo inserimento valutato prima in controlliPreCreazione
        urlencoded.append("idLocalità", idLocalita); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("nomeLocalità", nomeLocalita); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("indirizzo", indirizzo); //NON obbligato nè FE ne BE =>può restare vuoto?
        urlencoded.append("mezziId", objMezziDopo); //obbligo inserimento valutato prima in controlliPreCreazione
        urlencoded.append("interferenza", interferenza);
        urlencoded.append("noteInterferenza", noteInterferenza);
        //i due successivi integer se sono null non vengono inviati(saranno undefined in BE)
        //ma possono essere =='' quindi devo gestirli BE
        if(idDuplicata){ urlencoded.append("idRichiestaDuplicata", idDuplicata) }
        if(idAlbero){urlencoded.append("idAlbero", idAlbero)}



        if(noteOggetto!= null){urlencoded.append("noteOggetto", noteOggetto); }
        if(noteIntervento!= null){urlencoded.append("noteIntervento", noteIntervento); }

        if(municipio){
            urlencoded.append("municipio", municipio); //non è obbligato, ma se UNDEFINED???
        }
        if(civico){
            urlencoded.append("civico", civico); //non è obbligato, ma se null mando una stringa e non la vuole :D
        }
        if(tipoRichiedente){
            urlencoded.append("tipirichiedenteId", tipoRichiedente); //obbligato solo BE => se non inserito ha il default value
        }
        if(oggettoRichiesta){
            urlencoded.append("oggettirichiestaId", oggettoRichiesta); //obbligato solo BE => se non inserito ha il default value
        }
        if(priority){
            urlencoded.append("prioritiesId", priority); //obbligato solo BE => se non inserito ha il default value
        }
        if(danno){
            urlencoded.append("danniId", danno); //obbligato solo BE => se non inserito ha il default value
        }
        if(tipoIntervento){
            urlencoded.append("tipiinterventoId", tipoIntervento); //obbligato solo BE => se non inserito ha il default value
        }

        if(statoRichiesta){ 
            urlencoded.append("statirichiestaId", statoRichiesta); //obbligato solo BE => se non inserito ha il default value
        }
        if(causaAnnullamento){
            urlencoded.append("causeannullamentoId", causaAnnullamento); //obbligato solo BE => se non inserito ha il default value
        }

        if(dataRisoluzione){
            urlencoded.append("dataRisoluzione", dataRisoluzione); 
        }

        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result)
            if(result.message){
                //console.log(result);
                setAvviso({text:result.message, className:'green'})
                    if (foto) {
                        inserimentoImmagine()
                    }
                    setMostraParteModifica(false)
            }else if (result.error){
                setAvviso({text:result.error, className:'red'})
            }
        })
        
        .catch(error => console.log('error', error));
    }

    function inserimentoImmagine(){
        var myHeaders = new Headers();        
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        const formData = new FormData();
        formData.append("richiestaId",  richiestaDaModificare.id);
        formData.append("tipoImmagine", tipoImmagine); //l'operatore invece potrà inserire solo il PRIMA e il DOPO 

        for (let i = 0; i < foto.length; i++) {
            formData.append("images", foto[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };
        
        fetch(immaginiUrl, requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    return(
        <>
            <MappaModificaComp richiesta={richiestaDaModificare} setGeom={setGeom} setIdLocalita={setIdLocalita} setNomeLocalita={setNomeLocalita} setMunicipio={setMunicipio} setIndirizzo={setIndirizzo} setCivico={setCivico} setIdAlbero={setIdAlbero}>  </MappaModificaComp>
            <div className="formInserimentoRichiesta">
                <p className="titoloformInserimentoRichiesta">MODIFICA RICHIESTA {richiestaDaModificare.id}</p>
                <div className="formInserimentoRichiestaLeft">
                    <p className="titoloDatiContainer">Dati Geografici</p>
                    <div className="datiGeograficiContainer">

                        <div className="campoInserimentoRichiesta">
                            <label>Latitudine, Longitudine</label>
                            <input  type="text" value={geom} onChange={(e) => setGeom(e.target.value)} disabled="disabled"></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Id Località</label>
                            <input  type="text" value={idLocalita} onChange={(e) => setIdLocalita(e.target.value)}></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Nome Località</label>
                            <input  type="text" value={nomeLocalita} onChange={(e) => setNomeLocalita(e.target.value)}></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Municipio</label>
                            <input  type="text" value={municipio} onChange={(e) => setMunicipio(e.target.value)}></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Indirizzo</label>
                            <input  type="text" value={indirizzo} onChange={(e) => setIndirizzo(e.target.value)}></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Civico</label>
                            <input  type="varchar(6)" value={civico} onChange={(e) => setCivico(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className="formInserimentoRichiestaRight">
                    <p className="titoloDatiContainer">Dati Generali</p>
                    <div className="datiGeneraliContainer">

                        <TipoRichiedenteComp tipoRichiedente={tipoRichiedente} setTipoRichiedente={setTipoRichiedente}></TipoRichiedenteComp>
                        <OggettoRichiestaComp oggettoRichiesta={oggettoRichiesta} setOggettoRichiesta={setOggettoRichiesta}></OggettoRichiestaComp>

                        {noteOggettoInput&&<div className="campoInserimentoRichiesta">
                            <label style={{fontWeight:'500'}}>Note Oggetto</label>
                            <textarea maxLength="150" value={noteOggetto} onChange={(e) => setNoteOggetto(e.target.value)}></textarea>
                            <span> Inserire dettagli sull'oggetto della richiesta. </span>
                        </div>}
                        <div className="campoInserimentoRichiesta">
                            <label>Id Albero</label>
                            <input  type="number" value={idAlbero} onChange={(e) => setIdAlbero(e.target.value)}></input>
                        </div>
                        <div className="campoInserimentoRichiesta">
                            <label>Interferenza</label>
                            <select onChange={(e) => setInterferenza(e.target.value)} value={interferenza}  /* disabled={editor?'disabled':''} */>
                                <option value="false">No</option>
                                <option value="true">Si</option>
                            </select>
                        </div>
                        {noteInterferenzaInput&&<div className="campoInserimentoRichiesta">
                            <label style={{fontWeight:'500'}}>Note Interferenza</label>
                            <textarea maxLength="150" value={noteInterferenza} onChange={(e) => setNoteInterferenza(e.target.value)}></textarea>
                        </div>}

                        <PriorityComp priority={priority} setPriority={setPriority}></PriorityComp>
                        <DannoComp danno={danno} setDanno={setDanno}></DannoComp>
                        <TipoInterventoComp tipoIntervento={tipoIntervento} setTipoIntervento={setTipoIntervento}></TipoInterventoComp>

                      {/*   {noteInterventoInput&&<div className="campoInserimentoRichiesta">
                            <label style={{fontWeight:'500'}}>Note Intervento</label>
                            <textarea maxLength="150" value={noteIntervento} onChange={(e) => setNoteIntervento(e.target.value)}></textarea>
                        </div>} */}
                        <div className="campoInserimentoRichiesta">
                            <label style={{fontWeight:'500'}}>Note Intervento</label>
                            <textarea maxLength="150" value={noteIntervento} onChange={(e) => setNoteIntervento(e.target.value)}></textarea>
                        </div>

                        <div className="campoSingolaRichiesta">
                            <span>Mezzo/i Assegnato/i</span>
                            <div >
                            {objMezziPrima.map((squad)=>{
                                        return <div key={squad.id} value={squad.id}>{squad.targaMezzo} - {squad.user.username} </div>
                                    })}
                            </div>
                        </div>
                        <MezziComponent idMezzoScelto={objMezziDopo} setIdMezzoScelto={setObjMezziDopo}></MezziComponent>
                        <StatoRichiestaComp  statoRichiesta={statoRichiesta} setStatoRichiesta={setStatoRichiesta}></StatoRichiestaComp>

                        {causaAnnullamentoInput&&<CausaAnnullamentoComp causaAnnullamento={causaAnnullamento} setCausaAnnullamento={setCausaAnnullamento}></CausaAnnullamentoComp>}
                        {idDuplicataInput&&<div className="campoInserimentoRichiesta">
                            <label>Id Duplicata</label>
                            <input  type="number" value={idDuplicata} onChange={(e) => setIdDuplicata(e.target.value)}></input>
                        </div>}
                        {dataRisoluzioneInput &&<div className="campoInserimentoRichiesta">
                            <label>Data Risoluzione</label>
                            <input  type="date"  value={dataRisoluzione} onChange={(e) => setDataRisoluzione(e.target.value)} ></input>
                        </div>}
                        <br/>
                        
                    </div>
                    <div className="containerBottoniConferma"> 
                            <div className={`bottoneConferma ${immaginiComponent?"attivo":""}`} onClick={()=>setImmaginiComponent(!immaginiComponent)}>IMMAGINI</div>  
                            <div className="bottoneConferma" onClick={()=>{setMostraParteModifica(false); setAvviso({text:'', className:''})}}>ANNULLA</div>
                            {superadmin&&<EliminaRichiesta idRichiesta={richiestaDaModificare.id}></EliminaRichiesta>}
                        </div>
                </div>
                {immaginiComponent&& <div className="insertImages"><ImmaginiComponent idRichiesta={ richiestaDaModificare.id}  modifica={true} foto={foto} setFoto={setFoto} tipoImmagine={tipoImmagine} setTipoImmagine={setTipoImmagine}></ImmaginiComponent></div>}
            </div>
            <div className="containerBottoneModifica"><div  className="bottoneModifica" onClick={()=>controlliPreCreazione()} >INSERISCI MODIFICA</div></div>
            
    </>
    
)}
export default ModificaRichiesta;


/* AGGIUNGEREI LE X PER PULIRE I CAMPI PIU UNA PER PULIRLI TUTTI INSIEME
LE SINGOLE X PER INPUT SE CLICKED VOGLIO CHE CHIAMINO UNA FUNZIONE CHE PULISCE IL CAMPO IN MODO DINAMICO->NE CREO UNA SOLA NON 15!!!!!!!!(THIS. QUALCOSA???)
*/