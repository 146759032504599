/* questo component conterrà solo la label e la select della lista danno , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useEffect} from 'react';
import L from 'leaflet';

import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import icon from  '../../imgs/avr.svg';
import greenIcon from '../../imgs/greenM.svg';
import redIcon from '../../imgs/redM.svg';
import orangeIcon from '../../imgs/orangeM.svg';
import greyIcon from '../../imgs/greyM.svg';



import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize : [50, 50],
    /* popupAnchor: [15, 10], */
});

L.Marker.prototype.options.icon = DefaultIcon;

const LeafIcon = L.Icon.extend({
    options: {}
});


//SE ARRIVATA DALLE PROPS UN QUALCOSA CHE SI CHIAMA RICHIESTA ALLORA C'è UN SOLO MARKER DA VISUALIZZARE.
//SE MI ARRIVA PROPS.DATA SIGNIFICA CHE SONO NELLA PAGINA DI RICERCA (Table.js) E CHE VISUALIZZERò TUTTE LE RICHIESTE INSERITE FIN QUA
function Mappa(props){
    const [markers, setMarkers] = useState([]);
    const [openMap, setOpenMap] = useState(true);
    var marker = {lat:'',lon:''};
    const [coordinateRichiesta, setCoordinateRichiesta]= useState([])

    const richiestaDaVisualizzare = props.richiesta;
    //console.log("Props dentro al MappaVisualizza", props);

    useEffect(()=>{
        if(richiestaDaVisualizzare&&richiestaDaVisualizzare.geom){
            var lat = +richiestaDaVisualizzare.geom.split("(")[1].replace(/[)]/g, '').split(",")[0]
            var lng = +richiestaDaVisualizzare.geom.split("(")[1].replace(/[)]/g, '').split(",")[1]
            var id= richiestaDaVisualizzare.id;
            var statoRichiesta =richiestaDaVisualizzare.statirichiestaId

            marker = { lat: lat, lng: lng, id:id, statoRichiesta:statoRichiesta}
            setMarkers([marker]);
            setCoordinateRichiesta([lat, lng])
            //console.log()
        }
    },[richiestaDaVisualizzare])

    //centro la mappa su avr
    const initialCoordinates = [45.44956343385593, 9.254375202676734] //milano


    const greenIconM = new LeafIcon({
        iconUrl: greenIcon,
        iconAnchor: [20, 45], 
        iconSize: [40,40],
        popupAnchor: [0, -50],

        })

    const orangeIconM = new LeafIcon({
        iconUrl: orangeIcon,
        iconAnchor: [20, 45], 
        iconSize: [40,40],
        popupAnchor: [0, -50],
        })

    const redIconM = new LeafIcon({
        iconUrl: redIcon,
        iconAnchor: [20, 45], 
        iconSize: [40,40],
        popupAnchor: [0, -50],

        })

    const greyIconM = new LeafIcon({
        iconUrl: greyIcon,
        iconAnchor: [20, 45], 
        iconSize: [40,40],
        popupAnchor: [0, -50],
        })

    return(

        <div> 
        <div className="mappaSingolaComponent"> 
            <p className="headMappa" onClick={()=>setOpenMap(!openMap)}> MAPPA </p>
            <div className={`mappaContainer ${openMap?"open":"closed" }`}>
                {coordinateRichiesta.length>0&&<MapContainer center={coordinateRichiesta} zoom={16} scrollWheelZoom={true}/*  zoomControl={false} */ maxZoom={19} doubleClickZoom={false}>
                    <TileLayer maxZoom={19}
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={initialCoordinates}>
                        <Popup className="popUpAvr">
                        AVR S.p.A
                        </Popup>
                    </Marker>
                    {markers.map((marker,i) => {
                        //console.log("marker", marker)
                        if(marker.statoRichiesta == 6 || marker.statoRichiesta == 5 || marker.statoRichiesta == 4){
                            return <Marker key={i} position={marker} icon={greenIconM}>
                                        <Popup>Richiesta {marker.id}</Popup>
                                    </Marker>
                        }
                        if(marker.statoRichiesta == 2  || marker.statoRichiesta == 3){
                            return <Marker key={i} position={marker} icon={orangeIconM}>
                                        <Popup>Richiesta {marker.id}</Popup>
                                    </Marker>
                        }
                        if(marker.statoRichiesta== 1 ){
                            return <Marker key={i} position={marker} icon={redIconM}>
                                        <Popup>Richiesta {marker.id}</Popup>
                                    </Marker>
                        }
                        if(marker.statoRichiesta == 7 ){
                            return <Marker key={i} position={marker} icon={greyIconM}>
                                        <Popup>Richiesta {marker.id}</Popup>
                                    </Marker>
                        }
                        })}
                </MapContainer>
                }
            </div>
            <FontAwesomeIcon icon={faChevronUp} className={openMap?"angleUp":"angleDown"} onClick={()=>setOpenMap(!openMap)}></FontAwesomeIcon> 

        </div>
        </div>
    )
}
export default Mappa;

// REVERSE GEOCODING https://nominatim.openstreetmap.org/reverse?lat=45.452982&lon=9.246903&format=json
// GEOCODING https://nominatim.openstreetmap.org/search?q=empoli%20via%20roma&format=json
// Nominatim https://nominatim.org/release-docs/latest/api/Reverse/
