

import {useState, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import { UserContext } from "../../Context/UserContext.js";

import baseUrl from '../../utilities/globalVariables.js'


function InserisciUtente(props){
   
    const urlUtenti = baseUrl + "/api/v1/users";
    const urlRuoli = baseUrl + "/api/v1/users/ruoli";
    const urlCapiSquadra = baseUrl + "/api/v1/users/caposquadra";

    const setRenderAutomatico = props.setRenderAutomatico;
    const renderAutomatico =  props.renderAutomatico;
    
    const userObject = useContext(UserContext);
    const {userId, accessToken} = userObject

    //regex per il controllo della lunghezza, caratteri maiuscoli o minuscoli e la presenza di un carattere speciale
    var regPwd = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    //interni al component
    const [avviso, setAvviso]= useState({text:'', className:''});

    const [nomeUtente, setNomeUtente]= useState('');
    const [passwordUtente, setPasswordUtente]= useState('');
    const [mailUtente, setMailUtente]= useState('');
    const [ruoloScelto, setRuoloScelto]= useState(0)
    const [capoSquadraOption, setCapoSquadraOption]= useState('no')
    const [listaRuoli, setListaRuoli]= useState([]);
    let history = useHistory();


    useEffect(()=>{ /**get all ruoli */
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(urlRuoli, requestOptions)
        .then(response => response.json())
        .then(res => {
            //console.log("lista ruoli", res)
            if(res.message){
                setListaRuoli(res.message)
            }
        })
        .catch((err)=>console.log(err))

    },[accessToken])


    function controlliPreCreazione(){
        if (mailUtente =='' && !passwordUtente.match(regPwd)) {
            setAvviso({text:"La Password non soddisfa i requisiti!", className:'red'});
            return;
        }
        /* Per i campi obbligatori: se passa tutti i controlli chiami la f(x) */
        if( nomeUtente!=''&& mailUtente !='' && ruoloScelto != 0  || nomeUtente!='' && ruoloScelto != 0 && passwordUtente!='' ){
            if(capoSquadraOption=='si'){
                creaNuovoCapoSquadra()
                console.log('creo capo')
            }else{
                creaNuovoUtente()
                console.log('creo utente')
            }
            return;
        }
        setAvviso({text:'Mancano alcune informazioni...', className:'red'});
    }

    function creaNuovoCapoSquadra(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", nomeUtente);
        urlencoded.append("mail", mailUtente);
        urlencoded.append("password", passwordUtente);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(urlCapiSquadra, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                console.log(result)
                cleanAfterCreation()
                setAvviso({text:result.message, className:'green'})
                setRenderAutomatico(!renderAutomatico)
            }else{
                setAvviso({text:result.error, className:'red'})
            }
        })
        .catch(error => console.log('error', error));
    }

    function creaNuovoUtente(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", nomeUtente);
        urlencoded.append("mail", mailUtente);
        urlencoded.append("ruolo", ruoloScelto);
        urlencoded.append("password", passwordUtente);


        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(urlUtenti, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result)
            if(result.message){
                setAvviso({text:'Utente corretamente inserito!', className:'green'})
                cleanAfterCreation()
                setRenderAutomatico(!renderAutomatico)
            }else if(result.error){
                setAvviso({text:result.error, className:'red'})
            }

        })
        .catch(error => console.log('error', error));
    }
    useEffect(()=>{
        if(ruoloScelto!=2){
            setCapoSquadraOption('no')
        }
    },[ruoloScelto])

    function cleanAfterCreation(){
            setNomeUtente('')
            setMailUtente('')
            setPasswordUtente('')
            setRuoloScelto(0)
            setCapoSquadraOption('no')
    }

    return(
        <div className="inserimentoUtenteContainer"> 
            <p className="inserimentoUtenteContainerTitle">Qui puoi inserire un nuovo utente:</p>

            <div className="campoInserimentoUtente">
                <label>Nome Utente</label>
                <input value={nomeUtente} type="text" onChange={(e)=>setNomeUtente(e.target.value)}></input>
            </div>
            <div className="campoInserimentoUtente">
                <label>Mail Aziendale (Deve essere inserita se l'utente farà login con MSAL)</label>
                <input  type="text" value={mailUtente} disabled={`${passwordUtente!=''?'disabled':''}`} onChange={(e)=>setMailUtente(e.target.value)}></input>
            </div>

            <div className="campoInserimentoUtente">
                <label>Password** (Deve essere inserita se l'utente NON farà login con MSAL)</label>
                <input  type="text" value={passwordUtente} disabled={`${mailUtente!=''?'disabled':''}`}onChange={(e)=>setPasswordUtente(e.target.value)}></input>
                <span className="smallPassword"> **La password deve contenere almeno 8 caratteri di cui: almeno
                una minuscola, una maiuscola, un numero ed un carattere speciale
                (!@#$%^&*). </span>
            </div>

            <div className="campoInserimentoUtente">
                <label>Ruolo</label>
                <select value={ruoloScelto} onChange={(e)=>setRuoloScelto(e.target.value)}>
                    <option>---</option>
                    {listaRuoli.map((role)=>{
                        if(role.id != 5){
                            return <option key={role.id} value={role.id}>{role.name}</option>
                        }/* else{return} */
                        })}
                </select>
                
            </div>
            {ruoloScelto==2 && <div className="campoInserimentoUtente">
                <label>Capo Squadra?</label>
                <select value={capoSquadraOption} onChange={(e)=>setCapoSquadraOption(e.target.value)}>
                    <option value='no'> No</option>
                    <option value='si'> Si</option>
                </select>
                <p>Dopo aver creato un capo squadra sarà possibile assegnargli un mezzo nella sezione <span className="linkGestioneMezzi" onClick={()=>history.push('/gestioneMezzi')}>Gestione Mezzi.</span></p>
            </div>
            }
            <p className="suggestionUtente" >Affinchè sia possibile assegnare le richieste ad un utente è necessario che questo sia <b>Capo Squadra</b>. 
                    Per creare un Capo Squadra selezionare ruolo Editor e specificare Capo Squadra SI</p>

            <div className="bottoneConferma" onClick={()=>controlliPreCreazione()}>INSERISCI</div>
            <span className={` avviso ${avviso.className}`}>{avviso.text}</span>

        </div>
    )
}

export default InserisciUtente;
