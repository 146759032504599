

import {useState, useEffect, useContext} from 'react';
import { Marker, Popup } from 'react-leaflet'
import { UserContext } from "../../Context/UserContext.js";
import L from 'leaflet';

import greenTruckIcon from '../../imgs/delivery23green.svg';
import greyTruckIcon from '../../imgs/delivery23grey.svg';
import yellowTruckIcon from '../../imgs/delivery23yellow.svg';
import orangeTruckIcon from '../../imgs/delivery23orange.svg';

import baseUrl from '../../utilities/globalVariables.js'

const LeafIcon = L.Icon.extend({
    options: { }
});

function StatoFlottaMarkers() {
    var statoFlottaUrl= baseUrl + "/api/v1/mezzi/statoflotta";
    const [markersMezziDb, setMarkersMezziDb] = useState([]);

    var url='http://127.0.0.1:3000';
    const [listaMezzi, setListaMezzi] = useState([]); //da db
    const [listaMezziSiunet, setListaMezziSiunet]= useState([]) //da siunet
    const [listaMezziSiunetPresenti, setListaMezziSiunetPresenti]= useState([]) // intersezione mezzi da siunet & db
    
    const userObject = useContext(UserContext);  
    const { userId, accessToken} = userObject

    var arrayMarkers=[];
    var arrayMezziPresenti=[];

    useEffect(()=>{ //CHIAMATA BE ONMOUNT PER LISTA MEZZI DB 
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/mezzi ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("listaMezziPresentinelDB", result.message)
            if(result.message != undefined){
                //manipolo per togliere i deleted e ordinare i logged
                let arrayModificato=[];
                result.message.map((item)=>{
                if(item.deleted==false){
                    arrayModificato.push(item)
                    }
                })
                arrayModificato.sort( (a,b)=>{
                    return b.loggata -a.loggata
                })
                setListaMezzi(arrayModificato)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    useEffect(()=>{ //CHIAMATA BE ONMOUNT PER LISTA MEZZI SIUNET
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

        fetch(statoFlottaUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("ListaMezziSiunet",result)
            if(result.message){
                setListaMezziSiunet(result.message)
                //handleListaMezziSiunet()
            }
        })
        .catch(error => console.log('error', error));
    },[listaMezzi])

    
    useEffect(()=>{
        var timer = setInterval(()=>
        
        {   var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("x-user-id", userId);
            myHeaders.append('x-access-token', accessToken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

            fetch(statoFlottaUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log("ListaMezziSiunet",result)
                if(result.message){
                    setListaMezziSiunet(result.message)
                    //handleListaMezziSiunet()
                }
            })
            .catch(error => console.log('error', error));}

        , 10000 )
        return function cleanup() {
            clearInterval(timer)
        }
    })

    const greenTruckIconM = new LeafIcon({
        iconUrl: greenTruckIcon,
        popupAnchor: [-90, 10],
        iconSize: [40, 40],
        popupAnchor: [0, -20],


    })
    
    const yellowTruckIconM = new LeafIcon({
        iconUrl: yellowTruckIcon,
        popupAnchor: [-110, 10],
        iconSize: [40, 40],
        popupAnchor: [0, -20],

    })

    const orangeTruckIconM = new LeafIcon({
        iconUrl: orangeTruckIcon,
        popupAnchor: [-70, 10],
        iconSize: [40, 40],
        popupAnchor: [0, -20],


    })

    const greyTruckIconM = new LeafIcon({
        popupAnchor: [-90, 10],
        iconUrl: greyTruckIcon,
        iconSize: [40, 40],
        popupAnchor: [0, -20],


    })

    //SCORRO LISTAMEZZISIUNET  E PRENDO SOLO I MEZZI PRESENTI NEL DB (APPARTENENTI A LISTAMEZZI)
    useEffect(()=>{
        //console.log(listaMezziSiunet)
        if(listaMezziSiunet.length==0){
            return
        }
        listaMezziSiunet.forEach(item=>{
            listaMezzi.forEach(mezzoDB=>{
                if(mezzoDB.targaMezzo==item.targa){
                    var lat = item.latitudine;
                    var lng = item.longitudine;
                    var targa = item.targa;
                    var loggato = mezzoDB.user.logged;
                    var acceso = item.quadro;
                    var capoSquadra = mezzoDB.user.username
                    const newMarker = { lat: lat, lng: lng, targa:targa, loggato:loggato, acceso: acceso, capoSquadra:capoSquadra}
                    //if(item.targa== 'FK468LV'){console.log( lat, lng)}
                    arrayMarkers.push(newMarker)
                    arrayMezziPresenti.push(item)
                }
            })
        })
        //console.log("arrayMezziDB&Siunet", arrayMezziPresenti)

        const array2= arrayMarkers //appoggio array
        const array3 = arrayMezziPresenti;

        setMarkersMezziDb(array2); 
        setListaMezziSiunetPresenti(array3)
    },[listaMezziSiunet])
            
    return(
        <>
        {markersMezziDb.map((marker,i) => {
                //console.log("LOGGATO" , marker.loggato)
                if(marker.loggato&&marker.acceso){
                    return <Marker key={i} position={marker} icon={greenTruckIconM} >
                            <Popup> <p className="markerPopupVisualizzaMezzo" id={i} > {marker.targa} {/* - {marker.capoSquadra} */} Attivo, in movimento.</p></Popup>
                        </Marker>
                }else if(marker.loggato&&!marker.acceso){
                    return <Marker key={i} position={marker} icon={orangeTruckIconM} >
                            <Popup> <p className="markerPopupVisualizzaMezzo" id={i} > {marker.targa} {/* - {marker.capoSquadra} */} Attivo, fermo.</p></Popup>
                        </Marker>
                }else if(!marker.loggato&&marker.acceso){
                    return <Marker key={i} position={marker} icon={yellowTruckIconM} >
                            <Popup> <p className="markerPopupVisualizzaMezzo" id={i} > {marker.targa} {/* - {marker.capoSquadra}  */}Non Attivo, in movimento.</p></Popup>
                        </Marker>
                }else {
                    return <Marker key={i} position={marker} icon={greyTruckIconM} >
                                <Popup> <p className="markerPopupVisualizzaMezzo" id={i} > {marker.targa} {/* - {marker.capoSquadra} */} Non Attivo, fermo.</p></Popup>
                            </Marker>
                }
            })}
            </>
    )
}

export default StatoFlottaMarkers;