
/**
. parte con le statistiche per singolo meteo --- visibile da [1, 5]
. la giornata meteo critica --- visibile da [1, 5]
. la lista delle richieste archiviate, e possibilità di aprirle visibile da [5]

. [2,3,4] non vedono nulla sugli eventi passati
 */
import  React, { useState , useEffect, useContext  } from 'react'
import { UserContext } from "../../Context/UserContext.js";
import baseUrl from '../../utilities/globalVariables.js'
import {useHistory} from "react-router-dom"; 
import "../../style/StoricoPage.css"
import ChartRichiedenti from "../DashboardComponents/ChartRichiedenti.js" 

import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MappaMunicipiStorico from "./MappaMunicipiStorico.js"
import ChartTemporale from "./ChartTemporale.js"
import MeteoGiornataCritica from "./MeteoGiornataCritica.js"


/* 
    prende onload tutti gli eventi e tutte le richieste DAL PRIMO DELL'ANNO AD OGGI 
    (le richieste non sono associate agli eventi in qualche modo, quindi si basa tutto sulle date di creazione/ epoch!!!)

    -dalla lista eventi: prende l'ultimo e capisce se è aperto o chiuso => renderizza cosa diverse
    -nella select si può scegliere l'evento a cui fare riferimento, una volta scelto si possono visualizzare le 
        richieste stored create in quel periodo di tempo accedendo all'archivio.
        -una volta scelto l'evento vengo calcolate tutte le statistiche e creati i vari charts
    uso EPOCH per manipolare le date
*/
export function StoricoEmergenze(props){

    var emergencyURL = baseUrl + "/api/v1/richieste/emergenza";
    var apiUrl = baseUrl + "/api/v1/eventi";
    var richiesteURL = baseUrl + "/api/v1/richieste/tutte";
    let history = useHistory();

    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId, ruolo, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject;

    const [avviso, setAvviso] = useState(false)
    const [zonaCritica, setZonaCritica]= useState([{nome:'', numero:0}]); /** parliamo dei municipi, e del numero delle richieste inserite  */
    const [giornataCriticaEvento, setGiornataCriticaEvento]= useState([{giorno:'', numero:0}]); /**numero delle richieste inserite in quel giorno */
    const [giornataCriticaAnno, setGiornataCriticaAnno]= useState([{giorno:'', numero:0}]);
    const [giornataMeteoSelected, setGiornataMeteoSelected]= useState([{giorno:''}]);

    const [listaRichieste, setListaRichieste] = useState([]);
    const [listaRichiesteAnnue, setListaRichiesteAnnue] = useState([]);

    const [listaEventi, setListaEventi] = useState([])
    const [modal, setModal] = useState(false)

    const {setRenderArchivio, eventoConclusoScelto, setEventoConclusoScelto} = props; //eventoConclusoScelto ha due EPOCH
    const [dataInizioEventoAttuale, setDataInizioEventoAttuale] = useState(null);
    const [tipiRichieste, setTipiRichieste]= useState({chiuse:0, annullate:0, duplicate:0})


    var superadmin = ruolo.id==5? true:false; //per render condizionato ai ruoli

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    //creo il primo giorno dell'anno corrente in modo dinamico => mi serve per le statistiche annuali
    let thisYear = new Date().getFullYear(); 
    let firstDayOfYear = new Date(thisYear, 0, 1)
    let today = new Date()

    var todayForInput = new Date();
    var dd = todayForInput.getDate();
    var mm = todayForInput.getMonth() + 1; //January is 0!
    var yyyy = todayForInput.getFullYear();

    if (dd < 10) {
    dd = '0' + dd;
    }

    if (mm < 10) {
    mm = '0' + mm;
    } 
        
    todayForInput = yyyy + '-' + mm + '-' + dd;

    //GET DEGLI EVENTI
    useEffect(() => { 
        if(accessToken == null){return}

        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                setListaEventi(result.message)
                setAvviso('')
            }else{
                setAvviso('Non è stato possibile ottenere la lista degli eventi')
                console.log('GET EVENTI ANNUALI:' + result.error)
            }
            //console.log('risposta get eventi iniziale',result.message)
        })
        .catch(error => console.log('error', error));


    /* GET DELLE REQ ANNUALI */
        fetch(richiesteURL, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                setListaRichiesteAnnue(result.message)
                setAvviso('')
            }else{
                setAvviso('Non è stato possibile ottenere la lista delle richieste')
                console.log('GET REQ ANNUALI:' + result.error)
            }
            //console.log('risposta get richieste annuali', result.message)
        })
        .catch(error => console.log('error', error));

    },[accessToken, userId, modal])
    
    useEffect(() => {
        getUltimoEvento()
    },[listaEventi])
    
    useEffect(() => {
        if(eventoConclusoScelto.dataInizio!=''){
            callAPIAndAnalyse()
        }
    }, [eventoConclusoScelto])

    useEffect(() => {
        if(listaRichieste.length !=0){
            calcoloTipiRichieste()
        }
    },[listaRichieste])



    function concludiEmergenza(){

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(emergencyURL, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result)
            if(result.message){
                setModal(false)
                setAvviso('')
            }
            else if(result.error){
                setAvviso(`Non è stato possibile concludere l'emergenza`)
                console.log('CONCLUDI EMERGENZA:' + result.error)
            }
        })
        .catch(error => console.log('error', error));
    }

    function getUltimoEvento(){
        const ultimoEvento = listaEventi.length!=0?listaEventi[listaEventi.length - 1]:null;

        if( ultimoEvento && ultimoEvento.closedAt == null){ //significa che è ancora aperto
            let orarioLocale = new Date(ultimoEvento.createdAt).toLocaleTimeString()
            //console.log("orarioLocale", orarioLocale)
            setDataInizioEventoAttuale(ultimoEvento.createdAt.split('T')[0].split('-')[2]+'-'+ ultimoEvento.createdAt.split('T')[0].split('-')[1]+'-'+ultimoEvento.createdAt.split('T')[0].split('-')[0]+' - '+ orarioLocale)
        }else{ //l'ultimo evento è chiuso
            setDataInizioEventoAttuale(null)
        }
    }

    function eventoConclusoSelected(e){
        //console.log("EVENTO scelto dalla lista",e.target.value)
        let evento = e.target.value;
        //sono due epoch separate da una A (che ho messo io)
        let creationDateEpoch = +evento.split('A')[0]
        let closingDateEpoch = +evento.split('A')[1]
        setEventoConclusoScelto({dataInizio:creationDateEpoch, dataConclusione:closingDateEpoch})
    }

    function calcoloTipiRichieste(){
        var [tipo1, tipo2, tipo3]=[0,0,0]
        listaRichieste.forEach(item=>{
            if(item.statirichiestaId==6){ tipo1+=1} //chiuse
            if(item.statirichiestaId==7){ tipo2+=1} //annullate
            if(item.statirichiestaId==7&&item.causeannullamentoId==3){ tipo3+=1} //annullate per duplicazione
        })
        setTipiRichieste({chiuse:tipo1, annullate:tipo2, duplicate:tipo3})
    }

    function callAPIAndAnalyse() {
        //chiamo tutte le RICHIESTE che appartengono a quell'intervallo di date UNA VOLTA SCELTO L'EVENTO METEO 

        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);
        myHeaders.append('x-data-inizio', eventoConclusoScelto.dataInizio);
        myHeaders.append('x-data-conclusione', eventoConclusoScelto.dataConclusione);


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(emergencyURL, requestOptions)
            .then(response => response.json())
            .then(result =>{ 
                if(result.message){
                    //console.log("Lista richieste dell'evento scelto",result.message);

                    setListaRichieste(result.message); 
                    setAvviso('')

                }else if (result.error){
                    setAvviso('Non è stato possibile ottenere la lista delle richieste')
                    console.log('GET REQ EVENTO:' + result.error)
                }
        })
        .catch(error => console.log('error', error)); 
    }

    function handleGiornoMeteo(e){
        let giornoScelto = e.target.value.split('-')[2] +'-'+ e.target.value.split('-')[1]  +'-'+ e.target.value.split('-')[0]
        setGiornataMeteoSelected({giorno:giornoScelto})
    }


    return(
        <div  className="storicoEmergenze">
            <div className="">
                <p className="titoloStoricoEmergenze">EMERGENZA METEO ATTUALE </p>
                <div className="bodyStoricoEmergenze">

                    {dataInizioEventoAttuale&&
                    <p>Iniziata il: <span>{dataInizioEventoAttuale}</span> <br/>
                        
                    </p>}
                    {!dataInizioEventoAttuale&&<p className="">Nessuna Emergenza in corso.</p>}
                    
                    {(superadmin&&dataInizioEventoAttuale)&&<div className="bottoneConcludiEmergenza" onClick={()=>setModal(true)}>
                        <FontAwesomeIcon icon={faStopwatch} style={{marginRight:'0.5em'}}></FontAwesomeIcon>
                            CONCLUDI EMERGENZA
                    </div> } {(superadmin&&dataInizioEventoAttuale)&&<span  className="warningConcludi">Cliccando su concludi emergenza, archivierai tutte le richieste fino ad ora inserite resettando di conseguenza tutti i contatori della dashboard.</span>}
                </div>

            </div>
            <hr></hr>

            <div className="strisciaStatsEvento">
                <p className="titoloStoricoEmergenze">EVENTI EMERGENZIALI CONCLUSI</p>
                <div className="bodyStoricoEmergenze">
                    <label>Scegli un evento</label>
                    <select className="selectStorico" onChange={(e) => eventoConclusoSelected(e)}>
                        <option value="">---</option>
                        {listaEventi.map((evento)=>{
                                if(evento.closedAt){
                                    //Sono epoch
                                    let inizio = new Date(+evento.epoch).toLocaleString().replaceAll("/", "-")
                                    let fine = new Date(+evento.closedAt).toLocaleString().replaceAll("/", "-")

                                    return <option key={evento.id} value={evento.epoch + 'A' + evento.closedAt }>{evento.id} - DAL {inizio} AL {fine}</option>
                                }else{return}
                            })}
                    </select>

                    {eventoConclusoScelto.dataInizio!='' && 
                        <div className="">
                            {superadmin&&<div onClick={()=> setRenderArchivio(true)} className="goToArchivioRichieste"> 
                                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                                <span>VEDI LE RICHIESTE ARCHIVIATE RELATIVE A QUESTO EVENTO</span>
                            </div>}
                            <div className="containerStatisticheEvento">
                                <div className="">
                                    <span>Richieste Totali </span>{listaRichieste.length}
                                </div>
                                <div className="statisticheTipiRichieste">
                                    <span>Tipi Richieste</span> 
                                    <div>Chiuse: {tipiRichieste.chiuse} <br/> Annullate: {tipiRichieste.annullate}, di cui <br/> Duplicate:{tipiRichieste.duplicate}</div>
                                </div>
                                <div className="containerCharts">
                                    Tipi Richiedente
                                    <ChartRichiedenti listaRichieste={listaRichieste}></ChartRichiedenti>
                                </div>
                                <div className="giornataCriticaDiv">
                                    <span>Giornata Critica</span> <div className="zonaCriticaText"> {giornataCriticaEvento.giorno} con {giornataCriticaEvento.numero} richieste </div>
                                </div>
                                <div>
                                    <span>Meteo Giornata Critica</span> <br/>
                                    <MeteoGiornataCritica giornataCritica={giornataCriticaEvento}></MeteoGiornataCritica>
                                </div>
                                <div className="containerChartAnnuale">
                                    <ChartTemporale listaRichieste={listaRichieste} dataInizio={new Date(eventoConclusoScelto.dataInizio)} dataFine={new Date(eventoConclusoScelto.dataConclusione)} setGiornataCritica={setGiornataCriticaEvento}></ChartTemporale>
                                </div>
                                <div className="zonaCritica">
                                    <span>Zona Critica</span> 

                                    {zonaCritica.map(item=>{
                                        if(item.numero ==1){ 
                                            return <div className="zonaCriticaText" key={item.id}>{item.nome.replace("_", " ")} - {item.numero} richiesta </div>

                                        }else{
                                            return <div  className="zonaCriticaText" key={item.id}>{item.nome.replace("_", " ")} - {item.numero} richieste </div>
                                        }
                                    })}

                                </div>
                                <div className="containerCharts">
                                    <MappaMunicipiStorico listaRichieste={listaRichieste} zonaCritica={zonaCritica} setZonaCritica={setZonaCritica}></MappaMunicipiStorico>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            </div> 
            <hr></hr>

            <div className="">
                <p className="titoloStoricoEmergenze">STATISTICHE ANNUALI</p>
                <div className="containerStatisticheAnnuali">
                    <div className="giornataCriticaDiv">
                        <span>Giornata Critica</span> {giornataCriticaAnno.giorno} con {giornataCriticaAnno.numero} richieste <br/>
                    </div>
                    <div>
                        <span>Meteo Giornata Critica</span> <br/>
                        <MeteoGiornataCritica giornataCritica={giornataCriticaAnno}></MeteoGiornataCritica>
                    </div>
                    <div className="containerChartAnnuale">
                        <ChartTemporale listaRichieste={listaRichiesteAnnue}  dataInizio={firstDayOfYear} dataFine={today} setGiornataCritica={setGiornataCriticaAnno} ></ChartTemporale>
                    </div>
                </div>
            </div> 
            <hr></hr>

            <div className="">
                <p className="titoloStoricoEmergenze">CONSULTAZIONE METEO</p>
                <div className="containerStatisticheMeteo">
                    <div className="">
                        <input type="date"  onChange={(e)=>handleGiornoMeteo(e)} name="meteo"
                            min="2021-06-01"
                            max={todayForInput}
                        ></input>
                    </div>
                    <MeteoGiornataCritica giornataCritica={giornataMeteoSelected}></MeteoGiornataCritica>
                </div>
            </div> 


            {modal&&
                <div className="containerModal" onClick={(e)=>{if(e.target == e.currentTarget){setModal(false)}}}>
                    <div className="eliminaModal" >
                        <p> Sei sicuro di voler segnare conclusa questa emergenza? <br/> L'operazione non sarà reversibile!</p>
                        <div className="eliminaButtonModal" onClick={()=>concludiEmergenza()}>SI, Concludi</div>
                        <div className="eliminaButtonModal" onClick={()=>setModal(false)}>NO, Annulla</div>
                    </div>
                </div>
            } 
            {avviso}
        </div> 
            
    )
}

export default StoricoEmergenze
