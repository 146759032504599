import {useHistory} from "react-router-dom";
import {useState, useContext} from 'react';
import { useTable , useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import React from 'react'
import { UserContext } from "../../Context/UserContext.js";

import { CSVLink } from "react-csv";
import {matchSorter} from 'match-sorter'
import MappaVisualizzaComp from "../InserimentoRichiestaComponents/MappaVisualizza.js"

import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


//funzione che uso per l'array data che serve x generare il csv (ha elementi nested questa f toglie i livelli di profondità e toglie le chiavi che non voglio)
function flattenObj(obj, parent, res = {}){
    //parent è la chiave-key: esempio causaannullamentoId
    //console.log(obj)
    for(let key in obj){
        if(key != 'epoch'&& key != 'causeannullamentoId'&& key != 'danniId'&& key != 'oggettirichiestaId'&& key != 'prioritiesId'&& key != 'statirichiestaId'&& key != 'tipiinterventoId'&& key != 'tipirichiedenteId'){
            
            let propName = parent ? parent + '_' + key : key;
            if(typeof obj[key] == 'object'){
                flattenObj(obj[key], propName, res);
            } else {
                if(!(propName.includes('password') || propName.includes('logged'))){ //se non include ne password ne logged allora ok
                    if(propName.includes('mezzis')){ //se include mezzis deve mettere solo la targa e idcapoSQ
                        if((propName.includes('targaMezzo')|| propName.includes('idCapoSquadra'))){
                            res[propName] = obj[key];
                        }                            
                    }else if(propName.includes('immaginis')){
                        if((propName.includes('id'))){
                            res[propName] = obj[key];
                        }
                    }else{
                        res[propName] = obj[key];
                    }
                    
                }
            }
        }
    }
    return res;
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter) //value è la parola cercata
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    const [ricercaGlobale, setRicercaGlobale] = useState(false);

    //console.log("Buondi", preGlobalFilteredRows, globalFilter)

    return (
    <div className="ricercaGlobaleComponent">
        <div className={`ricercaButtons ${ricercaGlobale?"attivo":""}`} onClick={()=>setRicercaGlobale(!ricercaGlobale)}> Ricerca Globale</div>
            {ricercaGlobale&&<div>
                <div className="ricercaInput">
                    <input 
                        value={value || ""}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} richieste...`}
                        style={{
                            border: '0',
                        }}
                    />
                </div>
                <div  className="deleteDateButton" onClick={()=>{setValue();onChange();}}>&times;</div> 
            </div>}
    </div>

    )
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length
    //console.log("BHOO", preFilteredRows )

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            /* placeholder={`Ricerca su ${count} richieste...`} */
            placeholder={`Ricerca su ${count}...`}

        />
    )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

  // Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val




function Table({ columns, data, emergenzaPage }){
    //console.log("Data nella Table", data)
    
    const [mappaVisualizza, setMappaVisualizza] = useState(false);
    const [impostaColonne, setImpostaColonne] = useState(false);

    const userObject = useContext(UserContext);
    const ruolo = userObject.ruolo;

    var adminOrSuperadmin = false;
    if(ruolo.id == 5 || ruolo.id == 4){
        adminOrSuperadmin = true;
    }

    var dataPerCsv = [];
    data.forEach((richiesta) => {
        //console.log(richiesta.deleted)
        if(richiesta.deleted == false){
            //console.log("richiesta preflatten", richiesta)
            let richiestaAppiattita = flattenObj(richiesta)
            dataPerCsv.push(richiestaAppiattita)
        }
    })
    
    //console.log("dataPerCsv", dataPerCsv)

    let history = useHistory();

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
                })
            },
            }),
            []
        )
    const defaultColumn = React.useMemo(
        () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
        getToggleHideAllColumnsProps,
        state,
        visibleColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
        columns,
        data,
        defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes,
        initialState: {
            sortBy: [
                {
                    id: 'Id Richiesta',
                    desc: false
                }
            ]
        }
        },
        useFilters, // useFilters!
        useGlobalFilter // useGlobalFilter!
    )

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef
        
            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])
        
            return <input type="checkbox" ref={resolvedRef} {...rest} />
            }
        )

//con questa funzione passo l'id della cliccata come prop alla pagina dopo
    function clickedRow(e){
        history.push({
            pathname: '/singolarichiesta',
            state: { idRichiesta: e.target.parentNode.id, emergenzaPage:emergenzaPage}
        });
    }

    // Render the UI for your table
    const customStyle={ 
        borderCollapse: 'separate',
        borderSpacing: '0px 10px',
        cellpadding:"10",
    }

    return (
        <>
            <div className="apriMappaComponent">
                <div className="ricercaButtons" onClick={()=>setMappaVisualizza(!mappaVisualizza)}>Apri Mappa 
                    <FontAwesomeIcon icon={faMapMarkedAlt} className="iconaMappaRicerca" ></FontAwesomeIcon> 
                </div>
            </div>
            <div className="containerRicercaEColonne">
                <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                <div className="apriImpostaColonneComp">
                    <div className={`ricercaButtons ${impostaColonne?"attivo":""}`} onClick={()=>setImpostaColonne(!impostaColonne)}>Imposta Colonne</div>
                </div>
            </div>
            {adminOrSuperadmin&&<div className="apriMappaComponent">
                <CSVLink   data={dataPerCsv}  separator={";"}> 
                    <div className="ricercaButtons" >  Scarica CSV
                        <FontAwesomeIcon icon={faTable} className="iconaMappaRicerca"></FontAwesomeIcon> 
                    </div>
                </CSVLink>

            </div>}
            

            {mappaVisualizza&&<MappaVisualizzaComp rows={rows} setMappaVisualizza={setMappaVisualizza}></MappaVisualizzaComp>}   
            {impostaColonne&&
            
            <div className="selezionaColonneDiv">
                <div className="selezionaColonneCheckbox">
                    <IndeterminateCheckbox {...getToggleHideAllColumnsProps()}/> Seleziona tutte
                </div>
                {allColumns.map(column => (
                    <div key={column.id} className="selezionaColonneCheckbox">
                    <label>
                        <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                        {column.id}
                    </label>
                    </div>
                ))}
            </div>
            }


            <div className="containerTabella Flipped ">
            <table {...getTableProps()} style={customStyle} >
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        
                        <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                        {/* Render the columns filter UI */}
                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            /* CLICCANDO SU UN RECORD SI APRE UNA PAGINA RELATIVA AD ESSO */
                        <tr {...row.getRowProps()} id={row.original.id} className="rowTableRichiesta" onClick={(e)=>clickedRow(e)}>
                            {row.cells.map(cell => {
                                if(cell.column.Header == 'Id Richiesta' || cell.column.Header == 'Civico'|| cell.column.Header == 'Id Richiesta Duplicata' || cell.column.Header == 'Immagini Allegate'){
                                    return <td className="colonnaCentrata" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                }else{
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                }
                            })}
                        </tr>
                        )
                    })}
                </tbody>

            </table>
            </div>
        </>
    )
}
export default Table;


