/* questo component conterrà solo la label e la select della lista tipo richiedente, gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";

import baseUrl from '../../utilities/globalVariables.js'



function TipoRichiedente(props){
    const [listaTipi, setListaTipi] = useState([]);

    const [tipoRichiedente, setTipoRichiedente] = [props.tipoRichiedente, props.setTipoRichiedente];

    const userObject = useContext(UserContext);
    const { userId,ruolo, accessToken} = userObject

    //per render condizionato ai ruoli
    var editor = ruolo.id==2?true:false;

    //onload faccio get a lista tipi per polare la select
    useEffect(()=>{
        if(accessToken==null){return}
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/richieste/tiporichiedente ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista tipiRichiedenti", result.message)
            if(result.message != undefined){
                setListaTipi(result.message)
            }else{
                setListaTipi([])
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])
   // console.log("tipoRichiedente",tipoRichiedente)

    return(
        <div className="campoInserimentoRichiesta">
            <label>Tipo Richiedente</label>
            <select onChange={(e)=>setTipoRichiedente(e.target.value)} value={tipoRichiedente}  disabled={editor?'disabled':''}>
                {listaTipi.map((tipo)=>{
                        return <option key={tipo.id} value={tipo.id}>{tipo.name}</option>
                    })}
            </select>
        </div>
    )
}
export default TipoRichiedente;