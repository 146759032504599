/* questo component conterrà solo la label e la select della lista priority , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";

import baseUrl from '../../utilities/globalVariables.js'


function Priority(props){
    const [listaPriority, setListaPriority] = useState([]);

    const {priority, setPriority} = props;
    const userObject = useContext(UserContext);    
    const { userId, accessToken, ruolo} = userObject;

    //per render condizionato ai ruoli
    var editor = ruolo.id==2?true:false;

    //onload faccio get a lista Priority per polare la select
    useEffect(()=>{
        if(accessToken==null){return}

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/richieste/priority ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista priorità", result.message)
            if(result.message != undefined){
                setListaPriority(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    return(
        <div className="campoInserimentoRichiesta">
            <label>Priorità</label>
            <select onChange={(e)=> setPriority(e.target.value)} value={priority} disabled={editor?'disabled':''}>
            {listaPriority.map((priority)=>{
                    return <option key={priority.id} value={priority.id}>{priority.name}</option>
                })}
            </select>

        </div>
    )
}
export default Priority;