/* questo component conterrà solo la label e la select della lista stato , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";


import baseUrl from '../../utilities/globalVariables.js'


function StatoRichiesta(props){
    const [listaStati, setListaStati] = useState([]);
    const [statoRichiesta, setStatoRichiesta] = [props.statoRichiesta,  props.setStatoRichiesta];

    const userObject = useContext(UserContext);
    const { userId,ruolo, accessToken} = userObject

    //per render condizionato ai ruoli
    var editor = ruolo.id==2?true:false;
    var moderator = ruolo.id==3?true:false;

    //onload faccio get a lista Stati per polare la select
    useEffect(()=>{
        if(accessToken==null){return}

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(baseUrl + "/api/v1/richieste/statorichiesta ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista statorichiesta", result.message)
            if(result.message != undefined){
                setListaStati(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    return(
        <div className="campoInserimentoRichiesta">
            <label>Stato Richiesta</label>
            <select onChange={(e)=>setStatoRichiesta(e.target.value)} value={statoRichiesta}>
            {listaStati.map((stato)=>{
                if((editor||moderator)&&stato.id==6){
                    return
                }else{ 
                    return <option key={stato.id} value={stato.id}>{stato.name}</option>
                }
                })}
            </select>
        </div>
    )
}
export default StatoRichiesta;