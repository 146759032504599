import React, { useState, useEffect, useContext, useRef } from "react";
import {useHistory} from "react-router-dom";

import "../style/Sidebar.css";
import { NavLink } from 'react-router-dom';
import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import DateTime from "./DateTime.js";

import { faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faTruckPickup } from "@fortawesome/free-solid-svg-icons";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* import logoGEM from "../imgs/logo3.png" */
import logoGEM from "../imgs/risorsa2.png"
import baseUrl from '../utilities/globalVariables.js'


/* 
la classe  sidebarChiusa o meno, che dipende dallo stato openSideMenu, fa differenza solo perchè cambia con il css


il menu si apre e si chiude anche in versione wide screen 

*/
function Sidebar(){
    const userObject = useContext(UserContext);
    const { setUsername, userId, setUserId, ruolo, setRuolo, setMail, accessToken, setAccessToken, capoSquadra, setCapoSquadra} = userObject

    const sideMenuContext =  useContext(SideMenuContext);
    const {openSidemenu, setOpenSidemenu} = sideMenuContext

    const [hover, setHover]= useState(false)
    const [openImage, setOpenImage]= useState(false)

    let history = useHistory();
    
    const urlAPI = baseUrl + "/api/v1/auth/signin"


    if(accessToken == null&& window.localStorage.firstInstall != undefined){
        //console.log("accesstokenera null, local:",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    //per render condizionato ai ruoli
    var superadmin = ruolo.id==5?true:false;
    var admin= ruolo.id==4?true:false;
    var moderator= ruolo.id==3?true:false;
    var editor = ruolo.id==2?true:false;
    var user = ruolo.id==1?true:false;


    //SLOGGO AL CLICK DELLA PORTA
    function handleLogout(){
        setUsername('');
        setUserId('');
        setMail('');
        setRuolo({id:'', name:''})
        setAccessToken(null)

        window.localStorage.removeItem("userId"); //JSON.stringify(res.id) JSON.stringify(res.role.id)
        window.localStorage.removeItem("username");
        window.localStorage.removeItem("mail");
        window.localStorage.removeItem("ruoloId" ); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
        window.localStorage.removeItem("ruoloName"); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("caposquadra");
    

        var myHeaders = new Headers();        
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(urlAPI, requestOptions)
        .then(response => response.json())
        //.then(result => console.log(result))
        .catch(error => console.log('error', error));
        history.push("/")
    }


    return(
    <div className={`sidebar ${openSidemenu?"":"sidebarChiusa"}`} onClick={()=>{if(openSidemenu == true){setOpenSidemenu(false)}}}>  {/* sidebarChiusa */}
        <div className="titleAGE" > 
            <img src={logoGEM} onClick={()=>setOpenImage(true)}></img> 
            <span className={`${openSidemenu ? '' :'titleAGEspanChiuso'}`}>Gestione <br/> Emergenze <br/> Milano</span>
        </div> 
        <div className={`immagineApertaDivSidebar ${openImage?"":"chiusa"}`} onClick={()=>setOpenImage(false)} >
            <img src={`${logoGEM}`}></img>
            <p className="payOffBig">Gestione Emergenze Milano</p>
        </div>
        <p className="menuIcon"  onClick={()=>setOpenSidemenu(!openSidemenu)}><FontAwesomeIcon icon={faBars} title="Mostra/Nascondi Menu"></FontAwesomeIcon></p>

        <div className="contenutoSidebar">

            {/* PARTE ICONE */}
            <div className="iconeSidebar">
                <p ><NavLink  to="/dashboard" activeClassName="active"> <FontAwesomeIcon icon={faChartPie} title="DashBoard"></FontAwesomeIcon> </NavLink ></p>
                {(admin || moderator || superadmin) &&
                    <p ><NavLink  to="/inserimentoRichiesta" activeClassName="active"> <FontAwesomeIcon icon={faPencilAlt}  title="Inserisci"></FontAwesomeIcon> </NavLink ></p>
                }
                <p ><NavLink  to="/ricercaRichiesta" activeClassName="active"> <FontAwesomeIcon icon={faSearch}  title="Ricerca"></FontAwesomeIcon>  </NavLink ></p>
                {(admin || superadmin) && <p ><NavLink  to="/gestioneMezzi"      activeClassName="active"> <FontAwesomeIcon icon={faTruckPickup}  title="Mezzi"></FontAwesomeIcon>  </NavLink ></p>}
                {superadmin &&<p ><NavLink  to="/gestioneUtenti" activeClassName="active"> <FontAwesomeIcon icon={faUserFriends}  title="Utenti"></FontAwesomeIcon>  </NavLink ></p>}
                {(editor&&capoSquadra) &&
                        <p ><NavLink  to="/lemierichieste" activeClassName="active" title="Le Mie Richieste"> <FontAwesomeIcon icon={faClipboardList}></FontAwesomeIcon>  </NavLink ></p>}
                {(user||superadmin) &&<p ><NavLink  to="/storico" activeClassName="active"> <FontAwesomeIcon icon={faHistory}  title="Storico"></FontAwesomeIcon>  </NavLink ></p>}
                
            </div>

            {/* PARTE TESTUALE */}
            <div className={`linkSidebar ${openSidemenu?"":"linkSidebarChiuso"} "`}> {/* linkSidebarChiuso */}
                <p ><NavLink  to="/dashboard" activeClassName="active"> Dashboard </NavLink ></p>
                {(admin || moderator ||superadmin) &&
                    <p ><NavLink  to="/inserimentoRichiesta" activeClassName="active"> Inserimento <br/> Richiesta </NavLink ></p>
                }
                <p ><NavLink  to="/ricercaRichiesta" activeClassName="active"> Ricerca <br/>Richiesta  </NavLink ></p>
                {(admin || superadmin)&& <p ><NavLink  to="/gestioneMezzi" activeClassName="active"> Gestione <br/>Mezzi  </NavLink ></p>}
                {superadmin &&<p ><NavLink  to="/gestioneUtenti" activeClassName="active"> Gestione <br/>Utenti  </NavLink ></p>}
                {(editor&&capoSquadra) &&
                        <p ><NavLink  to="/lemierichieste" activeClassName="active"> Le Mie <br/>Richieste  </NavLink ></p>}
                {(user||superadmin) &&<p ><NavLink  to="/storico" activeClassName="active"> Storico </NavLink ></p>}
                
            </div>
        </div>

        <div className="logOutDiv">
            
            {!superadmin&&<DateTime></DateTime>}
            {hover&&<p className="logOutText">ESCI</p>}

            <FontAwesomeIcon icon={hover?faDoorOpen:faDoorClosed} className="logOutIcon" onClick={()=>{handleLogout()}} onMouseOver={()=>setHover(true)} onMouseOut={()=>setHover(false)} ></FontAwesomeIcon> 
        </div>
    </div>
    )
}
export default Sidebar;

/* https://openweathermap.org/img/wn/{iconId}@2x.png */
