import "../style/Dashboard.css"
import {useState, useEffect, useContext} from 'react';
import React from 'react'
import MappaVisualizzaComp from "./DashboardComponents/MappaVisualizzaConMezzi.js"
import ChartRichiedenti from "./DashboardComponents/ChartRichiedenti.js"
import ChartPrioritàTipi from "./DashboardComponents/ChartPrioritàTipi.js"
import ChartTipiIntervento from "./DashboardComponents/ChartTipiIntervento.js"
import Municipi from "./DashboardComponents/MappaMunicipi.js"
import Footer from "./Footer.js"
import HeaderDashboard from "./DashboardComponents/HeaderDashboard.js"

import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GridTable from "./GridTable.js"
import OneSignal from 'react-onesignal';
import {useHistory} from "react-router-dom";



/* CHANGE THOSE FROM PROD TO DEV */
import baseUrl from '../utilities/globalVariables.js'
import oneSignalKeys from '../utilities/oneSignal.js'


function Homepage(){
    
    const richiesteUrl = baseUrl + "/api/v1/richieste";
    const mezziURL = baseUrl + "/api/v1/mezzi";

    const appId = oneSignalKeys.appId; //appId
    //const basicAPI = oneSignalKeys.basicAPI //Basic serve solo nel sendNotification

    const [listaRichieste, setListaRichieste] = useState([]);
    const [listaMezzi, setListaMezzi] = useState([]);
    const [listaTipiIntervento, setListaTipiIntervento] = useState([]);
    const [listaRichiesteRisolte, setListaRichiesteRisolte] = useState([])
    const [listaRichiesteMesseInSicurezza, setListaRichiesteMesseInSicurezza] = useState([])
    const [listaRichiesteChiuse, setListaRichiesteChiuse] = useState([])
    const [mappaVisualizza, setMappaVisualizza] = useState(false);

    const userObject = useContext(UserContext);
    const {username, setUsername, userId, setUserId, ruolo, setRuolo, accessToken, setAccessToken, capoSquadra, setCapoSquadra} = userObject


    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

    const [counterMunicipioAttive, setCounterMunicipioAttive] = useState()
    const [counterMunicipioTotali, setCounterMunicipioTotali] = useState()
    const [municipioClicked, setMunicipioClicked] = useState('')
    const [counterMezziAttivi, setCounterMezziAttivi] =useState(0)

    const [risolteIconClicked, setRisolteIconClicked] = useState(false)
    const [inseriteIconClicked, setInseriteIconClicked] = useState(true)
    const [messeInSicurezzaIconClicked, setMesseInSicurezzaIconClicked] = useState(false)
    const [chiuseIconClicked, setChiuseIconClicked] = useState(false)

    let history = useHistory();

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    window.onbeforeunload = function() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('ruoloName');
        localStorage.removeItem('username');
        localStorage.removeItem('ruoloId');
        localStorage.removeItem('userId');
        localStorage.removeItem('caposquadra');
        return '';
    };

    useEffect(()=>{ //inizializza oneSignal
        OneSignal.init({appId:appId}) //la trovi in Keys& IDs pagina personale dopo aver registrato l'app //oppure nella stringa dell'url se sei in Segments(?)
        OneSignal.sendTag('userId', userId).then(()=>{
            OneSignal.sendTag('ruoloId', ruolo.id).then(()=>{
                OneSignal.sendTag('capoSquadra', capoSquadra)
            })
        })
    },[userId])

    //GET INIZIALE DI TUTTE LE RICHIESTE
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers:myHeaders,
            redirect: 'follow'
        };
        
        fetch(richiesteUrl, requestOptions)
            .then(response => response.json())
            .then(result =>{ 
            if(result.message){
                setListaRichieste(result.message); 
            }else if (result.error){
                setListaRichieste([]); 
            }
        })
        .catch(error => console.log('error', error)); 
    
    //GET PER TUTTI I MEZZI_ID    
        fetch(mezziURL, requestOptions)
        .then(response => response.json())
        .then(result =>{ 
        if(result.message){
            setListaMezzi(result.message); 
        }else if (result.error){
            console.log(result.error)
        }
        })
        .catch(error => console.log('error', error)); 

    ///GET PER I TIPI DI INTERVENTOR
        fetch(baseUrl + "/api/v1/richieste/tipointervento ", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message != undefined){
                setListaTipiIntervento(result.message)
            }
        })
        .catch(error => console.log('error', error));
    },[accessToken])

    //UNA VOLTA PIENATA LA LISTA RICHIESTE, PARTE QUESTO USEEFFECT per il div con tutti i counters
    useEffect(()=>{
        let value=[];
        //RISOLTE
        listaRichieste.forEach(item=>{
            if(item.statirichiestaId ==4 || item.statirichiestaId ==5 ){
                value.push(item)
            }
            return
        })
        setListaRichiesteRisolte(value); 

        //MESSEINSIC
        let value2=[];
        listaRichieste.forEach(item=>{
            if(item.statirichiestaId == 3){
                value2.push(item)
            }
            return
        })
        setListaRichiesteMesseInSicurezza(value2); 

        //CHIUSE
        let value3=[];
        listaRichieste.forEach(item=>{
            if(item.statirichiestaId == 6){
                value3.push(item)
            }
            return
        })
        setListaRichiesteChiuse(value3); 

    },[listaRichieste])

    //UNA VOLTA PIENATA LA LISTA mezzi, PARTE QUESTO USEEFFECT
    useEffect(()=>{
        let value=0;
        listaMezzi.forEach(item=>{
            if(item.user.logged ==true){
                value+=1;
            }
        })
        setCounterMezziAttivi(value)
    },[listaMezzi])

    const [legenda, setLegenda] = useState(false)

    

    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
            <div className="contenutoMainHomepage">
                <HeaderDashboard></HeaderDashboard>
                <div  className="dashboardGrid">
                    <div className="dashboardLeftMunicipi">
                        <div className="buongiornoDiv">
                        <strong>Buongiorno </strong><br/> {username} 
                        </div>
                        <div className="mappaHomepageDiv" onClick={()=>setMappaVisualizza(!mappaVisualizza)}> MAPPA EVENTI<br/> 
                            <FontAwesomeIcon icon={faMapMarkedAlt} className="leftDivCounter" style={{color:'#DB2219'}}></FontAwesomeIcon> 
                        </div>
                        <div className="mezziAttiviDiv">
                            <p >MEZZI ATTIVI </p>{/* (cs logg) */}
                            <p className="leftDivCounter">{counterMezziAttivi}</p>
                        </div>
                    </div>
                    <div className="welcomeDiv cardsDash">
                        <div className="welcomeDivMap">
                            <Municipi 
                                setMunicipioClicked={setMunicipioClicked} 
                                listaRichieste={listaRichieste} 
                                setCounterMunicipioAttive={setCounterMunicipioAttive} 
                                setCounterMunicipioTotali={setCounterMunicipioTotali}> 
                            </Municipi>
                            <div className="welcomeDivMapLeft">
                                <div className="counterMunicipi"> 
                                    <h4>{municipioClicked}</h4>
                                    <p>RICHIESTE ATTIVE </p>
                                    {/* <span>(Inserite, Prese In carico, <br/>Messe in Sicurezza o Recupero Risulta.)</span> */}
                                    <div className="numeriDashboard"> {counterMunicipioAttive} </div>
                                    <p> RICHIESTE TOTALI </p>
                                    <div className="numeriDashboard"> {counterMunicipioTotali}</div> 
                                </div>
                                <div className="legendaPercentuali">
                                    <div className={`tooltipLegenda ${legenda?'tooltipAperto':''}`}>
                                        <h5>Per ogni municipio, la percentuale esprime 
                                            il numero di richieste inserite appartenenti al singolo municipio 
                                            rispetto al numero di richieste inserite su tutta l'area.</h5>
                                        <p style={{color:'#22b85e'}}>% &lt; 5</p>
                                        <p style={{color:'yellow'}}> 5 &lt; % &lt; 10</p>
                                        <p style={{color:'#FF6D0A'}}>10 &lt; % &lt; 20</p>
                                        <p style={{color:'#DB2219'}}>% &gt; 20</p>
                                    </div>
                                    <h4 onClick={()=>setLegenda(!legenda)}>LEGENDA <br/>COLORI <br/>MUNICIPI</h4>

                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="dashboardLeft">
                        <div className="chartPriority"> STATI RICHIESTA - PRIORITÀ
                        <ChartPrioritàTipi listaRichieste={listaRichieste}></ChartPrioritàTipi>
                        </div>
                    </div>
                    
                    <div className="dashboardCenter">
                        <div className="chartRichiedenti"> RICHIEDENTI
                            <ChartRichiedenti listaRichieste={listaRichieste}></ChartRichiedenti>
                        </div>
                    </div>
                    <div className="dashboardRight">
                        <div className="chartIntervento"> TIPI INTERVENTO
                            <ChartTipiIntervento listaRichieste={listaRichieste} listaTipiIntervento={listaTipiIntervento}></ChartTipiIntervento>
                        </div>
                    </div>
                    <GridTable listaRichieste={listaRichieste}></GridTable>
                    <div className="dashboardLeftTabella">
                        <div className="countersRichieste"> 
                            <div className="containerIcone">
                                <FontAwesomeIcon icon={faEdit} className={`${inseriteIconClicked?"attivo":""}`} onClick={()=> {
                                    setInseriteIconClicked(true);
                                    setMesseInSicurezzaIconClicked(false)
                                    setRisolteIconClicked(false)
                                    setChiuseIconClicked(false)
                                    }} ></FontAwesomeIcon>  
                                <FontAwesomeIcon icon={faShieldAlt}  className={`${messeInSicurezzaIconClicked?"attivo":""}`}  onClick={()=>{
                                    setMesseInSicurezzaIconClicked(true)
                                    setInseriteIconClicked(false)
                                    setRisolteIconClicked(false)
                                    setChiuseIconClicked(false)
                                }} ></FontAwesomeIcon>  
                                <FontAwesomeIcon icon={faCheck} className={`${risolteIconClicked?"attivo":""}`} onClick={()=>{
                                    setRisolteIconClicked(true);
                                    setMesseInSicurezzaIconClicked(false)
                                    setInseriteIconClicked(false)
                                    setChiuseIconClicked(false)
                                    }} ></FontAwesomeIcon>  
                                <FontAwesomeIcon icon={faCheckDouble} className={`${chiuseIconClicked?"attivo":""}`} onClick={()=>{
                                    setChiuseIconClicked(true)
                                    setMesseInSicurezzaIconClicked(false)
                                    setInseriteIconClicked(false)
                                    setRisolteIconClicked(false)
                                    }} ></FontAwesomeIcon> 
                            </div>
                            <div > 
                                {inseriteIconClicked &&         <div style={{padding:'1em'}}>INSERITE <br/>
                                        <p className="pNumeriCounter">{listaRichieste.length}</p></div>} 
                                {messeInSicurezzaIconClicked && <div style={{padding:'1em'}}>MESSE IN SICUREZZA <br/>
                                        <p className="pNumeriCounter">{listaRichiesteMesseInSicurezza.length}</p></div>} 
                                {risolteIconClicked &&          <div style={{padding:'1em'}}>RISOLTE <br/>
                                        <p className="pNumeriCounter">{listaRichiesteRisolte.length}</p></div>} 
                                {chiuseIconClicked &&           <div style={{padding:'1em'}}>CHIUSE <br/>
                                        <p className="pNumeriCounter">{listaRichiesteChiuse.length}</p></div>} 
                            </div>
                        </div>
                    </div>

                    {mappaVisualizza && <MappaVisualizzaComp data={listaRichieste} setMappaVisualizza={setMappaVisualizza}></MappaVisualizzaComp>}
                </div>

            </div>
            <Footer></Footer>
        </div>
    )
}
export default Homepage;
