

import {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext.js";
import {useHistory} from "react-router-dom"; 
import "../../style/SendNotification.css"

/* CHANGE THOSE FROM PROD TO DEV */
import baseUrl from '../../utilities/globalVariables.js'
import oneSignalKeys from '../../utilities/oneSignal.js'


function SendNotification(props){

    const appId = oneSignalKeys.appId; //appId
    const basicAPI = oneSignalKeys.basicAPI //Basic
    const urlRuoli = baseUrl + "/api/v1/users/ruoli";

    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject;


    const listaUtenti = props.listaUtenti;
    const [listaRuoli, setListaRuoli]= useState([]);
    const [tipoDestinatario, setTipoDestinatario]= useState(0); 
    const [testoNotifica, setTestoNotifica]= useState(''); //
    const [utenteSingolo, setUtenteSingolo]= useState(); 
    const [ruoloScelto, setRuoloScelto]= useState(); 

    const [avviso, setAvviso]= useState({text:'', className:''});

    const [modal, setModal] = useState(false)
    let history = useHistory();

    if(accessToken == null){
        console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }

    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(urlRuoli, requestOptions)
        .then(response => response.json())
        .then(res => {
            //console.log("lista ruoli", res)
            if(res.message){
                setListaRuoli(res.message)
            }
        })
        .catch((err)=>console.log(err))

    },[accessToken])
    //console.log(listaUtenti, listaRuoli)


    function controlliPreCreazione(){
        if(testoNotifica && tipoDestinatario!=0){
            setAvviso({text: '', className:''})
            setModal(true)
            //postToOneSignal()
        }else if(!testoNotifica){
            setAvviso({text: 'Inserisci un testo!', className:'red'})
            return
        }else if(tipoDestinatario ==0){
            setAvviso({text: 'Seleziona un destinatario!', className:'red'})
            return
        }
    }

    function postToOneSignal(){
        var destinatarioPOSTreq;
        //console.log("invio..")

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Basic {${basicAPI}}`);
        myHeaders.append("Content-Type", "application/json");
        if(tipoDestinatario==1){
            destinatarioPOSTreq={"userId":'Tutti gli users'};

            var raw = JSON.stringify({
                "app_id": appId,
                "included_segments": [
                    "Subscribed Users"
                ],
                "data": {
                    "foo": "bar"
                },
                "contents": {
                    "en": testoNotifica
                },
                "headings": {
                    "en": "GEM"
                }
            });
        }
        
        if(tipoDestinatario==2&&ruoloScelto){
            destinatarioPOSTreq={"roleId":ruoloScelto, "capoSquadraFlag":false};

            var raw = JSON.stringify({
            "app_id": appId,
            "filters": [
                {
                "field": "tag",
                "key": "ruoloId",
                "relation": "=",
                "value": ruoloScelto
                }
            ],
            "data": {
                "foo": "bar"
            },
            "contents": {
                "en": testoNotifica
            },
            "headings": {
                "en": "GEM"
            }
            });
        }

        if(tipoDestinatario==4&&utenteSingolo){
            destinatarioPOSTreq={"userId":utenteSingolo};

            var raw = JSON.stringify({
            "app_id": appId,
            "filters": [
                {
                "field": "tag",
                "key": "userId",
                "relation": "=",
                "value": utenteSingolo
                }
            ],
            "data": {
                "foo": "bar"
            },
            "contents": {
                "en": testoNotifica
            },
            "headings": {
                "en": "GEM"
            }
            });
        }

        if(tipoDestinatario==3){ //SOLO CAPISQUADRA
            destinatarioPOSTreq={"roleId":2, "capoSquadraFlag":true};
            var raw = JSON.stringify({
                "app_id": appId,
                "filters": [
                    {
                        "field": "tag",
                        "key": "ruoloId",
                        "relation": "=",
                        "value": 2
                    },
                    {
                        "operator": "AND"
                    },
                    {
                        "field": "tag",
                        "key": "capoSquadra",
                        "relation": "=",
                        "value": true
                    }
                    ],
                    "data": {
                    "foo": "bar"
                    },
                    "contents": {
                    "en": "sei tu?"
                    },
                    "headings": {
                    "en": "GEM"
                    }
                });
        }
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch("https://onesignal.com/api/v1/notifications", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result)
            if(result.errors){
                setAvviso({text: "La notifica non è stata inviata, probabilmente non c'è nessun utente con quel ruolo o nome che abbia accettato la ricezione di notifiche push.", className:'red'})
                setModal(false)
                
            }else if (result.id){
                setModal(false)
                setAvviso({text: 'Notifica inviata!', className:'green'})
                //se tipoDestinatario 1 o 4: destinatarioPOSTreq={userId:# o STR};
                //se tipoDestinatario 2 o 3: destinatarioPOSTreq={roleId:#, capoSquadraFlag:BOOL};
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("x-user-id", userId);
                myHeaders.append('x-access-token', accessToken);


                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({"testoNotifica":testoNotifica, "destinatario":destinatarioPOSTreq}),
                redirect: 'follow'
                };

                fetch(baseUrl + "/api/v1/notifiche", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setTestoNotifica('')
                    console.log("da be",result)
                })
                .catch(error => console.log('error', error));
                }

        })
        .catch(error => console.log('error', error));
    }


    return(
        <div className="containerNotificaComponent"> 
            <p >Qui puoi creare una nuova notifica push:</p>
            <div className="containerSendNotification">
                <div className="destinatariNotifica">
                    <label>A chi vuoi inviare la notifica?</label>
                    <div className="containerBottoniDestinatario"> {/* container bottoni */}
                        <div className={`BottoniDestinatario  ${tipoDestinatario==1?"selectedButton":""}`} onClick={()=>setTipoDestinatario(1)}> A tutti gli utenti</div>
                        <div className={`BottoniDestinatario  ${tipoDestinatario==3?"selectedButton":""}`} onClick={()=>setTipoDestinatario(3)}> A tutti i capisquadra</div>
                        <div className={`BottoniDestinatario  ${tipoDestinatario==2?"selectedButton":""}`} onClick={()=>setTipoDestinatario(2)}> A tutti gli utenti con ruolo</div>
                        <div className={`BottoniDestinatario  ${tipoDestinatario==4?"selectedButton":""}`} onClick={()=>setTipoDestinatario(4)}> Ad un utente in particolare</div>
                        {tipoDestinatario==2&& 
                        <select className="" onChange={(e)=>setRuoloScelto(e.target.value)}> 
                            {listaRuoli.map((ruolo)=>{
                                return <option key={ruolo.id} value={ruolo.id}>{ruolo.name}</option>
                            })}
                        </select>
                        }
                        {tipoDestinatario==4&&
                        <select className="" onChange={(e)=>setUtenteSingolo(e.target.value)}>
                            {listaUtenti.map((user)=>{
                                return <option key={user.id} value={user.id}>{user.username}</option>
                            })}
                        </select>
                    }
                    </div>
                </div>
                <div className="testoNotifica"> 
                    <label>Inserisci il testo della notifica:</label>
                    <textarea maxLength="150" onChange={(e)=>setTestoNotifica(e.target.value)} value={testoNotifica}></textarea>
                </div>
            </div>   
            <div className="bottoneConferma" onClick={()=>controlliPreCreazione()}>INVIA</div>
            <div className={`avviso ${avviso.className}`}>{avviso.text}</div>
            {modal&&
                <div className="containerModal" onClick={(e)=>{if(e.target == e.currentTarget){setModal(false)}}}>
                    <div className="eliminaModal" >
                        <p> Sei sicuro di voler inviare questa notifica? <br/> L'operazione non sarà reversibile!</p>
                        <div className="eliminaButtonModal" onClick={()=>postToOneSignal()}>SI, Invia</div>
                        <div className="eliminaButtonModal" onClick={()=>setModal(false)}>NO, Annulla</div>
                    </div>
                </div>
            }

    

        </div>
    )
}

export default SendNotification;
