/*  
Gestione degli utenti editor e cioè delle squadreIdquesto component serve solo al tecnico che deve precompilare la lista delle squadra
*/

import {useState, useContext, useEffect} from 'react';
import SendNotification from "./GestioneUtentiComponents/SendNotification.js"
import InserisciUtente from "./GestioneUtentiComponents/InserisciUtente.js" //gem\age_fe\src\Components\GestioneUtentiComponents\InserisciUtente.js
import "../style/GestioneUtenti.css"

import { UserContext } from "../Context/UserContext.js";
import { SideMenuContext } from "../Context/SidebarContext.js";
import baseUrl from '../utilities/globalVariables.js'
import Footer from "./Footer.js"
import {useHistory} from "react-router-dom"; 


function GestioneUtenti(){

    var utentiURL = baseUrl + "/api/v1/users";
    const userObject = useContext(UserContext);
    const {setUsername, userId, setUserId,ruolo, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject

    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

    const [loading, setLoading] = useState(false);

    //import {useHistory} from "react-router-dom"; 
    let history = useHistory();

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }
    
    window.onbeforeunload = function() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('ruoloName');
        localStorage.removeItem('username');
        localStorage.removeItem('ruoloId');
        localStorage.removeItem('userId');
        localStorage.removeItem('caposquadra');
        return '';
    };
    const [listaUtenti, setListaUtenti] = useState([]);
    const [avviso, setAvviso]= useState({text:'', className:''});
    const [renderAutomatico, setRenderAutomatico] = useState(false)

    var superadmin = ruolo.id==5?true:false;
    /* var admin= ruolo.id==4?true:false;
    var moderator= ruolo.id==3?true:false;
    var editor = ruolo.id==2?true:false; */

    useEffect(()=>{
        if(accessToken == null){return}

        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(utentiURL, requestOptions)
            .then(response => response.json())
            .then(result =>{ 
                setLoading(false)

                //console.log(result);
                if(result.message){
                    setListaUtenti(result.message); 
                    setAvviso({text:'',className:''})
                }else if (result.error){
                setAvviso({text:result.error, className:'red'})
                }
            })
            .catch(error => console.log('error', error)); 
    },[renderAutomatico, accessToken])
    
    //console.log(listaUtenti)

    function handleDeleteUser(e){
        
            let idUser=e.target.parentNode.id;
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("x-user-id", userId);
            myHeaders.append('x-access-token', accessToken);
    
            var urlencoded = new URLSearchParams();
            urlencoded.append("idUser", idUser);
    
            var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
    
            fetch(baseUrl + "/api/v1/users", requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log(result);
                setRenderAutomatico(!renderAutomatico)
            })
            .catch(error => console.log('error', error));
    }

    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}

        {superadmin&&<div className="contenutoMain">
            <InserisciUtente setRenderAutomatico={setRenderAutomatico} renderAutomatico={renderAutomatico}></InserisciUtente>
            <SendNotification listaUtenti={listaUtenti}></SendNotification>

            <p className="headGestioneUtenti">Questa è la lista di tutti gli utenti.</p>
            <div className="listaUtentiContainer"> 
                <div className="headSingoloUtenteContainer"> 
                    <span>NOME</span>
                    <span>RUOLO </span>
                    <span> CAPO SQUADRA </span>
                </div>
                <span className={`avviso ${avviso.className}`}>{avviso.text}</span>
                {loading&&<div className="loaderContainer"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}

                {listaUtenti.map((user)=>{
                    return <div className="singoloUtenteContainer" key={user.id} id={user.id} >
                        <span className="nomeUtente">{user.username}</span>
                        <span className="ruolo">{user.role.name}</span>

                        <div className="capoSquadraHead">
                            {user.roleId==2 && user.capoSquadraFlag && <div ><span >Capo Squadra: </span><span  className="capoSquadraRes"> SI</span></div>}
                            {user.roleId==2 && !user.capoSquadraFlag && <div ><span >Capo Squadra: </span><span  className="capoSquadraRes"> NO</span></div>}
                        </div>

                        {!user.deleted&& <div className="utentiButtonsNotDeleted eliminaUtente" onClick={(e)=>handleDeleteUser(e)}>Elimina Utente</div>}
                        {user.deleted&& <div className="utentiButtonsDeleted eliminaUtente" onClick={(e)=>handleDeleteUser(e)}>Ripristina Utente</div>} 
                    </div>
                })}
            </div>
        </div>}
        {!superadmin&&<div className="contenutoMain"><p className="inserimentoUtenteContainerTitle">Non sei un Superadmin!</p></div>}


        <Footer></Footer>
    </div>
    )
}
export default GestioneUtenti;


