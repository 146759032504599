/* questo component conterrà solo la label e la select della lista danno , gli passerò come prop il setter dal component inserimento richiesto */
import {useState, useEffect} from 'react';
import L from 'leaflet';

import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapContainer, useMapEvents, TileLayer, WMSTileLayer, Marker, Popup } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'

import icon from  '../../imgs/avr.svg';
import greenIcon from '../../imgs/greenM.svg';
import catIcon from '../../imgs/cat.svg';
import baseUrl from '../../utilities/globalVariables.js'

import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [0, 0], 
    iconSize : [50,50],
    /* popupAnchor: [0, 0],
 */
});

L.Marker.prototype.options.icon = DefaultIcon;

const LeafIcon = L.Icon.extend({
    options: {}
});

/* 
IN QUESTA MAPPA VIENE PERMESSA LA RICERCA (geocoding) con flyto + addMarker
    oppure aggiunge il marker al click.
*/
function Mappa(props){
    const [map, setMap] = useState(null);
    const [openMap, setOpenMap] = useState(true)
    const [avviso, setAvviso]= useState('')

    const [layers, setLayers] = useState(true)
    const [layerAppaltatori, setLayerAppaltatori] = useState(false)
    const [layerOperatorePrivato, setLayerOperatorePrivato] = useState(false)
    const [layerCantieri, setLayerCantieri] = useState(false)
    const [layerSponsor, setLayerSponsor] = useState(false)
    const [layerAlberi, setLayerAlberi] = useState(false)
    const [layerInterferenze, setLayerInterferenze] = useState(false)
    const [hover, setHover] = useState(false)


    const {setGeom, setIdLocalita, setNomeLocalita, setMunicipio, setIndirizzo, setCivico, setIdAlbero} = props

    //centro la mappa su avr
    const initialCoordinates = [45.44956343385593, 9.254375202676734] //milano

    const [markers, setMarkers] = useState();    
    const [addressSearch, setAddressSearch] =useState('') 


    const greenIconM = new LeafIcon({
        iconUrl: greenIcon,
        iconAnchor: [20, 40], 
        iconSize: [40,40],
        popupAnchor: [0, -40],
    })

    const catIconM = new LeafIcon({
        iconUrl: catIcon,
        iconAnchor: [20, 35], 
        iconSize: [40,40],

    })

    //stato che uso per mostrare il nome completo della posizione nel Pop up del Marker 
    const [displayName , setDisplayName] = useState();

/*     useEffect(()=>{ //toglie i livelli se click su icona= modifica stato layers
        if(!layers){
            setLayerAppaltatori(false);
            setLayerSponsor(false);
            setLayerOperatorePrivato(false);
            setLayerCantieri(false)
            setLayerAlberi(false)
            setLayerInterferenze(false)
        }
    },[layers]) */

    function GetFeatureInfoWMS (e, map){ //interrogo i livelli
        var 
            map = map,
            loc = e.latlng,
            xy = e.containerPoint, // xy = map.latLngToContainerPoint(loc,map.getZoom())
            size = map.getSize(),
            bounds = map.getBounds(),
            url = 'https://geospaces.avrgroup.it/geoserver/AVRTech/wms',
            crs = map.options.crs, // me._crs
            //srs = crs.code,
            sw = crs.project(map.getBounds().getSouthWest()),
            ne = crs.project(map.getBounds().getNorthEast()),
        obj = {
            service: "WMS", // WMS (default)
            version: '1.1',
            request: "GetFeatureInfo",/* 
            layers: 'AVRTech:P103108_alberi',
            query_layers: ' AVRTech:P103108_alberi', */
            layers: 'AVRTech:P103108_alberi, AVRTech:S325502_appaltatore, AVRTech:S325509_operatore_privato,AVRTech:S326550_cantieri,AVRTech:S326_551_563_sponsor_collaborazioni, AVRTech:S441561_interferenza_32632',
            query_layers: ' AVRTech:P103108_alberi, AVRTech:S325502_appaltatore, AVRTech:S325509_operatore_privato,AVRTech:S326550_cantieri,AVRTech:S326_551_563_sponsor_collaborazioni, AVRTech:S441561_interferenza_32632',
            bbox: sw.x + "," + sw.y + "," + ne.x + "," + ne.y, 
            width: size.x,
            height: size.y,
            x: Math.round(xy.x),
            y: Math.round(xy.y),
            info_format: "application/json" // text/plain (default), application/json for JSON (CORS enabled servers), text/javascript for JSONP (JSONP enabled servers) // 1 (default)
            // exceptions: 'application/vnd.ogc.se_xml', // application/vnd.ogc.se_xml (default)
            // format_options: 'callback: parseResponse' // callback: parseResponse (default), use only with JSONP enabled servers, when you want to change the callback name
        };


        //console.log(url + L.Util.getParamString(obj, url, true));
        fetch(url + L.Util.getParamString(obj, url, true), {
            mode: 'cors'
        })
        .then((response)=> {
            //console.log(response);
            return response.json()
        })
        .then((res)=>{
            console.log("RISPOSTA LAYERS", res)

            if(res.features.length>0){
            //console.log("RISPOSTA LAYERS", res.features[0].properties)

                setIdLocalita(res.features[0].properties["z.area"])
                setNomeLocalita(res.features[0].properties["nome_loc"])
                setMunicipio("Municipio "+res.features[0].properties["zona"])
                //if(res.features[0].properties["obj_id"]){setIdAlbero(res.features[0].properties["obj_id"])}else{setIdAlbero(null)}
                //setInterferenza('true')
            }else{ 
                setIdLocalita('')
                setNomeLocalita('')
                setMunicipio('')
                setIdAlbero(null)
                //setInterferenza('false')
            }
        })
        .catch((err)=> {console.log(err)})
    }

    const [cat, setCat]= useState(false)
    //AL CLICK SU MAPPA, aggiunge marker poi interroga i layers
    function AddMarkerToClick() {
        const map = useMapEvents({
            click(e) {
                setCat(false)
                //inserisco il marker
                const newMarker = e.latlng
                setMarkers(newMarker); //questo mi fa partire lo UseEffect di REVERSE GEOCODING SU NOMINATIM
                setGeom(newMarker) 
                GetFeatureInfoWMS(e, map)
            },
        })

        return (
            <>    
                {(markers&&!cat) &&<Marker position={markers} icon={greenIconM}>
                    {displayName&& <Popup>La richiesta verrà inserita qui: <br/> {displayName}</Popup>}
                </Marker>}
                {(markers&&cat) &&<Marker position={markers} icon={catIconM}>
                    {displayName&& <Popup>MEOW! La richiesta verrà inserita qui: <br/> {displayName}</Popup>}
                </Marker>}
            </>
        )
    }

    //REVERSE GEOCODING se clicco su mappa CAMBIO LO STATO MARKERS E FACCIO PARTIRE L'EFFECT PER: ottenere longlat ed indirizzo come result fetch
    useEffect(()=>{
        if(markers){
            fetch(`https://nominatim.openstreetmap.org/reverse?lat=${markers.lat}&lon=${markers.lng}&format=json`)
            .then((response)=> {
                return response.json()
            })
            .then((res)=>{
                //console.log("risposta Nominatim ", res)
                //setMunicipio(res.address.suburb)
                setIndirizzo(res.address.road);
                setDisplayName(res.display_name)
                if(res.display_name.includes('Via Alfonso Gatto')){
                    setCat(true)
                }
                if(res.address.house_number){
                    setCivico(res.address.house_number)
                } else{ setCivico('')}
            })
            .catch((err)=> {console.log(err)})
        }
    },[markers])


    /* SEZIONE RELATIVA ALLA RICERCA PER INDIRIZZO */
    //per quando premo enter a fine inserimento 
    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            callAPIaddress();
        }
    };

    //RICERCA PER INDIRIZZO + click su vai => da nominatim ricevo latlong
    function callAPIaddress(){
        setIdLocalita('')
        setNomeLocalita('')

        fetch(`https://nominatim.openstreetmap.org/search?q=${addressSearch}&format=json`)
        .then((response)=> {
            //console.log(response)
            return response.json()
        })
        .then((res)=>{
            console.log("Nominatim",res)
            if(res.length==0){
                setAvviso('Nessun risultato - Prova una ricerca diversa ')
            }else{
                setAvviso('')
            }
            let objCoord={lat:+res[0].lat, lng:+res[0].lon }
            setCat(false)

            setMarkers(objCoord);
            map.flyTo([objCoord.lat, objCoord.lng],19)
            setGeom(`LatLng(${objCoord.lat}, ${objCoord.lng})`)
        })
        .catch((err)=> {console.log(err)})
    }

    return(

        <div> 
        <div className="mappaComponent"> 
            <p className="headMappa"  onClick={()=>setOpenMap(!openMap)}> MAPPA </p>
            <div className={`mappaContainer ${openMap?"":"closed" }` } >
                <MapContainer  whenCreated={setMap} center={{lat:45.464159, lon:9.191647}} zoom={13} scrollWheelZoom={true} doubleClickZoom={false} maxZoom={19}>
                {/* <ChangeView center={markers} zoom={14} />  */}
                    <TileLayer 
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" maxZoom={19}
                        
                    />
                    {layerAlberi&&          <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:P103108_alberi'}                      url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerAppaltatori&&     <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S325502_appaltatore'}                 url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerOperatorePrivato&&<WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S325509_operatore_privato'}           url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerCantieri&&        <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S326550_cantieri'}                    url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerSponsor&&         <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S326_551_563_sponsor_collaborazioni'} url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}
                    {layerInterferenze&&    <WMSTileLayer   maxZoom={19}  format='image/png' transparent={true}  layers={'AVRTech:S441561_interferenza_32632'}          url="https://geospaces.avrgroup.it/geoserver/AVRTech/wms"  opacity={1}  />}

                    <Marker position={initialCoordinates}>
                        <Popup className="popUpAvr">
                            AVR S.p.A {/*  <br /> Via Gaudenzo Fantoli, 6/11. */}
                        </Popup>
                    </Marker>
                    <AddMarkerToClick />
                </MapContainer>
            </div>
            <div className={openMap?"containerSearchMapOpen":"containerSearchMapClosed"}>
                <label>Cerca Indirizzo su mappa:</label>
                <input onChange={(e)=>setAddressSearch(e.target.value)} value={addressSearch}  onKeyPress={handleKeypress}></input>
                <div className="bottoneVai" onClick={()=>callAPIaddress()}>VAI</div>
                <div className="avvisoMappa">{avviso}</div>
            </div>
            {openMap&&<div className="containerBottoniLayers"> 
                <div className="iconaLayers" onClick={()=>setLayers(!layers)}>
                    {/* <span style={hover?{display:'inline'}:{display:'none'}}>Livelli</span> */}
                    <FontAwesomeIcon icon={faLayerGroup}  style={hover?{color:'#DB2219'}:''} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}></FontAwesomeIcon>
                </div>
                {layers&&<div className={`bottoniLayersDiv`}>
                    <div className={`bottoneLayer ${layerAppaltatori?'pressed1':''}`}       onClick={()=>setLayerAppaltatori(!layerAppaltatori)} >Appaltatore</div>
                    <div className={`bottoneLayer ${layerOperatorePrivato?'pressed2':''}`}  onClick={()=>setLayerOperatorePrivato(!layerOperatorePrivato)}>Operatore privato</div>
                    <div className={`bottoneLayer ${layerSponsor?'pressed3':''}`}           onClick={()=>setLayerSponsor(!layerSponsor)}>Sponsor</div>
                    <div className={`bottoneLayer ${layerCantieri?'pressed4':''}`}          onClick={()=>setLayerCantieri(!layerCantieri)}>Cantiere</div>
                    <div className={`bottoneLayer ${layerInterferenze?'pressed6':''}`}      onClick={()=>setLayerInterferenze(!layerInterferenze)}>Interferenze</div>
                    <div className={`bottoneLayer ${layerAlberi?'pressed5':''}`}            onClick={()=>setLayerAlberi(!layerAlberi)}>Alberi</div>
                </div>}
            </div>}
            <FontAwesomeIcon icon={faChevronUp} className={openMap?"angleUp":"angleDown"} onClick={()=>setOpenMap(!openMap)}></FontAwesomeIcon> 
        </div>
        
        </div>
    )
}
export default Mappa;

// REVERSE GEOCODING https://nominatim.openstreetmap.org/reverse?lat=45.452982&lon=9.246903&format=json
// GEOCODING https://nominatim.openstreetmap.org/search?q=empoli%20via%20roma&format=json
// Nominatim https://nominatim.org/release-docs/latest/api/Reverse/


/* FLUSSO DA RISCRIVERE:è anche importante capire che devo aspettare le risposte asincrone prima di passare alla next
così con gli useeffect fa le cose con ordine diverso ogni volta perchè dipende da come rispondono nominatim e i livelli
RICERCA DA INDIRIZZO + VIA

-> NOMINATIM 
    =>setParametriRichiesta
    =>prendo latlng => map.flyTo
                    => addMarker
                    => chiamoLIVELLI ->
                        Se NO fine.
                        Se SI => setParametriRichiesta
=>setNomeLocalità !="" -> checkIfAlreadyExists

RICERCA DA CLICK
=> addMarker
=> chiamoLIVELLI ->
    Se NO fine.
    Se SI => setParametriRichiesta
=>setNomeLocalità !="" -> checkIfAlreadyExists


*/