import React, {useState, createContext} from 'react';

export const UserContext = createContext();

export const UserProvider = props =>{
    const [userId, setUserId]= useState('')
    const [username, setUsername] = useState('');
    const [ruolo, setRuolo] = useState({id:0, name:''});
    const [mail, setMail] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [capoSquadra, setCapoSquadra] = useState(false);


    var userObject= {
        userId:userId,
        setUserId:setUserId,
        ruolo: ruolo,
        setRuolo: setRuolo,
        accessToken: accessToken,
        setAccessToken: setAccessToken,
        username: username,
        setUsername: setUsername,
        mail:mail,
        setMail:setMail,
        capoSquadra:capoSquadra,
        setCapoSquadra: setCapoSquadra
    }

    return(
        <UserContext.Provider value ={userObject}>
            {props.children}
        </UserContext.Provider>
    );
}