import * as React from "react";
import { Chart } from "react-google-charts";


function ChartPrioritàTipi(props){
    const listaRichieste = props.listaRichieste;
    
    /* da 1 a 7 gli statirichiestaId, abc sono le prioritesId */
    var [tipo1a, tipo2a, tipo3a,  tipo5a, tipo6a, tipo7a]=[0,0,0,0,0,0,0];
    var [tipo1b, tipo2b, tipo3b,  tipo5b, tipo6b, tipo7b]=[0,0,0,0,0,0,0];
    var [tipo1c, tipo2c, tipo3c,  tipo5c, tipo6c, tipo7c]=[0,0,0,0,0,0,0];

    let optionsMD = {
        chartArea: { width: '70%' },
        legend: { position: 'top'},
        bar: { groupWidth: '95%' },
        backgroundColor: { fill:'transparent' },
    }

    listaRichieste.forEach(item=>{
        if(item.statirichiestaId==1){ 
            if(item.prioritiesId ==1){tipo1a+=1}
            if(item.prioritiesId ==2){tipo1b+=1}
            if(item.prioritiesId ==3){tipo1c+=1}
        }
        if(item.statirichiestaId==2){ 
            if(item.prioritiesId ==1){tipo2a+=1}
            if(item.prioritiesId ==2){tipo2b+=1}
            if(item.prioritiesId ==3){tipo2c+=1}
        }
        if(item.statirichiestaId==3){
            if(item.prioritiesId ==1){tipo3a+=1}
            if(item.prioritiesId ==2){tipo3b+=1}
            if(item.prioritiesId ==3){tipo3c+=1}
        }
        /* if(item.statirichiestaId==4){  
            if(item.prioritiesId ==1){tipo4a+=1}
            if(item.prioritiesId ==2){tipo4b+=1}
            if(item.prioritiesId ==3){tipo4c+=1}
        } */
        if(item.statirichiestaId==4||item.statirichiestaId==5){  
            if(item.prioritiesId ==1){tipo5a+=1}
            if(item.prioritiesId ==2){tipo5b+=1}
            if(item.prioritiesId ==3){tipo5c+=1}
        }
        if(item.statirichiestaId==6){  
            if(item.prioritiesId ==1){tipo6a+=1}
            if(item.prioritiesId ==2){tipo6b+=1}
            if(item.prioritiesId ==3){tipo6c+=1}
        }
        if(item.statirichiestaId==7){  
            if(item.prioritiesId ==1){tipo7a+=1}
            if(item.prioritiesId ==2){tipo7b+=1}
            if(item.prioritiesId ==3){tipo7c+=1}
        }
    })
    
    return(

        <Chart
        width={'90%'}
        height={'310px'}
        chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={[
                ['Stato Richiesta', 'Segnalazione', 'Emergenza', 'Urgenza'],

                ['Inserita', tipo1a, tipo1b,tipo1c],
                
                ['Messa in Sic', tipo3a, tipo3b, tipo3c],

                ['Risolta', tipo5a, tipo5b, tipo5c],

                ['Chiusa', tipo6a, tipo6b, tipo6c],

                ['Annullata',  tipo7a, tipo7b, tipo7c],

            ]}
            options = {optionsMD}

            />
    );
}

export default ChartPrioritàTipi;


