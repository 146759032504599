import {useState, useEffect} from 'react';



function FiltriDate(props){

    const [listaRichieste, listaRichiesteFiltrate, setListaRichiesteFiltrate] = [props.listaRichieste, props.setListaRichieste, props.setListaRichiesteFiltrate]

    //stati che uso per ricercare e filtare i risultati in un intervallo di DATE DI RISOLUZIONE
    const [primaData, setPrimaData] = useState("");
    const [secondaData, setSecondaData] = useState("");

    //stati che uso per ricercare e filtare i risultati in un intervallo di DATE DI CREAZIONE
    const [primaDataCreazione, setPrimaDataCreazione] = useState("");
    const [secondaDataCreazione, setSecondaDataCreazione] = useState("");

    //stati value per gli input
    const [primaDataValue, setPrimaDataValue] = useState("");
    const [secondaDataValue, setSecondaDataValue] = useState("");
    const [primaDataCreazioneValue, setPrimaDataCreazioneValue] = useState("");
    const [secondaDataCreazioneValue, setSecondaDataCreazioneValue] = useState("");

    //stati per la renderizzazione dei component del
    const [ricercaRisoluzione, setRicercaRisoluzione] = useState(false);
    const [ricercaCreazione, setRicercaCreazione] = useState(false);

    //FILTRAGGIO PER DATA DI RISOLUZIONE ( setto gli stati in questa funzione, settandoli faccio partire lo useEffect che segue)
    function handleDateStates(e){
        if(e.target.value){
            if(e.target.id == 'primaData'){
                const val = new Date(e.target.value);
                setPrimaData(val)
                setPrimaDataValue(e.target.value);
            }else if(e.target.id == 'secondaData'){
                const val = new Date(e.target.value);
                setSecondaData(val)
                setSecondaDataValue(e.target.value);
            }
        }else{
            if(e.target.id == 'primaData'){
                setPrimaData()
                setPrimaDataValue("")

            }else if(e.target.id == 'secondaData'){
                setSecondaData()
                setSecondaDataValue("")
            }
        }
    }

    useEffect(()=>{ //PER ORA SULLA DATA DI RISOLUZIONE =>
        //console.log("date cercate",primaData, secondaData)
        if(primaData&&!secondaData){
            var result = listaRichieste.filter(richiesta => {
                    var date = new Date(richiesta.dataRisoluzione);
                    return (date >= primaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("prima", listaRichiesteFiltrate )

        } 
        if(!primaData&&secondaData){
            var result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.dataRisoluzione);
                return ( date <= secondaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("secondo", listaRichiesteFiltrate )

        } 
        if(primaData&&secondaData){
            var result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.dataRisoluzione);
                return (date >= primaData && date <= secondaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("between", listaRichiesteFiltrate )
        }
        if(!primaData && !secondaData){
            setListaRichiesteFiltrate(listaRichieste)
        }
        //console.log(listaRichiesteFiltrate)

    },[primaData,secondaData])




    //FILTRAGGIO PER DATA DI CREAZIONE( setto gli stati in questa funzione, settandoli faccio partire lo useEffect che segue)
    function handleCreationDateStates(e){

        if(e.target.value){
            if(e.target.id == 'primaDataCreazione'){
                const val = new Date(e.target.value);
                setPrimaDataCreazione(val)
                setPrimaDataCreazioneValue(e.target.value);

            }else if(e.target.id == 'secondaDataCreazione'){
                const val = new Date(e.target.value);
                setSecondaDataCreazione(val)
                setSecondaDataCreazioneValue(e.target.value);

            }
        }else{
            if(e.target.id == 'primaDataCreazione'){
                setPrimaDataCreazione()
                setPrimaDataCreazioneValue("");

            }else if(e.target.id == 'secondaDataCreazione'){
                setSecondaDataCreazione()
                setSecondaDataCreazioneValue("");

            }
        }
    }

    useEffect(()=>{ 
        //console.log("date Creazione cercate",primaDataCreazione, secondaDataCreazione)
        if(primaDataCreazione&&!secondaDataCreazione){
            var result = listaRichieste.filter(richiesta => {
                    var date = new Date(richiesta.createdAt);
                    return (date >= primaDataCreazione);
                });
            setListaRichiesteFiltrate(result)
            //console.log("prima", listaRichiesteFiltrate )

        } 
        if(!primaDataCreazione&&secondaDataCreazione){
            var result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.createdAt);
                return ( date <= secondaDataCreazione);
                });
            setListaRichiesteFiltrate(result)
            //console.log("secondo", listaRichiesteFiltrate )

        } 
        if(primaDataCreazione&&secondaDataCreazione){
            var result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.createdAt);
                return (date >= primaDataCreazione && date <= secondaDataCreazione);
                });
            setListaRichiesteFiltrate(result)
            //console.log("between", listaRichiesteFiltrate )
        }
        if(!primaDataCreazione && !secondaDataCreazione){
            setListaRichiesteFiltrate(listaRichieste)
        }

    },[primaDataCreazione,secondaDataCreazione])

    return(
            <div className="containerRicercaComponents">
                <div  className="ricercaComponent">
                    <div className={`ricercaButtons ${ricercaCreazione?"attivo":''}` } onClick={()=>setRicercaCreazione(!ricercaCreazione)}>Ricerca per Data di Creazione</div>
                    {ricercaCreazione&&<div  className="ricercaInput">
                            <div className="ricercaInputContainer"> Da <input type="date" id='primaDataCreazione' value={primaDataCreazioneValue} onChange={e =>handleCreationDateStates(e) }></input> 
                            <div  className="deleteDateButton" onClick={()=>{setPrimaDataCreazione(); setPrimaDataCreazioneValue("") }}>&times;</div> </div>
                        <div className="ricercaInputContainer">A  <input type="date" id='secondaDataCreazione' value={secondaDataCreazioneValue} onChange={e => handleCreationDateStates(e) }></input> <div  className="deleteDateButton" onClick={()=>{setSecondaDataCreazione(); setSecondaDataCreazioneValue("") }}>&times;</div></div>
                    </div>}
                </div>
                <div  className="ricercaComponent">
                    <div className={`ricercaButtons ${ricercaRisoluzione?"attivo":''}`} onClick={()=>setRicercaRisoluzione(!ricercaRisoluzione)}>Ricerca per Data di Risoluzione</div>
                    {ricercaRisoluzione&&<div  className="ricercaInput">
                    <div className="ricercaInputContainer">Da <input type="date" id='primaData' value={primaDataValue} onChange={e =>handleDateStates(e) }></input> <div className="deleteDateButton" onClick={()=>{setPrimaData(); setPrimaDataValue("") }}>&times;</div> </div>
                    <div className="ricercaInputContainer">A  <input type="date" id='secondaData' value={secondaDataValue} onChange={e => handleDateStates(e) }></input> <div  className="deleteDateButton" onClick={()=>{setSecondaData(); setSecondaDataValue("") }}>&times;</div> </div>
                    </div>}
                </div>

            </div>
    )
}

export default FiltriDate;