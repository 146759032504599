import  React, { useState, useEffect,  useContext } from 'react'
import "../../style/HeaderDashboard.css"
import baseUrl from '../../utilities/globalVariables.js'
import capitalizeFirstLetter from '../../utilities/utilities.js'
import { UserContext } from "../../Context/UserContext.js";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HeaderDashboard = () => {


    const meteoAPI = baseUrl + "/api/v1/meteo";
    const [linkUltimoDAM, setLinkUltimoDAM] = useState('');
    const [dataUltimoDAM, setDataUltimoDAM] = useState();
    const [iconMeteo, setIconMeteo] = useState('https://openweathermap.org/img/wn/10d@2x.png')
    const [iconMeteo1, setIconMeteo1] = useState('https://openweathermap.org/img/wn/10d@2x.png')
    const [iconMeteo2, setIconMeteo2] = useState('https://openweathermap.org/img/wn/10d@2x.png')
    const [iconMeteo4, setIconMeteo4] = useState('https://openweathermap.org/img/wn/10d@2x.png')
    const [meteoInfoCurrent, setMeteoInfoCurrent ] = useState([])
    const [meteoInfoHourly, setMeteoInfoHourly ] = useState([])

    const userObject = useContext(UserContext);
    const { userId, accessToken} = userObject

    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var requestOptions = {
            headers: myHeaders,
            method: 'GET',
            redirect: 'follow'
        };
        
        fetch(meteoAPI, requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("METEO",result.message);
            if(result.message){
                setMeteoInfoCurrent(result.message.meteoCurrent)
                setMeteoInfoHourly(result.message.meteoHourly)
                setIconMeteo("https://openweathermap.org/img/wn/"+result.message.meteoCurrent.weather[0].icon+"@2x.png")
                setIconMeteo1("https://openweathermap.org/img/wn/"+result.message.meteoHourly[1].weather[0].icon+"@2x.png")
                setIconMeteo2("https://openweathermap.org/img/wn/"+result.message.meteoHourly[2].weather[0].icon+"@2x.png")
                setIconMeteo4("https://openweathermap.org/img/wn/"+result.message.meteoHourly[4].weather[0].icon+"@2x.png")
            }
        })
        .catch(error => console.log('error', error));

        fetch(meteoAPI + "/allerta", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message){
                setLinkUltimoDAM(result.message.link) 
                let data = new Date(result.message.dataAcquisizione).toLocaleString('it')
                setDataUltimoDAM(data)
            }
            //console.log(result)
        })
        .catch(error => console.log('error', error));

    },[accessToken])

    function calcoloOre(epoch){
        const dt = new Date(epoch*1000);
        const hr = dt.getHours();
        const m = "0" + dt.getMinutes();
        return hr + ':' + m.substr(-2)
    }
    

    return(
        <div className="headerDashboardContainer">
            <div  className="allertaContainer">
                <a className="allertaTitle" href="https://www.allertalom.regione.lombardia.it/" target="_blank" rel="noreferrer">
                    <p >VISITA ALLERTA LOMBARDIA 
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{margin:'0 0.5em'}} ></FontAwesomeIcon> 
                    </p>
                </a>
                <a  href={linkUltimoDAM} download><div className="allertaButton">Scarica l'ultimo Documento di Allertamento </div>
                </a>
                <p className="dataUltimoLink">Ultima Acquisizione PDF: {dataUltimoDAM}</p>
            </div>
            <div className="strisciaMeteo" >
                <div className="oreMeteoContainersCurrent"> {/* ORA */}
                    <div className="leftMeteoContainer">
                        <p >{meteoInfoCurrent.dt?calcoloOre(meteoInfoCurrent.dt):''} </p>
                        <img src={iconMeteo} alt="iconaMeteo" title={meteoInfoCurrent.weather?capitalizeFirstLetter(meteoInfoCurrent.weather[0].description):''}></img> 
                    </div>
                    <div className="rightMeteoContainer">
                        <span >{Math.floor(meteoInfoCurrent.temp - 273.15 )  }° T </span>
                        <span >{meteoInfoCurrent.humidity}% h</span>
                        <span >{meteoInfoCurrent.wind_speed}Km/h w</span>
                        {meteoInfoCurrent.rain&&<span >{meteoInfoCurrent.rain['1h']}mm r</span>}
                        {meteoInfoCurrent.snow&&<span >{meteoInfoCurrent.snow['1h']}mm s</span>}

                        
                    </div>

                </div>
                {meteoInfoHourly[1]&&<div  className="oreMeteoContainers" > 
                    <div className="leftMeteoContainer">
                        <p>{meteoInfoHourly[1].dt?calcoloOre(meteoInfoHourly[1].dt):''} </p>
                        <img src={iconMeteo1} alt="iconaMeteo" title={meteoInfoHourly[1].weather?capitalizeFirstLetter(meteoInfoHourly[1].weather[0].description):''}></img> 
                    </div>
                    <div className="rightMeteoContainer">
                        <span >{Math.floor(meteoInfoHourly[1].temp - 273.15 )  }° T </span>
                        <span >{meteoInfoHourly[1].humidity}% h</span>
                        <span >{meteoInfoHourly[1].wind_speed}Km/h w</span>
                        {meteoInfoHourly[1].rain&&<span >{meteoInfoHourly[1].rain['1h']}mm r</span>}
                        {meteoInfoHourly[1].snow&&<span >{meteoInfoHourly[1].snow['1h']}mm s</span>}
                    </div>

                </div>}
                {meteoInfoHourly[2]&&<div  className="oreMeteoContainers" > 
                    <div className="leftMeteoContainer">
                        <p>{meteoInfoHourly[2].dt?calcoloOre(meteoInfoHourly[2].dt):''}</p>
                        <img src={iconMeteo2} alt="iconaMeteo" title={meteoInfoCurrent.weather?capitalizeFirstLetter(meteoInfoHourly[2].weather[0].description):''}></img> 
                    </div>
                    <div className="rightMeteoContainer">
                        <span >{Math.floor(meteoInfoHourly[2].temp - 273.15 )  }° T </span>
                        <span >{meteoInfoHourly[2].humidity}% h</span>
                        <span >{meteoInfoHourly[2].wind_speed}Km/h w</span>
                        {meteoInfoHourly[2].rain&&<span >{meteoInfoHourly[2].rain['1h']}mm r</span>}
                        {meteoInfoHourly[2].snow&&<span >{meteoInfoHourly[2].snow['1h']}mm s</span>}
                    </div>
                </div>}
                {meteoInfoHourly[4]&&<div  className="oreMeteoContainers" >
                    <div className="leftMeteoContainer">
                        <p>{meteoInfoHourly[4].dt?calcoloOre(meteoInfoHourly[4].dt):''}</p>
                        <img src={iconMeteo4} alt="iconaMeteo" title={meteoInfoCurrent.weather?capitalizeFirstLetter(meteoInfoHourly[4].weather[0].description):''}></img> 
                    </div>
                    <div className="rightMeteoContainer">
                        <span >{Math.floor(meteoInfoHourly[4].temp - 273.15 )  }° T </span>
                        <span >{meteoInfoHourly[4].humidity}% h</span>
                        <span >{meteoInfoHourly[4].wind_speed}Km/h w</span>
                        {meteoInfoHourly[4].rain&&<span >{meteoInfoHourly[4].rain['1h']}mm r</span>}
                        {meteoInfoHourly[4].snow&&<span >{meteoInfoHourly[4].snow['1h']}mm s</span>}
                    </div>

                </div>}
            </div>
        </div>
    )
}

export default HeaderDashboard


/* 43.43352128293927, 39.93272742308874 */
/* https://api.openweathermap.org/data/2.5/onecall?lat=43.43352128293927&lon=39.93272742308874&appid=91b2e8852c8de8f08ead31e108911942&lang=it */