
import {UserProvider} from "./Context/UserContext.js";
import {SideMenuProvider} from "./Context/SidebarContext.js";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom"; //il pacchetto ReactDOM viene importato per renderizzare i componenti React.

import Sidebar from './Components/Sidebar.js';
import HomePage from './Components/Homepage.js';
import DashBoardPage from './Components/DashBoardPage.js';
import InserimentoRichiesta from './Components/InserimentoRichiesta.js';
import RicercaRichiesta from './Components/RicercaRichiesta.js';
import SingolaRichiesta from './Components/SingolaRichiesta.js';
import GestioneMezzi from './Components/GestioneMezzi.js';
import GestioneUtenti from './Components/GestioneUtenti.js';
import LeMieRichieste from './Components/LeMieRichieste.js';
import StoricoPage from './Components/StoricoPage.js';

function App() {
  return (
    <Router>
      <div className="App">
          <Switch>
            <UserProvider><SideMenuProvider>
              <Sidebar></Sidebar>
              <Route exact path="/"                     render={(props) => <HomePage             {...props} />}/>
              <Route exact path="/dashboard"            render={(props) => <DashBoardPage        {...props} />}/>
              <Route exact path="/inserimentoRichiesta" render={(props) => <InserimentoRichiesta {...props} />}/>
              <Route exact path="/ricercaRichiesta"     render={(props) => <RicercaRichiesta     {...props} />}/>
              <Route exact path="/singolaRichiesta"     render={(props) => <SingolaRichiesta     {...props} />}/>
              <Route exact path="/gestioneMezzi"        render={(props) => <GestioneMezzi        {...props} />}/>
              <Route exact path="/gestioneUtenti"       render={(props) => <GestioneUtenti       {...props} />}/>
              <Route exact path="/lemierichieste"       render={(props) => <LeMieRichieste       {...props} />}/>
              <Route exact path="/storico"              render={(props) => <StoricoPage         {...props} />}/>
            </SideMenuProvider></UserProvider>
          </Switch>
      </div>
    </Router>

  );
}

export default App;
