import  React from 'react'
import {useState, useContext} from 'react';
import { UserContext } from "../Context/UserContext.js";
import {useHistory} from "react-router-dom";

import baseUrl from '../utilities/globalVariables.js'


function EliminaRichiesta(props){
    const idRichiesta= props.idRichiesta;
    const [modal, setModal] = useState(false)
    const [avviso, setAvviso] = useState(false)
    const apiUrl= baseUrl + "/api/v1/richieste"
    let history = useHistory();


    const userObject = useContext(UserContext);
    const {userId, accessToken} = userObject


    function callAPI(){

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-user-id", userId);
        myHeaders.append('x-access-token', accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("idrichiesta", idRichiesta);
        urlencoded.append("useriddeleting", userId);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
                //console.log(result);
                if(result.message){
                    history.push ("/ricercaRichiesta")
                }
                else if(result.error){
                    setAvviso(result.error)
                }
            
            
            })
        .catch(error => console.log('error', error));
    }

    return(
        <div style={{display: 'inline-block'}}>
            <div className="eliminaButton" onClick={()=>setModal(true)}> ELIMINA
            </div>
            <span>{avviso}</span>
            {modal&&
                <div className="containerModal" onClick={(e)=>{if(e.target == e.currentTarget){setModal(false)}}}>
                    <div className="eliminaModal" >
                        <p> Sei sicuro di voler eliminare questa richiesta? <br/> L'operazione non sarà reversibile!</p>
                        <div className="eliminaButtonModal" onClick={()=>callAPI()}>SI, Elimina</div>
                        <div className="eliminaButtonModal" onClick={()=>setModal(false)}>NO, Annulla</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EliminaRichiesta