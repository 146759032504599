import  React, { useState , useEffect } from 'react'

import logoAvr from '../imgs/avrLogo.png'
import "../style/Footer.css"

export function Footer(){

    return(
        <div className='footerContainer'>
            <div className="footer">
                <span>Made by </span>
                <a href="https://www.avrgroup.it/" target="_blank"><img src={logoAvr} className="logoAVR"></img></a>
                <span> Tech</span> |
                <span>Dati meteo forniti da OpenWeather&trade;</span>
            </div>
            
        </div> 
    )
}

export default Footer

//AVR Tech 