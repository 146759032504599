/*  
Gestione degli utenti editor e cioè delle squadreIdquesto component serve solo al tecnico che deve precompilare la lista delle squadra
*/

import {useState, useContext, useEffect} from 'react';
import InserisciMezzo from "./GestioneMezziComponents/InserisciMezzo.js"
import MostraMezzi from "./GestioneMezziComponents/MostraMezzi.js"

import { SideMenuContext } from "../Context/SidebarContext.js";
import { UserContext } from "../Context/UserContext.js";
import Footer from "./Footer.js"
import {useHistory} from "react-router-dom"; 


import "../style/GestioneMezzi.css"

function GestioneMezzi(){
    const [inserimentoMezzoContainer, setInserimentoMezzoContainer]= useState(false);
    const [listaMezzoContainer, setListaMezzoContainer]= useState(true);

    const userObject = useContext(UserContext);
    const { setUsername, setUserId, ruolo, setRuolo, accessToken, setAccessToken, setCapoSquadra} = userObject

    const [renderAutomatico, setRenderAutomatico] = useState(false)

    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;

        //import {useHistory} from "react-router-dom"; 
    let history = useHistory();
    var superadminOrModerator = ruolo.id==5||ruolo.id==4?true:false;

    if(accessToken == null){
        //console.log("LOCAL",window.localStorage)
        if(window.localStorage.accessToken){
            setRuolo({id:window.localStorage.ruoloId, name: window.localStorage.ruoloName}); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
            setCapoSquadra(window.localStorage.caposquadra)
        } else {
            history.push ("/")
        }
    }
    window.onbeforeunload = function() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('ruoloName');
        localStorage.removeItem('username');
        localStorage.removeItem('ruoloId');
        localStorage.removeItem('userId');
        localStorage.removeItem('caposquadra');
        return '';
    };
    return(
        <div className={`main ${openSidemenu?"":"mainChiuso"}`}> {/*  mainChiuso  */}
        {superadminOrModerator&&<div className="contenutoMain">
            <p className="headGestioneMezzi"> Ad ogni richiesta possono essere assegnati uno o più mezzi. <br/>Qui è possibile visualizzare, disabilitare e ripristinare tutti i mezzi inseriti in GEM, oppure inserirne di nuovi.</p>
            <div className="bottoniContainer">
            <div className={`listaMezziButton ${listaMezzoContainer?"attivo":""}`}  onClick={()=> setListaMezzoContainer(!listaMezzoContainer)}>Vedi tutti i Mezzi</div>
                <div className={`inserimentoMezziButton ${inserimentoMezzoContainer?"attivo":""}`} onClick={()=> setInserimentoMezzoContainer(!inserimentoMezzoContainer)}>Inserisci Nuovo Mezzo</div> <br/>
            </div>
            {inserimentoMezzoContainer&&<InserisciMezzo setRenderAutomatico={setRenderAutomatico} renderAutomatico={renderAutomatico}></InserisciMezzo>}
            {listaMezzoContainer&&<MostraMezzi setRenderAutomatico={setRenderAutomatico} renderAutomatico={renderAutomatico}> </MostraMezzi>}
        
        </div>}
        {!superadminOrModerator&&<div className="contenutoMain"><p className="headGestioneMezzi">Non sei nè un Moderator nè un Superadmin!</p></div>}
        <Footer></Footer>
    </div>
    )
}
export default GestioneMezzi;